import { Divider, Grid, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Header from "../../../shared/Header";
import moneyParser from "../../../shared/moneyParser";
import { getDeliverDetail } from "../data/delivery.api";
import { useParams } from "react-router-dom";
import { InterfaceDeliveryDetail } from "../data/delivery.interface";

export default function DeliveryPrint() {

    const dispatch = useDispatch()
    const { id } = useParams()

    const [deliveryDetail, setDeliveryDetail] = useState<InterfaceDeliveryDetail | null>(null)

    useEffect(() => {
        if (id !== undefined) {
            dispatch<any>(getDeliverDetail(parseInt(id)))
                .unwrap()
                .then((response: any) => {
                    if (response.status === 200) {
                        setDeliveryDetail(response.data.data)
                    }
                })
        }
    }, [dispatch, id])

    return (
        <Container maxWidth="xl">
            <table style={{ width: "100%", fontWeight: "bold" }}>
                <tbody>
                    {deliveryDetail?.package_list.map(pkg => (
                        <tr key={pkg.id}>
                            <td className="ticket">
                                {deliveryDetail.total_paid < deliveryDetail.total && (
                                    <div className="watermark">
                                        <span>Belum Dibayar</span>
                                    </div>
                                )}
                                <Divider />
                                <Header />
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <Typography fontWeight={"bold"}>
                                            Pengirim
                                        </Typography>
                                        <Typography fontWeight={"bold"}>
                                            {deliveryDetail.sender_name}
                                        </Typography>
                                        <Typography fontWeight={"bold"}>
                                            {deliveryDetail.sender_address}
                                        </Typography>
                                        <Typography fontWeight={"bold"}>
                                            {deliveryDetail.sender_phone}
                                        </Typography>
                                        <Typography fontWeight={"bold"}>
                                            {deliveryDetail.start_terminal.name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography fontWeight={"bold"}>
                                            Penerima
                                        </Typography>
                                        <Typography fontWeight={"bold"}>
                                            {deliveryDetail.receiver_name}
                                        </Typography>
                                        <Typography fontWeight={"bold"}>
                                            {deliveryDetail.receiver_address}
                                        </Typography>
                                        <Typography fontWeight={"bold"}>
                                            {deliveryDetail.receiver_phone}
                                        </Typography>
                                        <Typography fontWeight={"bold"}>
                                            {deliveryDetail.finish_terminal.name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography fontWeight={"bold"}>
                                            Biaya kirim: {moneyParser(deliveryDetail.price * deliveryDetail.package_list.reduce((total, pkg) => total + pkg.weight, 0))}
                                        </Typography>
                                        <Typography fontWeight={"bold"}>
                                            Biaya Packing: {moneyParser(deliveryDetail.price_packing)}
                                        </Typography>
                                        <Typography fontWeight={"bold"}>
                                            Total: {moneyParser(deliveryDetail.price * deliveryDetail.package_list.reduce((total, pkg) => total + pkg.weight, 0) + deliveryDetail.price_packing)}
                                        </Typography>
                                        <Typography fontWeight={"bold"}>
                                            Discount: {moneyParser(deliveryDetail.discount_price)} ({deliveryDetail.discount.toFixed(0)}%)
                                        </Typography>
                                        <Typography fontWeight={"bold"}>
                                            Total Bayar: {moneyParser(deliveryDetail.total)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography fontWeight={"bold"} align="center">
                                            Data Barang
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography fontWeight={"bold"}>
                                            Nama: {pkg.name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography fontWeight={"bold"}>
                                            Jenis: {deliveryDetail.package_type.name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography fontWeight={"bold"}>
                                            Berat: {pkg.name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography fontWeight={"bold"}>
                                            Keterangan: {deliveryDetail.notes}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Divider />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </Container>
    )
}