import { Box, Button } from "@mui/material";
import { FormEvent } from "react";
import { InitialScheduleForm, InterfaceScheduleForm } from "../data/schedule.interface";
import { useDispatch } from "react-redux";
import alertSuccess from "../../../shared/alert/alertSuccess";
import { useFormValues } from "../../../hooks/useFormValues";
import { createSchedule, editSchedule, getSchedule } from "../data/schedule.api";
import DaySelect from "./DaySelect";
import CitySelect from "../../city/components/CitySelect";
import FormTime from "../../../shared/form/FormTime";
import FormMoney from "../../../shared/form/FormMoney";
import FormText from "../../../shared/form/FormText";
import FormCheck from "../../../shared/form/FormCheck";

export default function ScheduleForm(props: { mode: "ADD" | "EDIT", data?: InterfaceScheduleForm, onSubmit: () => void }) {

    const dispatch = useDispatch<any>()

    const { values, handleChange, handleChangeValues } = useFormValues<InterfaceScheduleForm>(props.data || InitialScheduleForm)

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault()
        try {
            if (props.mode === "ADD") {
                await dispatch(createSchedule(values)).unwrap()
                await alertSuccess("Berhasil menyimpan data")
            }
            else if (props.mode === "EDIT") {
                await dispatch(editSchedule(values)).unwrap()
                await alertSuccess("Berhasil mengubah data")
            }
            await dispatch(getSchedule({}))
            props.onSubmit()
        } catch (error) { }
    }

    return (
        <Box component={"form"} onSubmit={handleSubmit}>
            <DaySelect value={values.day_list} onChange={(value) => handleChangeValues("day_list", value)} />
            <CitySelect label="Kota Keberangkatan" value={values.start_city_id} onChange={(value) => handleChangeValues("start_city_id", value)} />
            <CitySelect label="Kota Tujuan" value={values.finish_city_id} onChange={(value) => handleChangeValues("finish_city_id", value)} />
            <FormTime label="Jam Keberangkatan" name="start_time" value={values.start_time} onChange={handleChange} />
            <FormTime label="Jam Tiba" name="finish_time" value={values.finish_time} onChange={handleChange} />
            <FormMoney label="Harga Pengiriman Paket" name="price_delivery" value={values.price_delivery} onChange={handleChange} />
            <FormMoney label="Biaya Solar" name="cost_gas" value={values.cost_gas} onChange={handleChange} />
            <FormMoney label="Biaya Maintenance" name="cost_maintenance" value={values.cost_maintenance} onChange={handleChange} />
            <FormText label="Keterangan" name="note" value={values.note} onChange={handleChange} />
            <FormCheck label="Tampilkan di Kalender" name="is_show_on_calendar" value={values.is_show_on_calendar} onChange={handleChange} />
            <Box marginTop={1} display={"flex"} justifyContent={"flex-end"}>
                <Button type="submit">Simpan</Button>
            </Box>
        </Box>
    )
}