export interface InterfaceSparepartCategoryData {
    id: number,
    ts_created: string,
    name: string,
    parrent_id: number | null
}

export interface InterfaceSparepartCategoryDataTree extends InterfaceSparepartCategoryData {
    indent: number
}

export interface InterfaceSparepartCategoryForm {
    id: number,
    name: string,
    parrent_id: number | null
}

export interface InterfaceSparepartCategoryCreate {
    name: string,
    parrent_id: number | null
}

export interface InterfaceSparepartCategoryEdit {
    id: number,
    name?: string,
    parrent_id?: number | null
}

export const InitialSparepartCategoryForm: InterfaceSparepartCategoryForm = {
    id: 0,
    name: "",
    parrent_id: null
}