import { Box, Button, Divider } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { setItineraryInput } from "../../data/itinerary.reducer";
import { InterfaceItineraryInput } from "../../data/itinerary.interface";
import EmployeeSelect from "../../../employee/components/EmployeeSelect";
import { FormEvent } from "react";
import CarSelect from "../../../car/components/CarSelect";
import { createItinerary } from "../../data/itinerary.api";
import alertSuccess from "../../../../shared/alert/alertSuccess";
import { useSetting } from "../../../../hooks/useSetting";

export default function ItineraryCreateD(props: { onSubmit: () => void }) {

    const dispatch = useDispatch<any>()
    const setting = useSetting()

    const { itineraryInput } = useSelector((state: RootState) => state.itinerary)
    const { bookingItineraryList } = useSelector((state: RootState) => state.booking)

    const handleChangeValues = (name: keyof InterfaceItineraryInput, value: any) => {
        dispatch(setItineraryInput({
            ...itineraryInput,
            [name]: value
        }))
    }

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault()
        console.log(itineraryInput)
        if (
            itineraryInput.ts_created !== undefined &&
            itineraryInput.start_date !== undefined &&
            itineraryInput.schedule !== undefined &&
            itineraryInput.car !== undefined &&
            itineraryInput.driver_primary !== undefined &&
            itineraryInput.driver_secondary !== undefined &&
            itineraryInput.helper_a !== undefined &&
            itineraryInput.helper_b !== undefined
        ) {
            try {
                await dispatch(createItinerary({
                    ts_created: itineraryInput.ts_created,
                    start_date: itineraryInput.start_date,
                    schedule_id: itineraryInput.schedule.id,
                    car_id: itineraryInput.car.id,
                    driver_primary_id: itineraryInput.driver_primary.id,
                    driver_secondary_id: itineraryInput.driver_secondary.id,
                    helper_a_id: itineraryInput.helper_a.id,
                    helper_b_id: itineraryInput.helper_b.id,
                    booking_id_list: bookingItineraryList.map((item) => ({ booking_id: item.id })),
                    delivery_id_list: itineraryInput.delivery_id_list?.map((item) => ({ delivery_id: item })) || [],
                    cost_list: [
                        {
                            name: "Solar",
                            account_source_id: setting.getValue("ACCOUNT_ITINERARY_COST_SOURCE_DEFAULT"),
                            account_id: setting.getValue("ACCOUNT_ITINERARY_COST_DESTINATION_GAS"),
                            value: itineraryInput.schedule.cost_gas || setting.getValue("COST_GAS")
                        },
                        {
                            name: "Maintenance",
                            account_source_id: setting.getValue("ACCOUNT_ITINERARY_COST_SOURCE_DEFAULT"),
                            account_id: setting.getValue("ACCOUNT_ITINERARY_COST_DESTINATION_MAINTENANCE"),
                            value: itineraryInput.schedule.cost_maintenance || setting.getValue("COST_MAINTENANCE")
                        }
                    ]
                })).unwrap()
                alertSuccess("Berhasil menyimpan data")
                props.onSubmit()
                dispatch(setItineraryInput({}))
            } catch (error) { }
        }
    }

    return (
        <Box>
            <form onSubmit={handleSubmit}>
                <CarSelect required value={itineraryInput.car?.id} onChange={(_, value) => handleChangeValues("car", value)} />
                <Divider sx={{ marginY: 1 }} />
                <EmployeeSelect required type={["SOPIR","SOPIR_CADANGAN"]} label="Sopir Utama" value={itineraryInput.driver_primary?.id} onChange={(_, value) => handleChangeValues("driver_primary", value)} />
                <EmployeeSelect required type={["SOPIR","SOPIR_CADANGAN"]} label="Sopir Cadangan" value={itineraryInput.driver_secondary?.id} onChange={(_, value) => handleChangeValues("driver_secondary", value)} />
                <EmployeeSelect required type={["KERNET"]} label="Kernet 1" value={itineraryInput.helper_a?.id} onChange={(_, value) => handleChangeValues("helper_a", value)} />
                <EmployeeSelect required type={["KERNET"]} label="Kernet 2" value={itineraryInput.helper_b?.id} onChange={(_, value) => handleChangeValues("helper_b", value)} />
                <Box marginTop={1} display={"flex"} justifyContent={"flex-end"}>
                    <Button type="submit">Simpan</Button>
                </Box>
            </form>
        </Box>
    )
}