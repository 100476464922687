import { Box, Button, Card, CardContent, Grid, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { setDeliveryInput } from "../../data/delivery.reducer";
import { InterfaceDeliveryInput } from "../../data/delivery.interface";
import PackageTypeSelect from "../../../packageType/components/PackageTypeSelect";
import FormText from "../../../../shared/form/FormText";
import FormMoney from "../../../../shared/form/FormMoney";
import { FormEvent, useState } from "react";
import FormNumber from "../../../../shared/form/FormNumber";
import { Delete } from "@mui/icons-material";

export default function InputD(props: { onNext: () => void }) {

    const dispatch = useDispatch<any>()

    const { deliveryInput } = useSelector((state: RootState) => state.delivery)

    const [packageTemp, setPackageTemp] = useState<{ name: string, weight: number }>({ name: '', weight: 0 })

    const handleChangeValues = (name: keyof InterfaceDeliveryInput, value: any) => {
        dispatch(setDeliveryInput({
            ...deliveryInput,
            [name]: value
        }))
    }

    const handleDeletePackage = (index: number) => {
        handleChangeValues("package_list", deliveryInput.package_list?.filter((_, i) => i !== index))
    }

    const handleSubmitAddPackage = (e: FormEvent) => {
        e.preventDefault()
        handleChangeValues("package_list", [...(deliveryInput.package_list || []), packageTemp])
        setPackageTemp({ name: '', weight: 0 })
    }

    return (
        <Box>
            <Card sx={{ marginBottom: 1 }}>
                <CardContent>
                    {deliveryInput.package_list?.map((p, i) => (
                        <Grid container spacing={2} key={i}>
                            <Grid item xs={6}>
                                <FormText label="Nama" value={p.name} />
                            </Grid>
                            <Grid item xs={4}>
                                <FormNumber label="Berat" value={p.weight} />
                            </Grid>
                            <Grid item xs={2} display={"flex"} alignItems={"center"}>
                                <IconButton color="error" onClick={() => handleDeletePackage(i)}>
                                    <Delete />
                                </IconButton>
                            </Grid>
                        </Grid>
                    ))}
                    <form onSubmit={handleSubmitAddPackage}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <FormText label="Nama" value={packageTemp.name} onChange={(e) => setPackageTemp({ ...packageTemp, name: e.target.value })} />
                            </Grid>
                            <Grid item xs={4}>
                                <FormNumber label="Berat" value={packageTemp.weight} onChange={(e) => setPackageTemp({ ...packageTemp, weight: parseFloat(e.target.value) })} />
                            </Grid>
                            <Grid item xs={2} display={"flex"} alignItems={"center"}>
                                <Button type="submit">Tambah</Button>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
            <Box component={"form"} onSubmit={(e) => { e.preventDefault(); props.onNext() }}>
                <PackageTypeSelect value={deliveryInput.package_type?.id} onChange={(_, valueDetail) => handleChangeValues("package_type", valueDetail)} required />
                <FormMoney label="Biaya Packing" value={deliveryInput.price_packing || 0} onChange={(e) => handleChangeValues("price_packing", parseInt(e.target.value))} />
                <FormText label="Keterangan" value={deliveryInput.notes || ''} onChange={(e) => handleChangeValues("notes", e.target.value)} />
                <Button sx={{ visibility: "hidden", height: 0, padding: 0 }} type="submit">Next</Button>
            </Box>
        </Box>
    )
}