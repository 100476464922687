import { Box, Card, CardContent, CardHeader, Checkbox, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { useEffect } from "react";
import { getBookingForItinerary } from "../../../booking/data/booking.api";
import { getDeliveryForItinerary } from "../../../delivery/data/delivery.api";
import moneyParser from "../../../../shared/moneyParser";
import { amber, green } from "@mui/material/colors";
import { setItineraryInput } from "../../data/itinerary.reducer";

export default function ItineraryCreateC() {

    const dispatch = useDispatch<any>()

    const { itineraryInput } = useSelector((state: RootState) => state.itinerary)
    const { bookingItineraryList } = useSelector((state: RootState) => state.booking)
    const { deliveryItineraryList } = useSelector((state: RootState) => state.delivery)

    useEffect(() => {
        if (itineraryInput.schedule !== undefined && itineraryInput.start_date !== undefined) {
            dispatch(getBookingForItinerary({ start_date: itineraryInput.start_date, schedule_id: itineraryInput.schedule.id }))
            dispatch(getDeliveryForItinerary({ schedule_id: itineraryInput.schedule.id }))
        }
    }, [dispatch, itineraryInput])

    const handleChangeDelivery = (delivery_id: number) => {
        dispatch(setItineraryInput({
            ...itineraryInput,
            delivery_id_list: itineraryInput.delivery_id_list?.includes(delivery_id)
                ? itineraryInput.delivery_id_list?.filter((item) => item !== delivery_id)
                : (itineraryInput.delivery_id_list || []).concat([delivery_id])
        }))
    }

    const startTerminalList: string[] = bookingItineraryList.map((item) => item.start_terminal?.name || "-")
    const startTerminalListUnique = startTerminalList.filter((item, index) => startTerminalList.indexOf(item) === index)
    const finishTerminalList: string[] = bookingItineraryList.map((item) => item.finish_terminal?.name || "-")
    const finishTerminalListUnique = finishTerminalList.filter((item, index) => finishTerminalList.indexOf(item) === index)

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Card sx={{ height: "100%" }}>
                        <CardHeader title="Penumpang" />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Typography variant="h6">Keberangkatan</Typography>
                                    <ul>
                                        {startTerminalListUnique.map((terminal, terminalIndex) => {
                                            const customerList = bookingItineraryList.filter((item) => (item.start_terminal?.name || "-") === terminal).flatMap((item) => item.customer_list)
                                            const customerListUnique = customerList.filter((item, index) => customerList.map(c => c.customer.name).indexOf(item.customer.name) === index)
                                            return (
                                                <li key={terminalIndex}>
                                                    {terminal}
                                                    <ul>
                                                        {customerListUnique.map((customer, customerIndex) => (
                                                            <li key={customerIndex}>
                                                                {customer.customer.name}
                                                                <ul>
                                                                    {customerList.filter((item) => item.customer.name === customer.customer.name).map((item, itemIndex) => (
                                                                        <li key={itemIndex}>
                                                                            {item.seat.name}
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="h6">Tujuan</Typography>
                                    <ul>
                                        {finishTerminalListUnique.map((terminal, terminalIndex) => {
                                            const customerList = bookingItineraryList.filter((item) => (item.finish_terminal?.name || "-") === terminal).flatMap((item) => item.customer_list)
                                            const customerListUnique = customerList.filter((item, index) => customerList.map(c => c.customer.name).indexOf(item.customer.name) === index)
                                            return (
                                                <li key={terminalIndex}>
                                                    {terminal}
                                                    <ul>
                                                        {customerListUnique.map((customer, customerIndex) => (
                                                            <li key={customerIndex}>
                                                                {customer.customer.name}
                                                                <ul>
                                                                    {customerList.filter((item) => item.customer.name === customer.customer.name).map((item, itemIndex) => (
                                                                        <li key={itemIndex}>
                                                                            {item.seat.name}
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6}>
                    <Card sx={{ height: "100%" }}>
                        <CardHeader title="Paket" />
                        <CardContent>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell>Nomor Transaksi</TableCell>
                                        <TableCell>Jumlah Koli</TableCell>
                                        <TableCell>Total Harga</TableCell>
                                        <TableCell>Lunas</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {deliveryItineraryList.map((delivery, index) => {
                                        const isPaid = delivery.total === delivery.total_paid
                                        return (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    <Checkbox checked={itineraryInput.delivery_id_list?.includes(delivery.id) || false} onChange={() => handleChangeDelivery(delivery.id)} />
                                                </TableCell>
                                                <TableCell>{delivery.id}</TableCell>
                                                <TableCell>{delivery.package_list.length}</TableCell>
                                                <TableCell>{moneyParser(delivery.total)}</TableCell>
                                                <TableCell>
                                                    {isPaid
                                                        ? <Typography variant="body1" align="left" color={green[800]}>Sudah</Typography>
                                                        : <Typography variant="body1" align="left" color={amber[800]}>Belum</Typography>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box >
    )
}