export interface InterfaceCarTypeData {
    id: number,
    ts_created: string,
    name: string
}

export interface InterfaceCarTypeForm {
    id: number,
    name: string
}

export interface InterfaceCarTypeCreate {
    name: string
}

export interface InterfaceCarTypeEdit {
    id: number,
    name?: string
}

export const InitialCarTypeForm: InterfaceCarTypeForm = {
    id: 0,
    name: ""
}