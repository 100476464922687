import { createSlice } from '@reduxjs/toolkit'
import { createSparepart, deleteSparepart, editSparepart, getSparepart } from './sparepart.api'
import { InterfaceSparepartData } from './sparepart.interface'
import alertError from '../../../shared/alert/alertError'

export interface SparepartState {
    loading: boolean,
    sparepartList: InterfaceSparepartData[]
}

const initialState: SparepartState = {
    loading: false,
    sparepartList: []
}

export const sparepartSlice = createSlice({
    name: 'sparepart',
    initialState,
    reducers: {},
    extraReducers(builder) {

        builder.addCase(getSparepart.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getSparepart.fulfilled, (state, action) => {
            state.loading = false
            state.sparepartList = action.payload.data.data
        })
        builder.addCase(getSparepart.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(createSparepart.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createSparepart.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createSparepart.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(editSparepart.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editSparepart.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editSparepart.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(deleteSparepart.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteSparepart.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteSparepart.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })
    },
})

export default sparepartSlice.reducer