import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { RootState } from "../../../store";
import { Box, Button, Checkbox, FormControlLabel, IconButton, Typography, useTheme } from "@mui/material";
import moneyParser from "../../../shared/moneyParser";
import { amber, blue, pink } from "@mui/material/colors";
import { getSeat } from "../data/seat.api";
import { InterfaceSeatData, StatusPaid } from "../data/seat.interface";
import { Info } from "@mui/icons-material";
import { editBookingCustomer } from "../../booking/data/booking.api";

export default function SeatBookingEditor(props: { scheduleId: number, startDate: string, countReload: number, onClick: (seat: InterfaceSeatData) => void }) {

    const dispatch = useDispatch<any>()
    const theme = useTheme()

    const { seatList } = useSelector((state: RootState) => state.seat)

    useEffect(() => {
        dispatch(getSeat({ schedule_id: props.scheduleId, start_date: props.startDate }))
    }, [dispatch, props.scheduleId, props.startDate, props.countReload])

    const maxX = seatList.map(seat => seat.x).reduce((prev, current) => Math.max(prev, current), 0)
    const maxY = seatList.map(seat => seat.y).reduce((prev, current) => Math.max(prev, current), 0)

    const handleEditReady = async (id: number, is_ready: boolean) => {
        await dispatch(editBookingCustomer({ id: id, is_ready: is_ready }))
        dispatch(getSeat({ schedule_id: props.scheduleId, start_date: props.startDate }))
    }

    return (
        <Box width={"60vw"} display={"flex"} gap={1} marginTop={1}>
            {Array.from({ length: maxY }, (_, y) => (
                <Box width={"100%"} key={y} display={"flex"} gap={1} flexDirection={"column"}>
                    {Array.from({ length: maxX }, (_, x) => {
                        const seat = seatList.find(seat => seat.x === x + 1 && seat.y === y + 1)
                        if (seat) {
                            const customerOnSeat = seat.customer_list && seat.customer_list[0]
                            return (
                                <Box
                                    component={"button"}
                                    border={0}
                                    key={x}
                                    padding={1}
                                    height={141}
                                    display={"flex"}
                                    gap={1}
                                    bgcolor={customerOnSeat ? amber[100] : undefined}
                                >
                                    <Box height={"100%"} display={"flex"} flexGrow={1} flexDirection={"column"} alignItems={"flex-start"} justifyContent={"space-between"}>
                                        <Box>
                                            <Typography variant="h3" align="left">{seat?.name}</Typography>
                                            <Typography variant="body2">({moneyParser(seat?.price)})</Typography>
                                        </Box>
                                        {customerOnSeat && (
                                            <Button variant="outlined" color="info" onClick={() => window.open(`/#/booking/print/${customerOnSeat.booking.id}`, "_blank")}>Print</Button>
                                        )}
                                    </Box>
                                    {customerOnSeat && (
                                        <Box position={"relative"} width={"100%"} sx={{ borderLeft: 1, paddingLeft: 1 }}>
                                            <Box position={"absolute"} right={0}>
                                                <IconButton onClick={() => props.onClick(seat)}>
                                                    <Info />
                                                </IconButton>
                                            </Box>
                                            <Typography variant="body1" align="left" whiteSpace={"nowrap"}>{customerOnSeat?.customer.name}</Typography>
                                            <Typography variant="body2" align="left" whiteSpace={"nowrap"} fontWeight={"bold"}>{customerOnSeat?.customer.phone}</Typography>
                                            <Typography variant="body2" align="left" whiteSpace={"nowrap"} color={(customerOnSeat.customer.gender === "Lakilaki" && blue[400]) || pink[400]}>{customerOnSeat?.customer.gender}</Typography>
                                            <Typography variant="body2" align="left" whiteSpace={"nowrap"}>{customerOnSeat?.booking.start_terminal?.name} - {customerOnSeat.booking.finish_terminal?.name}</Typography>
                                            {
                                                (customerOnSeat?.total_paid === 0 && <Typography variant="body1" align="left" color={StatusPaid[0].color}>{StatusPaid[0].name}</Typography>) ||
                                                (customerOnSeat?.total_paid < customerOnSeat?.total && <Typography variant="body1" align="left" color={StatusPaid[1].color}>{StatusPaid[1].name}</Typography>) ||
                                                (customerOnSeat?.total_paid >= customerOnSeat?.total && <Typography variant="body1" align="left" color={StatusPaid[2].color}>{StatusPaid[2].name}</Typography>)
                                            }
                                            <Box display={"flex"}>
                                                <FormControlLabel
                                                    sx={{ marginLeft: 0 }}
                                                    label="Jadi Berangkat"
                                                    control={<Checkbox checked={customerOnSeat?.is_ready} onChange={(e) => handleEditReady(customerOnSeat.id, e.target.checked)} />}
                                                />
                                            </Box>
                                        </Box>
                                    )}
                                </Box>
                            )
                        }
                        return (
                            <Box
                                component={"button"}
                                border={0}
                                key={x}
                                padding={1}
                                display={"flex"}
                                flexGrow={1}
                                bgcolor={theme.palette.background.paper}
                            >
                            </Box>
                        )
                    })}
                </Box>
            ))}
        </Box>
    )
}