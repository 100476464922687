export interface InterfaceSparepartData {
    id: number,
    ts_created: string,
    code: string,
    name: string,
    price: number,
    brand: string,
    type: string,
    acc: string,
    category_id: number,
    warehouse_id: number,
    category: {
        name: true
    },
    warehouse: {
        name: true
    },
    used_list: {
        qty: number
    }[]
}

export interface InterfaceSparepartForm {
    id: number,
    code: string,
    name: string,
    price: number,
    brand: string,
    type: string,
    acc: string,
    category_id: number,
    warehouse_id: number
}

export interface InterfaceSparepartCreate {
    code: string,
    name: string,
    price: number,
    brand: string,
    type: string,
    acc: string,
    category_id: number,
    warehouse_id: number
}

export interface InterfaceSparepartEdit {
    id: number,
    code: string,
    name: string,
    price: number,
    brand: string,
    type: string,
    acc: string,
    category_id: number,
    warehouse_id: number
}

export const InitialSparepartForm: InterfaceSparepartForm = {
    id: 0,
    code: "",
    name: "",
    price: 0,
    brand: "",
    type: "",
    acc: "",
    category_id: 0,
    warehouse_id: 0
}