export type TypeGender = "Lakilaki" | "Perempuan"

export interface InterfaceCustomerData {
    id: number,
    ts_created: string,
    name: string,
    phone: string,
    email: string,
    gender: TypeGender
}

export interface InterfaceCustomerForm {
    id: number,
    name: string,
    phone: string,
    email: string,
    gender: TypeGender
}

export interface InterfaceCustomerCreate {
    name: string,
    phone: string,
    email: string,
    gender: TypeGender
}

export interface InterfaceCustomerEdit {
    id: number,
    name?: string,
    phone?: string,
    email?: string,
    gender?: TypeGender
}

export const InitialCustomerForm: InterfaceCustomerForm = {
    id: 0,
    name: "",
    phone: "",
    email: "",
    gender: "Lakilaki"
}