import { Card, CardContent, Container } from "@mui/material";
import DeliveryInput from "../features/delivery/components/input/Input";

export default function DeliveryPage() {

    return (
        <Container>
            <Card>
                <CardContent>
                    <DeliveryInput />
                </CardContent>
            </Card>
        </Container>
    )
}