import { Box, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useEffect } from "react";
import CustomTable from "../../../shared/CustomTable";
import { InterfaceBuyData } from "../data/buy.interface";
import { Edit } from "@mui/icons-material";
import { getBuy } from "../data/buy.api";
import moment from "moment";
import moneyParser from "../../../shared/moneyParser";

export default function BuyTableDebt(props: {
    onEdit?: (data: InterfaceBuyData) => void,
}) {

    const dispatch = useDispatch<any>()

    const { buyList } = useSelector((state: RootState) => state.buy)

    useEffect(() => {
        dispatch(getBuy())
    }, [dispatch])

    return (
        <Box>
            <CustomTable
                columns={[
                    {
                        name: "Supplier",
                        key: "supplier_id",
                        render: (data) => data.supplier.name
                    },
                    {
                        name: "Deskripsi",
                        key: "description"
                    },
                    {
                        name: "Tanggal Jatuh Tempo",
                        key: "due_date",
                        render: (data) => data.due_date ? moment(data.due_date).format("YYYY-MM-DD") : "-"
                    },
                    {
                        name: "Total",
                        key: "total",
                        render: (data) => moneyParser(data.total)
                    },
                    {
                        name: "Total Dibayar",
                        key: "total_paid",
                        render: (data) => moneyParser(data.total_paid)
                    },
                    {
                        name: "Sisa Hutang",
                        key: "total",
                        render: (data) => moneyParser(data.total - data.total_paid)
                    }
                ]}
                dataList={buyList.filter(data => data.total_paid < data.total)}
                actionList={(props.onEdit)
                    ? (data) => (
                        <Box display={"flex"} justifyContent={"flex-end"} gap={1}>
                            {props.onEdit && (<Button startIcon={<Edit />} color="warning" onClick={() => props.onEdit && props.onEdit(data)}>Edit</Button>)}
                        </Box>
                    )
                    : undefined
                }
            />
        </Box>
    )
}