export interface InterfaceSalaryData {
    id: number,
    ts_created: string,
    employee_id: number,
    account_source_id: number,
    account_id: number,
    day_count: number,
    day_salary: number,
    day_consumption: number,
    craft: number,
    bonus: number,
    cut: number,
    employee: {
        name: string
    },
    account_source: {
        name: string
    },
    account: {
        name: string
    }
}

export interface InterfaceSalaryForm {
    id: number,
    employee_id: number,
    account_source_id: number,
    account_id: number,
    day_count: number,
    day_salary: number,
    day_consumption: number,
    craft: number,
    bonus: number,
    cut: number,
}

export interface InterfaceSalaryCreate {
    employee_id: number,
    account_source_id: number,
    account_id: number,
    day_count: number,
    day_salary: number,
    day_consumption: number,
    craft: number,
    bonus: number,
    cut: number,
    commision_ids: number[],
    cash_ids: number[]
}

export interface InterfaceSalaryEdit {
    id: number,
    employee_id?: number,
    account_source_id?: number,
    account_id?: number,
    day_count?: number,
    day_salary?: number,
    day_consumption?: number,
    craft?: number,
    bonus?: number,
    cut?: number,
}

export const InitialSalaryForm: InterfaceSalaryForm = {
    id: 0,
    employee_id: 0,
    account_source_id: 0,
    account_id: 0,
    day_count: 0,
    day_salary: 0,
    day_consumption: 0,
    craft: 0,
    bonus: 0,
    cut: 0,
}


export interface InterfaceGetCommisionParams {
    salary_id?: number,
    employee_id?: number,
}

export interface InterfaceGetCashParams extends InterfaceGetCommisionParams { }

export interface InterfaceCommisionData {
    id: number,
    ts_created: string,
    value: number
}

export interface InterfaceCashData extends InterfaceCommisionData { }