import { Box, Button } from "@mui/material";
import { FormEvent } from "react";
import { InitialSupplierForm, InterfaceSupplierForm } from "../data/supplier.interface";
import FormText from "../../../shared/form/FormText";
import { useDispatch } from "react-redux";
import alertSuccess from "../../../shared/alert/alertSuccess";
import { useFormValues } from "../../../hooks/useFormValues";
import { createSupplier, editSupplier, getSupplier } from "../data/supplier.api";
import CitySelect from "../../city/components/CitySelect";

export default function SupplierForm(props: { mode: "ADD" | "EDIT", data?: InterfaceSupplierForm, onSubmit: () => void }) {

    const dispatch = useDispatch<any>()

    const { values, handleChange, handleChangeValues } = useFormValues<InterfaceSupplierForm>(props.data || InitialSupplierForm)

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault()
        try {
            if (props.mode === "ADD") {
                await dispatch(createSupplier(values)).unwrap()
                await alertSuccess("Berhasil menyimpan data")
            }
            else if (props.mode === "EDIT") {
                await dispatch(editSupplier(values)).unwrap()
                await alertSuccess("Berhasil mengubah data")
            }
            await dispatch(getSupplier())
            props.onSubmit()
        } catch (error) { }
    }

    return (
        <Box component={"form"} onSubmit={handleSubmit}>
            <FormText label="Kode" name="code" value={values.code} onChange={handleChange} />
            <FormText label="Nama" name="name" value={values.name} onChange={handleChange} />
            <FormText label="Telepon" name="phone" value={values.phone} onChange={handleChange} />
            <FormText label="Alamat" name="address" value={values.address} onChange={handleChange} />
            <CitySelect value={values.city_id} onChange={(value) => handleChangeValues("city_id", value)} />
            <Box marginTop={1} display={"flex"} justifyContent={"flex-end"}>
                <Button type="submit">Simpan</Button>
            </Box>
        </Box>
    )
}