import { Box, CircularProgress, Modal } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

export default function Loading() {

    const loadingAuth = useSelector((state: RootState) => state.auth.loading)

    const loadingBooking = useSelector((state: RootState) => state.booking.loading)
    const loadingDelivery = useSelector((state: RootState) => state.delivery.loading)

    const loadingItinerary = useSelector((state: RootState) => state.itinerary.loading)

    const loadingEmployeeCash = useSelector((state: RootState) => state.employeeCash.loading)
    const loadingSalary = useSelector((state: RootState) => state.salary.loading)
    const loadingReport = useSelector((state: RootState) => state.report.loading)
    const loadingFlow = useSelector((state: RootState) => state.flow.loading)

    const loadingCustomer = useSelector((state: RootState) => state.customer.loading)

    const loadingCar = useSelector((state: RootState) => state.car.loading)
    const loadingCarType = useSelector((state: RootState) => state.carType.loading)

    const loadingBuy = useSelector((state: RootState) => state.buy.loading)
    const loadingSupplier = useSelector((state: RootState) => state.supplier.loading)

    const loadingSparepartUsed = useSelector((state: RootState) => state.sparepartUsed.loading)
    const loadingSparepart = useSelector((state: RootState) => state.sparepart.loading)
    const loadingSparepartCategory = useSelector((state: RootState) => state.sparepartCategory.loading)

    const loadingSetting = useSelector((state: RootState) => state.setting.loading)
    const loadingAccount = useSelector((state: RootState) => state.account.loading)
    const loadingPackegeType = useSelector((state: RootState) => state.packageType.loading)
    const loadingSchedule = useSelector((state: RootState) => state.schedule.loading)
    const loadingSeat = useSelector((state: RootState) => state.seat.loading)
    const loadingTerminal = useSelector((state: RootState) => state.terminal.loading)
    const loadingWarehouse = useSelector((state: RootState) => state.warehouse.loading)
    const loadingCity = useSelector((state: RootState) => state.city.loading)
    const loadingProvince = useSelector((state: RootState) => state.province.loading)
    const loadingEmployee = useSelector((state: RootState) => state.employee.loading)
    const loadingUser = useSelector((state: RootState) => state.user.loading)

    return (
        <Modal open={

            loadingAuth ||

            loadingBooking ||
            loadingDelivery ||

            loadingItinerary ||

            loadingEmployeeCash ||
            loadingSalary ||
            loadingReport ||
            loadingFlow ||

            loadingCustomer ||

            loadingCar ||
            loadingCarType ||

            loadingBuy ||
            loadingSupplier ||

            loadingSparepartUsed ||
            loadingSparepart ||
            loadingSparepartCategory ||

            loadingSetting ||
            loadingAccount ||
            loadingPackegeType ||
            loadingSchedule ||
            loadingSeat ||
            loadingTerminal ||
            loadingWarehouse ||
            loadingCity ||
            loadingProvince ||
            loadingEmployee ||
            loadingUser

        }>
            <Box display={"flex"} alignItems={"center"} justifyContent={"center"} height={"100vh"}>
                <CircularProgress />
            </Box>
        </Modal>
    )
}