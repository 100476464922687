export interface InterfacePackageTypeData {
    id: number,
    ts_created: string,
    code: string,
    name: string,
    is_active: boolean,
    price: number | null
}

export interface InterfacePackageTypeForm {
    id: number,
    code: string,
    name: string,
    is_active: boolean,
    price: number | null
}

export interface InterfacePackageTypeCreate {
    code: string,
    name: string,
    is_active: boolean,
    price: number | null
}

export interface InterfacePackageTypeEdit {
    id: number,
    code?: string,
    name?: string,
    is_active?: boolean,
    price?: number | null
}

export const InitialPackageTypeForm: InterfacePackageTypeForm = {
    id: 0,
    code: "",
    name: "",
    is_active: true,
    price: null
}