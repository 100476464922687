import InputA from "./InputA";
import InputB from "./InputB";
import InputC from "./InputC";
import InputD from "./InputD";
import InputE from "./InputE";

import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { Box, Button, Tab, Tabs, useTheme } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import moment from "moment";
import { InterfaceDeliveryInput } from "../../data/delivery.interface";
import { setDeliveryInput } from "../../data/delivery.reducer";
import { getSchedule } from "../../../schedule/data/schedule.api";
import { AxiosResponse } from "axios";
import { InterfaceScheduleData } from "../../../schedule/data/schedule.interface";

export default function Input(props: {
    scheduleId?: number,
    onSubmit?: (id: number) => void
}) {

    const dispatch = useDispatch<any>()
    const theme = useTheme()

    const { deliveryInput } = useSelector((state: RootState) => state.delivery)

    const [step, setStep] = useState(0)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const initialData = async () => {
            const initialData: InterfaceDeliveryInput = {}
            if (deliveryInput.ts_created === undefined) {
                initialData.ts_created = moment().format('YYYY-MM-DD HH:mm:ss')
            }
            if (deliveryInput.start_date === undefined) {
                initialData.start_date = moment().format('YYYY-MM-DD')
            }
            if (props.scheduleId !== undefined && deliveryInput.schedule === undefined) {
                const response: AxiosResponse = await dispatch(getSchedule({ id: props.scheduleId })).unwrap()
                const schedule: InterfaceScheduleData = response.data.data[0]
                initialData.schedule = schedule
                initialData.start_city = schedule.start_city
                initialData.finish_city = schedule.finish_city
                setStep(2)
            }
            if (Object.keys(initialData).length > 0) {
                dispatch(setDeliveryInput(initialData))
            }
            setLoading(false)
        }
        initialData()
    }, [
        dispatch,
        props.scheduleId,
        deliveryInput
    ])

    useEffect(() => {
        if (
            step === 0 &&
            deliveryInput.ts_created !== undefined &&
            deliveryInput.start_date !== undefined &&
            deliveryInput.start_terminal !== undefined &&
            deliveryInput.finish_terminal !== undefined
        ) {
            if (
                deliveryInput.schedule !== undefined
            ) {
                setStep(2)
            }
            else {
                setStep(1)
            }
        }
        if (
            step === 1 &&
            deliveryInput.schedule !== undefined
        ) {
            setStep(2)
        }
        // eslint-disable-next-line
    }, [deliveryInput])

    const handleSubmit = (id: number) => {
        dispatch(setDeliveryInput({}))
        setStep(0)
        if (props.onSubmit) {
            props.onSubmit(id)
        }
    }

    const steps = [
        {
            name: "Layanan",
            component: <InputA />
        },
        {
            name: "Jam Keberangkatan",
            component: <InputB />
        },
        {
            name: "Alamat",
            component: <InputC onNext={() => setStep(step + 1)} />
        },
        {
            name: "Barang",
            component: <InputD onNext={() => setStep(step + 1)} />
        },
        {
            name: "Pembayaran",
            component: <InputE onSubmit={handleSubmit} />
        }
    ]

    const enableBack = () => {
        if (step === 0) {
            return false
        }
        return true
    }

    const enableNext = () => {
        if (step === 0 && (deliveryInput.start_terminal === undefined || deliveryInput.finish_terminal === undefined)) {
            return false
        }
        if (step === 1 && deliveryInput.schedule === undefined) {
            return false
        }
        if (step === 4) {
            return false
        }
        return true
    }

    return (
        <Fragment>
            <Box position={"sticky"} top={0} bgcolor={theme.palette.background.paper}>
                <Box display={"flex"} justifyContent={"flex-end"} gap={1}>
                    <Button variant="text" disabled={!enableBack()} startIcon={<ArrowBackIos />} onClick={() => setStep(step - 1)}>Back</Button>
                    <Button variant="text" disabled={!enableNext()} endIcon={<ArrowForwardIos />} onClick={() => setStep(step + 1)}>Next</Button>
                </Box>
                <Tabs value={step}>
                    {steps.map((step, index) => (
                        <Tab key={index} value={index} label={step.name} />
                    ))}
                </Tabs>
            </Box>
            <Box marginTop={1}>
                {loading ? "Loading..." : steps[step].component}
            </Box>
        </Fragment>
    )
}