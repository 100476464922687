import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { Box, Button, Tab, Tabs, useTheme } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBookingInput } from "../../data/booking.reducer";
import { RootState } from "../../../../store";
import moment from "moment";

import InputA from "./InputA";
import InputB from "./InputB";
import InputC from "./InputC";
import InputD from "./InputD";
import InputE from "./InputE";
import { getSchedule } from "../../../schedule/data/schedule.api";
import { AxiosResponse } from "axios";
import { InterfaceScheduleData } from "../../../schedule/data/schedule.interface";
import { InterfaceBookingInput } from "../../data/booking.interface";
import { getCity } from "../../../city/data/city.api";

export default function Input(props: {
    startDate?: string,
    scheduleId?: number,
    startCityId?: number,
    finishCityId?: number,
    isAllowSubmit: boolean,
    onSubmit?: (id: number) => void
}) {

    const dispatch = useDispatch<any>()
    const theme = useTheme()

    const { bookingInput } = useSelector((state: RootState) => state.booking)

    const [step, setStep] = useState(0)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const initialData = async () => {
            const initialData: InterfaceBookingInput = {}
            if (bookingInput.ts_created === undefined) {
                initialData.ts_created = moment().format('YYYY-MM-DD HH:mm:ss')
            }
            if (bookingInput.start_date === undefined) {
                initialData.start_date = props.startDate || moment().format('YYYY-MM-DD')
            }
            if (props.scheduleId !== undefined && bookingInput.schedule === undefined) {
                const response: AxiosResponse = await dispatch(getSchedule({ id: props.scheduleId })).unwrap()
                const schedule: InterfaceScheduleData = response.data.data[0]
                initialData.schedule = schedule
                initialData.start_city = schedule.start_city
                initialData.finish_city = schedule.finish_city
            }
            if (props.startCityId !== undefined && bookingInput.start_city === undefined) {
                const response: AxiosResponse = await dispatch(getCity({ id: props.startCityId })).unwrap()
                const city = response.data.data[0]
                initialData.start_city = city
            }
            if (props.finishCityId !== undefined && bookingInput.finish_city === undefined) {
                const response: AxiosResponse = await dispatch(getCity({ id: props.finishCityId })).unwrap()
                const city = response.data.data[0]
                initialData.finish_city = city
            }
            if (Object.keys(initialData).length > 0) {
                dispatch(setBookingInput(initialData))
            }
            setLoading(false)
        }
        initialData()
    }, [
        dispatch,
        props.startDate,
        props.scheduleId,
        props.startCityId,
        props.finishCityId,
        bookingInput
    ])

    useEffect(() => {
        if (
            step === 0 &&
            bookingInput.ts_created !== undefined &&
            bookingInput.start_date !== undefined &&
            bookingInput.start_city !== undefined &&
            bookingInput.finish_city !== undefined
        ) {
            if (
                bookingInput.schedule !== undefined
            ) {
                setStep(2)
            }
            else {
                setStep(1)
            }
        }
        if (
            step === 1 &&
            bookingInput.schedule !== undefined
        ) {
            setStep(2)
        }
        // eslint-disable-next-line
    }, [bookingInput])

    const steps = [
        {
            name: "Tujuan dan Tanggal",
            component: <InputA />
        },
        {
            name: "Jam Keberangkatan",
            component: <InputB />
        },
        {
            name: "Penumpang",
            component: <InputC />
        },
        {
            name: "Kursi",
            component: <InputD />
        },
        {
            name: "Pembayaran",
            component: <InputE isAllowSubmit={props.isAllowSubmit} onSubmit={(id) => props.onSubmit && props.onSubmit(id)} />
        }
    ]

    const enableBack = () => {
        if (step === 0) {
            return false
        }
        return true
    }

    const enableNext = () => {
        if (step === 0 && (bookingInput.start_city === undefined || bookingInput.finish_city === undefined)) {
            return false
        }
        if (step === 1 && bookingInput.schedule === undefined) {
            return false
        }
        if (step === 2 && (bookingInput.customer_list === undefined || bookingInput.customer_list?.length === 0)) {
            return false
        }
        if (step === 3 && bookingInput.customer_list?.some(customer => customer.seat === undefined)) {
            return false
        }
        if (step === 4) {
            return false
        }
        return true
    }

    console.log(bookingInput.customer_list)

    return (
        <Fragment>
            <Box position={"sticky"} top={0} bgcolor={theme.palette.background.paper}>
                <Box display={"flex"} justifyContent={"flex-end"} gap={1}>
                    <Button variant="text" disabled={!enableBack()} startIcon={<ArrowBackIos />} onClick={() => setStep(step - 1)}>Back</Button>
                    <Button variant="text" disabled={!enableNext()} endIcon={<ArrowForwardIos />} onClick={() => setStep(step + 1)}>Next</Button>
                </Box>
                <Tabs value={step}>
                    {steps.map((step, index) => (
                        <Tab key={index} value={index} label={step.name} />
                    ))}
                </Tabs>
            </Box>
            <Box marginTop={1}>
                {loading ? "Loading..." : steps[step].component}
            </Box>
        </Fragment>
    )
}