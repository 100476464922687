import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../../state";
import { InterfaceSupplierCreate, InterfaceSupplierEdit } from "./supplier.interface";
import { RootState } from "../../../store";

export const getSupplier = createAsyncThunk('getSupplier', async (_, thunkAPI) => {
    try {
        const response = await axios({
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + (thunkAPI.getState() as RootState).auth.loginData.key
            },
            url: baseUrl + '/supplier'
        })
        return response
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error)
    }
})

export const createSupplier = createAsyncThunk('createSupplier', async (data: InterfaceSupplierCreate, thunkAPI) => {
    try {
        const response = await axios({
            method: 'post',
            headers: {
                'Authorization': 'Bearer ' + (thunkAPI.getState() as RootState).auth.loginData.key
            },
            url: baseUrl + '/supplier',
            data: data
        })
        return response
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error)
    }
})

export const editSupplier = createAsyncThunk('editSupplier', async (data: InterfaceSupplierEdit, thunkAPI) => {
    try {
        const response = await axios({
            method: 'put',
            headers: {
                'Authorization': 'Bearer ' + (thunkAPI.getState() as RootState).auth.loginData.key
            },
            url: baseUrl + '/supplier',
            data: data
        })
        return response
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error)
    }
})

export const deleteSupplier = createAsyncThunk('deleteSupplier', async (id: number, thunkAPI) => {
    try {
        const response = await axios({
            method: 'delete',
            headers: {
                'Authorization': 'Bearer ' + (thunkAPI.getState() as RootState).auth.loginData.key
            },
            url: baseUrl + '/supplier',
            params: {
                id: id
            }
        })
        return response
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error)
    }
})