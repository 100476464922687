export interface InterfaceUserData {
    id: number,
    ts_created: string,
    name: string,
    username: string,
    password: string,
    access: Array<{
        url: string,
        access: string[]
    }>
}

export interface InterfaceMenuData {
    id: number,
    order: number,
    name: string
    url: string
    access: string[]
}

export interface InterfaceUserForm {
    id: number,
    name: string,
    username: string,
    password: string,
    access: Array<{
        url: string,
        access: string[]
    }>
}

export interface InterfaceUserCreate {
    name: string,
    username: string,
    password: string
}

export interface InterfaceUserEdit {
    id: number,
    name: string,
    username: string,
    password: string
}

export const InitialUserForm: InterfaceUserForm = {
    id: 0,
    name: "",
    username: "",
    password: "",
    access: []
}