export interface InterfaceSparepartUsedData {
    id: number,
    ts_created: string,
    sparepart_id: number,
    qty: number,
    note: string,
    sparepart: {
        name: string
    }
}

export interface InterfaceSparepartUsedForm {
    id: number,
    sparepart_id: number,
    qty: number,
    note: string
}

export interface InterfaceSparepartUsedCreate {
    sparepart_id: number,
    qty: number,
    note: string
}

export interface InterfaceSparepartUsedEdit {
    id: number,
    sparepart_id?: number,
    qty?: number,
    note?: string
}

export const InitialSparepartUsedForm: InterfaceSparepartUsedForm = {
    id: 0,
    sparepart_id: 0,
    qty: 0,
    note: ""
}