import { Box, Button, Container, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import CustomModal from "../../shared/CustomModal";
import { useState } from "react";
import { InterfaceAccountData } from "../../features/account/data/account.interface";
import { useDispatch } from "react-redux";
import alertDelete from "../../shared/alert/alertDelete";
import { deleteAccount, getAccount } from "../../features/account/data/account.api";
import alertSuccess from "../../shared/alert/alertSuccess";
import useAccess from "../../hooks/useAccess";
import AccountForm from "../../features/account/components/AccountForm";
import AccountTree from "../../features/account/components/AccountTree";

export default function AccountPage() {

    const dispatch = useDispatch<any>()
    const access = useAccess()

    const [modalAdd, setModalAdd] = useState<boolean>(false)
    const [modalEdit, setModalEdit] = useState<boolean>(false)
    const [dataSelected, setDataSelected] = useState<InterfaceAccountData | undefined>(undefined)

    const handleAdd = () => {
        setModalAdd(true)
    }

    const handleEdit = (data: InterfaceAccountData) => {
        setDataSelected(data)
        setModalEdit(true)
    }

    const handleDelete = (id: number) => {
        alertDelete()
            .then(async isConfirmed => {
                if (isConfirmed) {
                    try {
                        await dispatch(deleteAccount(id))
                        await alertSuccess("Berhasil menghapus data")
                        await dispatch(getAccount())
                    } catch (error) { }
                }
            })
    }

    const handleAddSub = (data: InterfaceAccountData) => {
        setDataSelected(data)
        setModalAdd(true)
    }

    const handleSubmitAdd = () => {
        setModalAdd(false)
    }

    const handleSubmitEdit = () => {
        setModalEdit(false)
    }

    const handleCloseModal = () => {
        setModalAdd(false)
        setModalEdit(false)
        setDataSelected(undefined)
    }

    return (
        <Container>
            <CustomModal
                open={modalAdd}
                title="Tambah Kategori"
                onClose={handleCloseModal}
                component={<AccountForm mode="ADD" parrentId={dataSelected?.id || undefined} onSubmit={handleSubmitAdd} />}
            />
            <CustomModal
                open={modalEdit}
                title="Edit Kategori"
                onClose={handleCloseModal}
                component={<AccountForm mode="EDIT" data={dataSelected} onSubmit={handleSubmitEdit} />}
            />
            <Typography variant="h3">Akun Keuangan</Typography>
            {access("CREATE") && (
                <Box marginTop={1} display={"flex"} justifyContent={"flex-end"}>
                    <Button startIcon={<Add />} onClick={handleAdd}>Tambah</Button>
                </Box>
            )}
            <br />
            <AccountTree onAdd={access("CREATE") ? handleAddSub : undefined} onEdit={access("EDIT") ? handleEdit : undefined} onDelete={access("DELETE") ? handleDelete : undefined} />
        </Container>
    )
}