import { Box, Button, Card, CardContent, Divider, IconButton, List, ListItemButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSchedule } from "../../../schedule/data/schedule.api";
import CustomerTable from "../../../customer/components/CustomerTable";
import CustomModal from "../../../../shared/CustomModal";
import CustomerForm from "../../../customer/components/CustomerForm";
import { Add, Close, Remove } from "@mui/icons-material";
import useAccess from "../../../../hooks/useAccess";
import { InterfaceCustomerData } from "../../../customer/data/customer.interface";
import { setBookingInput } from "../../data/booking.reducer";
import { RootState } from "../../../../store";
import FormNumber from "../../../../shared/form/FormNumber";

export default function InputC() {

    const access = useAccess("/customer")
    const dispatch = useDispatch<any>()

    const [modalAdd, setModalAdd] = useState<boolean>(false)
    const [dataSelected, setDataSelected] = useState<InterfaceCustomerData | undefined>(undefined)
    const { bookingInput } = useSelector((state: RootState) => state.booking)
    const customerDataList = useSelector((state: RootState) => state.customer).customerList

    useEffect(() => {
        dispatch(getSchedule({}))
    }, [dispatch])

    useEffect(() => {
        if (modalAdd && customerDataList.length > 0) {
            dispatch(setBookingInput({ ...bookingInput, customer_list: [...customerList, { customer: customerDataList[0] }] }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerDataList])

    const handleDecreaseCusotomer = (customer: InterfaceCustomerData) => {
        const dataWithoutThis = bookingInput.customer_list?.filter(data => data.customer.id !== customer.id) || []
        const dataThis = bookingInput.customer_list?.filter(data => data.customer.id === customer.id) || []
        dispatch(setBookingInput({ ...bookingInput, customer_list: [...dataWithoutThis, ...dataThis.filter((_, i) => i !== 0)] }))
    }

    const handleIncreaseCusotomer = (customer: InterfaceCustomerData) => {
        dispatch(setBookingInput({ ...bookingInput, customer_list: [...bookingInput.customer_list || [], { customer: customer }] }))
    }

    const customerList = bookingInput.customer_list || []
    const customerListUnique = customerList.filter((data, index, self) => self.findIndex(t => t.customer.id === data.customer.id) === index)

    return (
        <Box>
            <Card>
                <CardContent>
                    <List>
                        {customerListUnique
                            .sort((a, b) => a.customer.name.localeCompare(b.customer.name))
                            .map((customer, index) => (
                                <ListItemButton key={index}>
                                    <Box display={"flex"} flexGrow={1} justifyContent={"space-between"}>
                                        <Box display={"flex"} alignItems={"center"}>
                                            <IconButton onClick={() => dispatch(setBookingInput({ ...bookingInput, customer_list: bookingInput.customer_list?.filter(data => data.customer.id !== customer.customer.id) }))}>
                                                <Close />
                                            </IconButton>
                                            <Typography>{customer.customer.name}</Typography>
                                        </Box>
                                        <Box display={"flex"} alignItems={"center"} gap={1}>
                                            <FormNumber
                                                label="Diskon (%)"
                                                value={customer.discount || 0}
                                                onChange={e => dispatch(setBookingInput({
                                                    ...bookingInput,
                                                    customer_list: customerList.map(c => c.customer.id === customer.customer.id ? { ...c, discount: parseInt(e.target.value) } : c)
                                                }))} />
                                            <IconButton onClick={() => handleDecreaseCusotomer(customer.customer)}>
                                                <Remove />
                                            </IconButton>
                                            <Typography>
                                                {customerList.filter(data => data.customer.id === customer.customer.id).length}
                                            </Typography>
                                            <IconButton onClick={() => handleIncreaseCusotomer(customer.customer)}>
                                                <Add />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </ListItemButton>
                            ))}
                    </List>
                </CardContent>
            </Card>
            <CustomModal
                open={modalAdd}
                title="Tambah Pelanggan"
                onClose={() => setModalAdd(false)}
                component={<CustomerForm mode="ADD" onSubmit={() => setModalAdd(false)} />}
            />
            <CustomModal
                open={Boolean(dataSelected)}
                title="Edit Pelanggan"
                onClose={() => setDataSelected(undefined)}
                component={<CustomerForm mode="EDIT" data={dataSelected} onSubmit={() => setDataSelected(undefined)} />}
            />
            <Divider sx={{ marginTop: 2 }} />
            {access("CREATE") && (
                <Box marginY={1} display={"flex"} justifyContent={"flex-end"}>
                    <Button startIcon={<Add />} onClick={() => setModalAdd(true)}>Tambah</Button>
                </Box>
            )}
            <CustomerTable
                onEdit={access("EDIT") ? setDataSelected : undefined}
                onSelect={data => dispatch(setBookingInput({ ...bookingInput, customer_list: [...customerList, { customer: data }] }))}
                onAdd={() => access("CREATE") ? setModalAdd(true) : undefined}
            />
        </Box>
    )
}