import { createSlice } from '@reduxjs/toolkit'
import { createSupplier, deleteSupplier, editSupplier, getSupplier } from './supplier.api'
import { InterfaceSupplierData } from './supplier.interface'
import alertError from '../../../shared/alert/alertError'

export interface SupplierState {
    loading: boolean,
    supplierList: InterfaceSupplierData[]
}

const initialState: SupplierState = {
    loading: false,
    supplierList: []
}

export const supplierSlice = createSlice({
    name: 'supplier',
    initialState,
    reducers: {},
    extraReducers(builder) {

        builder.addCase(getSupplier.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getSupplier.fulfilled, (state, action) => {
            state.loading = false
            state.supplierList = action.payload.data.data
        })
        builder.addCase(getSupplier.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(createSupplier.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createSupplier.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createSupplier.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(editSupplier.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editSupplier.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editSupplier.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(deleteSupplier.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteSupplier.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteSupplier.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })
    },
})

export default supplierSlice.reducer