import { Box, Button } from "@mui/material";
import { ChangeEvent, FormEvent } from "react";
import { InitialPackageTypeForm, InterfacePackageTypeForm } from "../data/packageType.interface";
import FormText from "../../../shared/form/FormText";
import { useDispatch } from "react-redux";
import alertSuccess from "../../../shared/alert/alertSuccess";
import { useFormValues } from "../../../hooks/useFormValues";
import { createPackageType, editPackageType, getPackageType } from "../data/packageType.api";
import FormCheck from "../../../shared/form/FormCheck";
import FormMoney from "../../../shared/form/FormMoney";

export default function PackageTypeForm(props: { mode: "ADD" | "EDIT", data?: InterfacePackageTypeForm, onSubmit: () => void }) {

    const dispatch = useDispatch<any>()

    const { values, handleChange, handleChangeValues } = useFormValues<InterfacePackageTypeForm>(props.data || InitialPackageTypeForm)

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault()
        try {
            if (props.mode === "ADD") {
                await dispatch(createPackageType(values)).unwrap()
                await alertSuccess("Berhasil menyimpan data")
            }
            else if (props.mode === "EDIT") {
                await dispatch(editPackageType(values)).unwrap()
                await alertSuccess("Berhasil mengubah data")
            }
            await dispatch(getPackageType())
            props.onSubmit()
        } catch (error) { }
    }

    const handleChangePrice = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value) {
            handleChangeValues("price", 0)
        }
        else {
            handleChangeValues("price", null)
        }
    }

    return (
        <Box component={"form"} onSubmit={handleSubmit}>
            <FormText label="Kode" name="code" value={values.code} onChange={handleChange} />
            <FormText label="Nama" name="name" value={values.name} onChange={handleChange} />
            <FormCheck label="Harga Khusus" value={values.price !== null} onChange={handleChangePrice} />
            {values.price !== null && (
                <FormMoney label="Harga Khusus" name="price" value={values.price} onChange={handleChange} />
            )}
            <FormCheck label="Aktif" name="is_active" value={values.is_active} onChange={handleChange} />
            <Box marginTop={1} display={"flex"} justifyContent={"flex-end"}>
                <Button type="submit">Simpan</Button>
            </Box>
        </Box>
    )
}