import { createSlice } from '@reduxjs/toolkit'
import { checkLogin, login } from './auth.api'
import { InitialLoginResponse, InterfaceLoginResponse } from './auth.interface'
import alertError from '../../../shared/alert/alertError'

export interface AuthState {
    loading: boolean,
    loginData: InterfaceLoginResponse
}

const initialState: AuthState = {
    loading: false,
    loginData: InitialLoginResponse
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        resetLoginData: (state) => {
            state.loginData = InitialLoginResponse
        }
    },
    extraReducers(builder) {

        builder.addCase(login.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(login.fulfilled, (state, action) => {
            state.loading = false
            state.loginData = action.payload.data.data
        })
        builder.addCase(login.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(checkLogin.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(checkLogin.fulfilled, (state, action) => {
            state.loading = false
            state.loginData = action.payload.data.data
        })
        builder.addCase(checkLogin.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })
    },
})

export const { resetLoginData } = authSlice.actions

export default authSlice.reducer