export interface InterfaceWarehouseData {
    id: number,
    ts_created: string,
    name: string
}

export interface InterfaceWarehouseForm {
    id: number,
    name: string
}

export interface InterfaceWarehouseCreate {
    name: string
}

export interface InterfaceWarehouseEdit {
    id: number,
    name?: string
}

export const InitialWarehouseForm: InterfaceWarehouseForm = {
    id: 0,
    name: ""
}