import { Box, Card, CardContent, IconButton, Tooltip, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { TreeItem, TreeView } from "@mui/x-tree-view";
import { Add, ChevronRight, Delete, Edit, ExpandMore } from "@mui/icons-material";
import { InterfaceAccountData } from "../data/account.interface";
import { useEffect } from "react";
import { getAccount } from "../data/account.api";

export default function AccountTree(props: {
    onAdd?: (data: InterfaceAccountData) => void,
    onEdit?: (data: InterfaceAccountData) => void,
    onDelete?: (id: number) => void
}) {

    const dispatch = useDispatch<any>()

    const { accountList } = useSelector((state: RootState) => state.account)

    useEffect(() => {
        dispatch(getAccount())
    }, [dispatch])

    const renderItem = (parrent: null | number) => {
        return accountList.filter(account => account.parrent_id === parrent).map(account => (
            <TreeItem
                key={account.id}
                nodeId={String(account.id)}
                label={
                    <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                        <Typography variant="body1">
                            [{account.code}] {account.name}
                        </Typography>
                        <Box>
                            {props.onAdd && (
                                <Tooltip title="Tambah Subkategori">
                                    <IconButton onClick={() => props.onAdd && props.onAdd(account)}>
                                        <Add />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {props.onEdit && (
                                <Tooltip title="Edit">
                                    <IconButton onClick={() => props.onEdit && props.onEdit(account)}>
                                        <Edit />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {props.onDelete && (
                                <Tooltip title="Hapus">
                                    <IconButton onClick={() => props.onDelete && props.onDelete(account.id)}>
                                        <Delete />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Box>
                    </Box>
                }
            >
                {renderItem(account.id)}
            </TreeItem>
        ))
    }

    return (
        <Card>
            <CardContent>
                <TreeView
                    aria-label="file system navigator"
                    defaultCollapseIcon={<ExpandMore />}
                    defaultExpandIcon={<ChevronRight />}
                >
                    {renderItem(null)}
                </TreeView>
            </CardContent>
        </Card>
    )
}