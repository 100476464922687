import { createSlice } from '@reduxjs/toolkit'
import { createCity, deleteCity, editCity, getCity } from './city.api'
import { InterfaceCityData } from './city.interface'
import alertError from '../../../shared/alert/alertError'

export interface CityState {
    loading: boolean,
    cityList: InterfaceCityData[]
}

const initialState: CityState = {
    loading: false,
    cityList: []
}

export const citySlice = createSlice({
    name: 'city',
    initialState,
    reducers: {},
    extraReducers(builder) {

        builder.addCase(getCity.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getCity.fulfilled, (state, action) => {
            state.loading = false
            state.cityList = action.payload.data.data
        })
        builder.addCase(getCity.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(createCity.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createCity.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createCity.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(editCity.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editCity.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editCity.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(deleteCity.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteCity.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteCity.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })
    },
})

export default citySlice.reducer