import { Container, Typography } from "@mui/material";
import SettingTable from "../../features/setting/components/SettingTable";

export default function SettingPage() {

    return (
        <Container>
            <Typography variant="h3">Pengaturan</Typography>
            <br />
            <SettingTable />
        </Container>
    )
}