import { Autocomplete, Box, TextField } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSparepartCategory } from "../data/sparepartCategory.api";
import { RootState } from "../../../store";
import { InterfaceSparepartCategoryDataTree } from "../data/sparepartCategory.interface";

export default function SparepartCategorySelect(props: {
    value: number | null,
    onChange: (value: number | null) => void,
    required?: boolean,
    disabled?: boolean,
    disabledIdList?: number[]
}) {

    const dispatch = useDispatch<any>()

    const { loading, sparepartCategoryList } = useSelector((state: RootState) => state.sparepartCategory)

    useEffect(() => {
        dispatch(getSparepartCategory())
    }, [dispatch])

    const getSubSparepartCategory = (indent: number, parrent: number | null): any => {
        return sparepartCategoryList
            .filter(sparepartCategory => sparepartCategory.parrent_id === parrent)
            .map(sparepartCategory => {
                return [
                    {
                        ...sparepartCategory,
                        indent: indent
                    },
                    ...getSubSparepartCategory(indent + 1, sparepartCategory.id)
                ]
            })
    }

    const sparepartCategoryTree = getSubSparepartCategory(0, null)
    const sparepartCategoryListOrdered: InterfaceSparepartCategoryDataTree[] = sparepartCategoryTree.flat(Infinity)

    return (
        <Box>
            {loading
                ? (
                    <TextField fullWidth label="Kategori Induk" />
                )
                : (
                    <Autocomplete
                        fullWidth
                        disabled={props.disabled}
                        options={sparepartCategoryListOrdered}
                        getOptionLabel={options => options.name}
                        renderOption={(props, option, { selected }) => (
                            <li {...props} style={{ paddingLeft: `${(option.indent + 1)}rem` }}>
                                {option.name}
                            </li>
                        )}
                        getOptionDisabled={option => (props.disabledIdList || []).includes(option.id)}
                        value={sparepartCategoryListOrdered.filter(sparepartCategory => sparepartCategory.id === props.value)[0]}
                        onChange={(_, value) => props.onChange(value?.id || null)}
                        renderInput={(params) => <TextField {...params} label="Kategori Induk" required={props.required} />}
                    />
                )
            }
        </Box>
    )
}