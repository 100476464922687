import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import routeList from '../../routeList';
import { useNavigate } from "react-router-dom"
import { useState } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse, Typography, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

const drawerWidth = 240;

export default function Sidebar() {

    const navigate = useNavigate()
    const theme = useTheme()

    const { loginData } = useSelector((state: RootState) => state.auth)

    const [expanded, setExpanded] = useState("")

    const handleClickMenu = (menu: any, child: any = null) => {
        if (Boolean(child)) {
            navigate(menu.path + child.path)
        }
        else {
            if (!menu.child) {
                navigate(menu.path)
            }
            else if (expanded === menu.path) {
                setExpanded("")
            }
            else {
                setExpanded(menu.path)
            }
        }
    }

    const allowUrlHead = loginData.access.filter(access => (access.url.match(/\//g) || []).length > 1).map(access => access.url.substring(0, access.url.indexOf('/', 1)))
    const allowUrlChild = loginData.access.map(access => access.url)

    const openedUrl = window.location.hash.substring(1)

    return (
        <Drawer
            variant="permanent"
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
            }}
        >
            <Toolbar />
            <Box sx={{ overflow: 'auto' }} display={"flex"} flexGrow={1} flexDirection={"column"} justifyContent={"space-between"}>
                <List>
                    {routeList
                        .filter((menu => (allowUrlHead.includes(menu.path) || allowUrlChild.includes(menu.path))))
                        .map((menu, i) => (
                            <div key={i}>
                                <ListItemButton sx={(menu.child === undefined && openedUrl === menu.path) || (expanded !== menu.path && menu.child?.map(child => menu.path + child.path).includes(openedUrl)) ? { color: theme.palette.primary.main } : {}} onClick={() => handleClickMenu(menu)}>
                                    <ListItemIcon sx={(menu.child === undefined && openedUrl === menu.path) || (expanded !== menu.path && menu.child?.map(child => menu.path + child.path).includes(openedUrl)) ? { color: theme.palette.primary.main } : {}}>
                                        {menu.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={menu.name} />
                                    {menu.child && (expanded === menu.path ? <ExpandLess /> : <ExpandMore />)}
                                </ListItemButton>
                                {menu.child && (
                                    <Collapse in={expanded === menu.path}>
                                        <List disablePadding>
                                            {menu.child
                                                .filter((child => allowUrlChild.includes(menu.path + child.path)))
                                                .map((child: any, i: number) => (
                                                    <ListItemButton key={i} sx={openedUrl === menu.path + child.path ? { color: theme.palette.primary.main } : {}} onClick={() => handleClickMenu(menu, child)}>
                                                        <ListItemIcon></ListItemIcon>
                                                        <ListItemText primary={child.name} />
                                                    </ListItemButton>
                                                ))}
                                        </List>
                                    </Collapse>
                                )}
                            </div>
                        ))}
                </List>
                <Typography align='center' marginBottom={1}>JTM Group 2024</Typography>
            </Box>
        </Drawer>
    )
}