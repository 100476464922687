import { createSlice } from '@reduxjs/toolkit'
import { createAccount, deleteAccount, editAccount, getAccount } from './account.api'
import { InterfaceAccountData } from './account.interface'
import alertError from '../../../shared/alert/alertError'

export interface AccountState {
    loading: boolean,
    accountList: InterfaceAccountData[]
}

const initialState: AccountState = {
    loading: false,
    accountList: []
}

export const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {},
    extraReducers(builder) {

        builder.addCase(getAccount.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getAccount.fulfilled, (state, action) => {
            state.loading = false
            state.accountList = action.payload.data.data
        })
        builder.addCase(getAccount.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(createAccount.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createAccount.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createAccount.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(editAccount.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editAccount.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editAccount.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(deleteAccount.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteAccount.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteAccount.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })
    },
})

export default accountSlice.reducer