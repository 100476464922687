import { Box, Button, Container, Typography } from "@mui/material";
import SupplierTable from "../../features/supplier/components/SupplierTable";
import { Add } from "@mui/icons-material";
import CustomModal from "../../shared/CustomModal";
import { useState } from "react";
import { InterfaceSupplierData } from "../../features/supplier/data/supplier.interface";
import { useDispatch } from "react-redux";
import alertDelete from "../../shared/alert/alertDelete";
import { deleteSupplier, getSupplier } from "../../features/supplier/data/supplier.api";
import alertSuccess from "../../shared/alert/alertSuccess";
import useAccess from "../../hooks/useAccess";
import SupplierForm from "../../features/supplier/components/SupplierForm";

export default function SupplierPage() {

    const dispatch = useDispatch<any>()
    const access = useAccess()

    const [modalAdd, setModalAdd] = useState<boolean>(false)
    const [dataSelected, setDataSelected] = useState<InterfaceSupplierData | undefined>(undefined)

    const handleDelete = (id: number) => {
        alertDelete()
            .then(async isConfirmed => {
                if (isConfirmed) {
                    try {
                        await dispatch(deleteSupplier(id)).unwrap()
                        await alertSuccess("Berhasil menghapus data")
                        await dispatch(getSupplier())
                    } catch (error) { }
                }
            })
    }

    return (
        <Container>
            <CustomModal
                open={modalAdd}
                title="Tambah Supplier"
                onClose={() => setModalAdd(false)}
                component={<SupplierForm mode="ADD" onSubmit={() => setModalAdd(false)} />}
            />
            <CustomModal
                open={Boolean(dataSelected)}
                title="Edit Supplier"
                onClose={() => setDataSelected(undefined)}
                component={<SupplierForm mode="EDIT" data={dataSelected} onSubmit={() => setDataSelected(undefined)} />}
            />
            <Typography variant="h3">Supplier</Typography>
            {access("CREATE") && (
                <Box marginTop={1} display={"flex"} justifyContent={"flex-end"}>
                    <Button startIcon={<Add />} onClick={() => setModalAdd(true)}>Tambah</Button>
                </Box>
            )}
            <br />
            <SupplierTable onEdit={access("EDIT") ? setDataSelected : undefined} onDelete={access("DELETE") ? handleDelete : undefined} />
        </Container>
    )
}