import { Box, Button, Container, Typography } from "@mui/material";
import SparepartUsedTable from "../../features/sparepartUsed/components/SparepartUsedTable";
import { Add } from "@mui/icons-material";
import CustomModal from "../../shared/CustomModal";
import { useState } from "react";
import { InterfaceSparepartUsedData } from "../../features/sparepartUsed/data/sparepartUsed.interface";
import { useDispatch } from "react-redux";
import alertDelete from "../../shared/alert/alertDelete";
import { deleteSparepartUsed, getSparepartUsed } from "../../features/sparepartUsed/data/sparepartUsed.api";
import alertSuccess from "../../shared/alert/alertSuccess";
import useAccess from "../../hooks/useAccess";
import SparepartUsedForm from "../../features/sparepartUsed/components/SparepartUsedForm";

export default function SparepartUsedPage() {

    const dispatch = useDispatch<any>()
    const access = useAccess()

    const [modalAdd, setModalAdd] = useState<boolean>(false)
    const [dataSelected, setDataSelected] = useState<InterfaceSparepartUsedData | undefined>(undefined)

    const handleDelete = (id: number) => {
        alertDelete()
            .then(async isConfirmed => {
                if (isConfirmed) {
                    try {
                        await dispatch(deleteSparepartUsed(id)).unwrap()
                        await alertSuccess("Berhasil menghapus data")
                        await dispatch(getSparepartUsed())
                    } catch (error) { }
                }
            })
    }

    return (
        <Container>
            <CustomModal
                open={modalAdd}
                title="Tambah Pemakaian Sparepart"
                onClose={() => setModalAdd(false)}
                component={<SparepartUsedForm mode="ADD" onSubmit={() => setModalAdd(false)} />}
            />
            <CustomModal
                open={Boolean(dataSelected)}
                title="Edit Pemakaian Sparepart"
                onClose={() => setDataSelected(undefined)}
                component={<SparepartUsedForm mode="EDIT" data={dataSelected} onSubmit={() => setDataSelected(undefined)} />}
            />
            <Typography variant="h3">Pemakaian Sparepart</Typography>
            {access("CREATE") && (
                <Box marginTop={1} display={"flex"} justifyContent={"flex-end"}>
                    <Button startIcon={<Add />} onClick={() => setModalAdd(true)}>Tambah</Button>
                </Box>
            )}
            <br />
            <SparepartUsedTable onEdit={access("EDIT") ? setDataSelected : undefined} onDelete={access("DELETE") ? handleDelete : undefined} />
        </Container>
    )
}