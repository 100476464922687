import { Box, Typography, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useEffect } from "react";
import { getSeat } from "../data/seat.api";
import { amber, green, red } from "@mui/material/colors";
import moneyParser from "../../../shared/moneyParser";
import { InterfaceSeatData } from "../data/seat.interface";

export default function SeatSelector(props: { scheduleId: number, startDate: string, choosedIdList: number[], bookedList: number[], onClick: (data: InterfaceSeatData) => void }) {

    const dispatch = useDispatch<any>()
    const theme = useTheme()

    const { seatList } = useSelector((state: RootState) => state.seat)

    useEffect(() => {
        dispatch(getSeat({ schedule_id: props.scheduleId, start_date: props.startDate }))
    }, [dispatch, props.scheduleId, props.startDate])

    const maxX = seatList.map(seat => seat.x).reduce((prev, current) => Math.max(prev, current), 0)
    const maxY = seatList.map(seat => seat.y).reduce((prev, current) => Math.max(prev, current), 0)

    return (
        <Box display={"flex"} gap={1}>
            {Array.from({ length: maxY }, (_, y) => (
                <Box width={"100%"} key={y} display={"flex"} gap={1} flexDirection={"column"}>
                    {Array.from({ length: maxX }, (_, x) => {
                        const seat = seatList.find(seat => seat.x === x + 1 && seat.y === y + 1)
                        if (seat) {
                            const isChoosed = props.choosedIdList.includes(seat.id)
                            const isBooked = props.bookedList.includes(seat.id) || (seat.customer_list && seat.customer_list.length > 0)
                            return (
                                <Box
                                    component={"button"}
                                    border={0}
                                    key={x}
                                    height={80}
                                    padding={1}
                                    bgcolor={(isChoosed && green[100]) || (isBooked && red[100]) || amber[100]}
                                    sx={
                                        isBooked
                                            ? {}
                                            : {
                                                cursor: "pointer",
                                                ":hover": {
                                                    bgcolor: amber[200]
                                                }
                                            }
                                    }
                                    onClick={() => (isChoosed || !isBooked) && props.onClick(seat)}
                                >
                                    <Typography variant="h6">{seat?.name}</Typography>
                                    <Typography variant="body1">{moneyParser(seat?.price)}</Typography>
                                </Box>
                            )
                        }
                        return (
                            <Box
                                component={"button"}
                                border={0}
                                key={x}
                                height={80}
                                bgcolor={theme.palette.background.paper}
                            >
                            </Box>
                        )
                    })}
                </Box>
            ))}
        </Box>
    )
}