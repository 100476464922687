export type TypeFlow = "CASH_TRANSFER" | "CASH_IN" | "CASH_OUT"

export interface InterfaceFlowData {
    id: number,
    ts_created: string,
    type: TypeFlow,
    account_source_id: number,
    account_id: number,
    value: number,
    notes: string,
    account_source: {
        name: string
    },
    account: {
        name: string
    }
}

export interface InterfaceFlowForm {
    id: number,
    type: TypeFlow,
    account_source_id: number,
    account_id: number,
    value: number,
    notes: string
}

export interface InterfaceFlowCreate {
    type: TypeFlow,
    account_source_id: number,
    account_id: number,
    value: number,
    notes: string
}

export interface InterfaceFlowEdit {
    id: number,
    type: TypeFlow,
    account_source_id: number,
    account_id: number,
    value: number,
    notes: string
}

export const InitialFlowForm: InterfaceFlowForm = {
    id: 0,
    type: "CASH_TRANSFER",
    account_source_id: 0,
    account_id: 0,
    value: 0,
    notes: ""
}