import { createSlice } from '@reduxjs/toolkit'
import { createSparepartUsed, deleteSparepartUsed, editSparepartUsed, getSparepartUsed } from './sparepartUsed.api'
import { InterfaceSparepartUsedData } from './sparepartUsed.interface'
import alertError from '../../../shared/alert/alertError'

export interface SparepartUsedState {
    loading: boolean,
    sparepartUsedList: InterfaceSparepartUsedData[]
}

const initialState: SparepartUsedState = {
    loading: false,
    sparepartUsedList: []
}

export const sparepartUsedSlice = createSlice({
    name: 'sparepartUsed',
    initialState,
    reducers: {},
    extraReducers(builder) {

        builder.addCase(getSparepartUsed.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getSparepartUsed.fulfilled, (state, action) => {
            state.loading = false
            state.sparepartUsedList = action.payload.data.data
        })
        builder.addCase(getSparepartUsed.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(createSparepartUsed.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createSparepartUsed.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createSparepartUsed.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(editSparepartUsed.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editSparepartUsed.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editSparepartUsed.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(deleteSparepartUsed.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteSparepartUsed.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteSparepartUsed.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })
    },
})

export default sparepartUsedSlice.reducer