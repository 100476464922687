import { Box } from "@mui/material";
import FormDate from "../../../../shared/form/FormDate";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { setItineraryInput } from "../../data/itinerary.reducer";
import { InterfaceItineraryInput } from "../../data/itinerary.interface";

export default function ItineraryCreateA() {

    const dispatch = useDispatch<any>()

    const { itineraryInput } = useSelector((state: RootState) => state.itinerary)

    const handleChangeValues = (name: keyof InterfaceItineraryInput, value: any) => {
        dispatch(setItineraryInput({
            ...itineraryInput,
            [name]: value
        }))
    }

    return (
        <Box>
            <FormDate label="Tanggal Input" name="ts_created" value={itineraryInput.ts_created?.split(" ")[0]} onChange={e => handleChangeValues("ts_created", e.target.value)} />
            <FormDate label="Tanggal Keberangkatan" name="start_date" value={itineraryInput.start_date} onChange={e => handleChangeValues("start_date", e.target.value)} />
        </Box>
    )
}