export type TypeEmployeeType = "ALL" | "STAFF" | "SOPIR" | "SOPIR_CADANGAN" | "KERNET"

export interface InterfaceEmployeeData {
    id: number,
    ts_created: string,
    name: string,
    address: string,
    phone: string,
    type: TypeEmployeeType
}

export interface InterfaceEmployeeForm {
    id: number,
    name: string,
    address: string,
    phone: string,
    type: TypeEmployeeType
}

export interface InterfaceEmployeeCreate {
    name: string,
    address: string,
    phone: string,
    type: TypeEmployeeType
}

export interface InterfaceEmployeeEdit {
    id: number,
    name?: string,
    address?: string,
    phone?: string,
    type?: TypeEmployeeType
}

export const InitialEmployeeForm: InterfaceEmployeeForm = {
    id: 0,
    name: "",
    address: "",
    phone: "",
    type: "ALL"
}