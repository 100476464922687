import { amber, green, red } from "@mui/material/colors"
import { TypeGender } from "../../customer/data/customer.interface"

export interface InterfaceSeatData {
    id: number,
    ts_created: string,
    name: string,
    price: number,
    x: number,
    y: number
    schedule_id: number,
    customer_list?: {
        id: number,
        booking: {
            id: number,
            start_terminal?: {
                name: string
            },
            finish_terminal?: {
                name: string
            }
        },
        customer: {
            name: string,
            phone: string,
            gender: TypeGender
        },
        total: number,
        total_paid: number,
        is_ready: boolean
    }[],

}

export interface InterfaceSeatForm {
    id: number,
    name: string,
    price: number,
    x: number,
    y: number
    schedule_id: number
}

export interface InterfaceSeatCreate {
    name: string,
    price: number,
    x: number,
    y: number
    schedule_id: number
}

export interface InterfaceSeatEdit {
    id: number,
    name?: string,
    price?: number,
    x?: number,
    y?: number
    schedule_id?: number
}

export const InitialSeatForm: InterfaceSeatForm = {
    id: 0,
    name: "",
    price: 0,
    x: 0,
    y: 0,
    schedule_id: 0
}

export const StatusPaid = [
    {
        name: "Belum Bayar",
        color: red[800]
    },
    {
        name: "Belum Lunas | DP",
        color: amber[800]
    },
    {
        name: "Lunas",
        color: green[800]
    }
]