import { Box, Button, Checkbox } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useEffect } from "react";
import CustomTable from "../../../shared/CustomTable";
import { InterfaceScheduleData } from "../data/schedule.interface";
import { Chair, Delete, Edit } from "@mui/icons-material";
import { getSchedule } from "../data/schedule.api";
import moneyParser from "../../../shared/moneyParser";

export default function ScheduleTable(props: {
    onEditSeat?: (id: number) => void,
    onEdit?: (data: InterfaceScheduleData) => void,
    onDelete?: (id: number) => void
}) {

    const dispatch = useDispatch<any>()

    const { scheduleList } = useSelector((state: RootState) => state.schedule)

    useEffect(() => {
        dispatch(getSchedule({}))
    }, [dispatch])

    return (
        <Box>
            <CustomTable
                columns={[
                    {
                        name: "Keberangkatan",
                        key: "start_city",
                        render: (data) => data.start_city.name
                    },
                    {
                        name: "Tujuan",
                        key: "finish_city",
                        render: (data) => data.finish_city.name
                    },
                    {
                        name: "Hari",
                        key: "day_list",
                        render: (data) => data.day_list.join(", ")
                    },
                    {
                        name: "Jam Barangkat",
                        key: "start_time"
                    },
                    {
                        name: "Jam Tiba",
                        key: "finish_time"
                    },
                    {
                        name: "Harga Pengiriman Paket",
                        key: "price_delivery",
                        render: (data) => moneyParser(data.price_delivery)
                    },
                    {
                        name: "Biaya Solar",
                        key: "cost_gas",
                        render: (data) => moneyParser(data.cost_gas)
                    },
                    {
                        name: "Biaya Maintenance",
                        key: "cost_maintenance",
                        render: (data) => moneyParser(data.cost_maintenance)
                    },
                    {
                        name: "Tampilkan di Kalender",
                        key: "is_show_on_calendar",
                        render: (data) => <Checkbox checked={data.is_show_on_calendar} />
                    },
                    {
                        name: "Keterangan",
                        key: "note"
                    }
                ]}
                dataList={scheduleList}
                actionList={(props.onEdit || props.onDelete)
                    ? (data) => (
                        <Box display={"flex"} justifyContent={"center"} gap={1}>
                            {props.onEditSeat && (<Button startIcon={<Chair />} onClick={() => props.onEditSeat && props.onEditSeat(data.id)}>Kursi</Button>)}
                            {props.onEdit && (<Button startIcon={<Edit />} color="warning" onClick={() => props.onEdit && props.onEdit(data)}>Edit</Button>)}
                            {props.onDelete && (<Button startIcon={<Delete />} color="error" onClick={() => props.onDelete && props.onDelete(data.id)}>Hapus</Button>)}
                        </Box>
                    )
                    : undefined
                }
            />
        </Box>
    )
}