import { Container, Typography } from "@mui/material";
import CustomModal from "../../shared/CustomModal";
import { useState } from "react";
import { InterfaceBuyData } from "../../features/buy/data/buy.interface";
import useAccess from "../../hooks/useAccess";
import BuyForm from "../../features/buy/components/BuyForm";
import BuyTableDebt from "../../features/buy/components/BuyTableDebt";

export default function BuyDebtPage() {

    const access = useAccess()

    const [dataSelected, setDataSelected] = useState<InterfaceBuyData | undefined>(undefined)

    return (
        <Container>
            <CustomModal
                open={Boolean(dataSelected)}
                size="lg"
                title="Edit Pembelian"
                onClose={() => setDataSelected(undefined)}
                component={<BuyForm mode="EDIT" data={dataSelected} onSubmit={() => setDataSelected(undefined)} />}
            />
            <Typography variant="h3">Hutang Pembelian</Typography>
            <br />
            <BuyTableDebt onEdit={access("EDIT") ? setDataSelected : undefined} />
        </Container>
    )
}