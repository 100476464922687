import { Box, Button, Container, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import CustomModal from "../../shared/CustomModal";
import { useState } from "react";
import { InterfaceSparepartCategoryData } from "../../features/sparepartCategory/data/sparepartCategory.interface";
import { useDispatch } from "react-redux";
import alertDelete from "../../shared/alert/alertDelete";
import { deleteSparepartCategory, getSparepartCategory } from "../../features/sparepartCategory/data/sparepartCategory.api";
import alertSuccess from "../../shared/alert/alertSuccess";
import useAccess from "../../hooks/useAccess";
import SparepartCategoryForm from "../../features/sparepartCategory/components/SparepartCategoryForm";
import SparepartCategoryTree from "../../features/sparepartCategory/components/SparepartCategoryTree";

export default function SparepartCategoryPage() {

    const dispatch = useDispatch<any>()
    const access = useAccess()

    const [modalAdd, setModalAdd] = useState<boolean>(false)
    const [modalEdit, setModalEdit] = useState<boolean>(false)
    const [dataSelected, setDataSelected] = useState<InterfaceSparepartCategoryData | undefined>(undefined)

    const handleAdd = () => {
        setModalAdd(true)
    }

    const handleEdit = (data: InterfaceSparepartCategoryData) => {
        setDataSelected(data)
        setModalEdit(true)
    }

    const handleDelete = (id: number) => {
        alertDelete()
            .then(async isConfirmed => {
                if (isConfirmed) {
                    try {
                        await dispatch(deleteSparepartCategory(id))
                        await alertSuccess("Berhasil menghapus data")
                        await dispatch(getSparepartCategory())
                    } catch (error) { }
                }
            })
    }

    const handleAddSub = (data: InterfaceSparepartCategoryData) => {
        setDataSelected(data)
        setModalAdd(true)
    }

    const handleSubmitAdd = () => {
        setModalAdd(false)
    }

    const handleSubmitEdit = () => {
        setModalEdit(false)
    }

    const handleCloseModal = () => {
        setModalAdd(false)
        setModalEdit(false)
        setDataSelected(undefined)
    }

    return (
        <Container>
            <CustomModal
                open={modalAdd}
                title="Tambah Kategori"
                onClose={handleCloseModal}
                component={<SparepartCategoryForm mode="ADD" parrentId={dataSelected?.id || undefined} onSubmit={handleSubmitAdd} />}
            />
            <CustomModal
                open={modalEdit}
                title="Edit Kategori"
                onClose={handleCloseModal}
                component={<SparepartCategoryForm mode="EDIT" data={dataSelected} onSubmit={handleSubmitEdit} />}
            />
            <Typography variant="h3">Kategori Sparepart</Typography>
            {access("CREATE") && (
                <Box marginTop={1} display={"flex"} justifyContent={"flex-end"}>
                    <Button startIcon={<Add />} onClick={handleAdd}>Tambah</Button>
                </Box>
            )}
            <br />
            <SparepartCategoryTree onAdd={access("CREATE") ? handleAddSub : undefined} onEdit={access("EDIT") ? handleEdit : undefined} onDelete={access("DELETE") ? handleDelete : undefined} />
        </Container>
    )
}