export interface InterfaceLogin {
    username: string,
    password: string
}

export interface InterfaceLoginResponse {
    id: number,
    ts_created: string,
    key: string,
    name: string,
    username: string,
    password: string,
    access: Array<{
        url: string,
        access: string[]
    }>
}

export const InitialLoginResponse: InterfaceLoginResponse = {
    id: 0,
    ts_created: "",
    key: "",
    name: "",
    username: "",
    password: "",
    access: []
}