import { Box, Button, Card, CardContent, Container, Divider, Typography } from "@mui/material";
import FormDate from "../../shared/form/FormDate";
import { useRef, useState } from "react";
import moment from "moment";
import ReactToPrint from "react-to-print";
import Journal from "../../features/report/components/Journal";

export default function JournalPage() {

    const toPrint = useRef(null)

    const [dateStart, setDateStart] = useState(moment().subtract(1, "month").format("YYYY-MM-DD"))
    const [dateEnd, setDateEnd] = useState(moment().format("YYYY-MM-DD"))

    return (
        <Container>
            <Card>
                <CardContent>
                    <FormDate value={dateStart} onChange={(e) => setDateStart(e.target.value)} label="Tanggal Awal" />
                    <FormDate value={dateEnd} onChange={(e) => setDateEnd(e.target.value)} label="Tanggal Akhir" />
                    <ReactToPrint
                        trigger={() => {
                            return <Button sx={{ marginTop: 2 }} variant="contained" fullWidth>Print</Button>;
                        }}
                        content={() => toPrint.current}
                    />
                </CardContent>
            </Card>
            <Box ref={toPrint} padding={2}>
                <Typography variant="h3">Laporan Jurnal Umum</Typography>
                <Typography variant="h6" gutterBottom>Periode: {moment(dateStart).format("YYYY-MM-DD")} s.d. {moment(dateEnd).format("YYYY-MM-DD")}</Typography>
                <Divider sx={{ marginY: 3 }} />
                <Journal date_start={dateStart} date_end={dateEnd} />
            </Box>
        </Container>
    )
}