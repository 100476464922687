import { Box, Button, Card, CardContent, CardHeader, Divider, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { FormEvent, Fragment, useEffect } from "react";
import { InitialSalaryForm, InterfaceSalaryForm } from "../data/salary.interface";
import { useDispatch, useSelector } from "react-redux";
import alertSuccess from "../../../shared/alert/alertSuccess";
import { useFormValues } from "../../../hooks/useFormValues";
import { createSalary, editSalary, getCash, getCommision, getSalary } from "../data/salary.api";
import EmployeeSelect from "../../employee/components/EmployeeSelect";
import FormNumber from "../../../shared/form/FormNumber";
import FormMoney from "../../../shared/form/FormMoney";
import AccountSelect from "../../account/components/AccountSelect";
import { RootState } from "../../../store";
import moneyParser from "../../../shared/moneyParser";
import moment from "moment";
import { useSetting } from "../../../hooks/useSetting";

export default function SalaryForm(props: { mode: "ADD" | "EDIT", data?: InterfaceSalaryForm, onSubmit: () => void }) {

    const dispatch = useDispatch<any>()

    const setting = useSetting()
    const { values, handleChange, handleChangeValues } = useFormValues<InterfaceSalaryForm>(props.data || InitialSalaryForm)

    const { commisionList, cashList } = useSelector((state: RootState) => state.salary)

    useEffect(() => {
        if (props.mode === "ADD" && values.employee_id) {
            dispatch(getCommision({ employee_id: values.employee_id }))
            dispatch(getCash({ employee_id: values.employee_id }))
        }
        else if (props.mode === "EDIT" && props.data?.id) {
            dispatch(getCommision({ salary_id: props.data?.id }))
            dispatch(getCash({ salary_id: props.data?.id }))
        }
    }, [dispatch, props.mode, values.employee_id, props.data?.id])

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault()
        console.log(values)
        try {
            if (props.mode === "ADD") {
                await dispatch(createSalary({
                    ...values,
                    account_id: setting.getValue("ACCOUNT_SALARY"),
                    commision_ids: commisionList.map(c => c.id),
                    cash_ids: cashList.map(c => c.id),
                })).unwrap()
                await alertSuccess("Berhasil menyimpan data")
            }
            else if (props.mode === "EDIT") {
                await dispatch(editSalary(values)).unwrap()
                await alertSuccess("Berhasil mengubah data")
            }
            await dispatch(getSalary())
            props.onSubmit()
        } catch (error) { }
    }

    const totalPerDay = values.day_salary + values.day_consumption
    const totalSalary = (totalPerDay * values.day_count) + values.craft + values.bonus - values.cut
    const totalCommission = commisionList.reduce((acc, curr) => acc + curr.value, 0)
    const totalCash = cashList.reduce((acc, curr) => acc + curr.value, 0)
    const totalAll = totalSalary + totalCommission - totalCash

    const isEditMode = props.mode === "EDIT"

    return (
        <Box component={"form"} onSubmit={handleSubmit}>
            <AccountSelect disabledHead headId={setting.getValue("ACCOUNT_MAIN_FLOW")} label="Akun Pengeluaran" value={values.account_source_id} onChange={(value) => handleChangeValues("account_source_id", value)} readonly={isEditMode} />
            <EmployeeSelect value={values.employee_id} onChange={(value) => handleChangeValues("employee_id", value)} readOnly={isEditMode}/>
            <Divider sx={{ marginY: 1 }} />
            <FormMoney label="Gaji Harian" name="day_salary" value={values.day_salary} onChange={handleChange} />
            <FormMoney label="Uang Makan Harian" name="day_consumption" value={values.day_consumption} onChange={handleChange} />
            <FormMoney label="Total Harian" value={totalPerDay} readOnly />
            <FormNumber label="Jumlah Hari" name="day_count" value={values.day_count} onChange={handleChange} />
            <Divider sx={{ marginY: 1 }} />
            <FormMoney label="Kerajinan" name="craft" value={values.craft} onChange={handleChange} />
            <FormMoney label="Bonus" name="bonus" value={values.bonus} onChange={handleChange} />
            <FormMoney label="Potongan" name="cut" value={values.cut} onChange={handleChange} />
            <Divider sx={{ marginY: 1 }} />
            <FormMoney label="Total Gaji" value={totalSalary} readOnly />
            <Divider sx={{ marginY: 1 }} />
            {values.employee_id && (
                <Fragment>
                    <Card sx={{ marginY: 1 }}>
                        <CardHeader title="Komisi" />
                        <CardContent>
                            <Table>
                                <TableBody>
                                    {commisionList.map(item => (
                                        <TableRow key={item.id}>
                                            <TableCell>{moment(item.ts_created).format("YYYY-MM-DD")}</TableCell>
                                            <TableCell align="right">{moneyParser(item.value)}</TableCell>
                                        </TableRow>
                                    ))}
                                    <TableRow>
                                        <TableCell sx={{ fontWeight: "bold" }}>Total</TableCell>
                                        <TableCell sx={{ fontWeight: "bold" }} align="right">{moneyParser(totalCommission)}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </CardContent>
                    </Card>
                    <Card sx={{ marginY: 1 }}>
                        <CardHeader title="Kasbon" />
                        <CardContent>
                            <Table>
                                <TableBody>
                                    {cashList.map(item => (
                                        <TableRow key={item.id}>
                                            <TableCell>{moment(item.ts_created).format("YYYY-MM-DD")}</TableCell>
                                            <TableCell align="right">{moneyParser(item.value)}</TableCell>
                                        </TableRow>
                                    ))}
                                    <TableRow>
                                        <TableCell sx={{ fontWeight: "bold" }}>Total</TableCell>
                                        <TableCell sx={{ fontWeight: "bold" }} align="right">{moneyParser(totalCash)}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </CardContent>
                    </Card>
                </Fragment>
            )}
            <FormMoney label="Total Gaji + Komisi - Kasbon" value={totalAll} readOnly />
            <Box marginTop={1} display={"flex"} justifyContent={"flex-end"}>
                <Button type="submit">Simpan</Button>
            </Box>
        </Box>
    )
}