import { Box, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useEffect, useState } from "react";
import CustomTable from "../../../shared/CustomTable";
import { Delete, Print } from "@mui/icons-material";
import moment from "moment";
import { getItinerary } from "../data/itinerary.api";

export default function ItineraryTableAll(props: {
    onPrint?: (id: number) => void,
    onDelete?: (id: number) => void
}) {

    const dispatch = useDispatch<any>()

    const { itineraryList, itineraryListTotal } = useSelector((state: RootState) => state.itinerary)

    const [skip, setSkip] = useState(0)
    const [take, setTake] = useState(5)

    useEffect(() => {
        dispatch(getItinerary({ skip, take }))
    }, [dispatch, skip, take])

    return (
        <Box>
            <CustomTable
                columns={[
                    {
                        key: "id",
                        name: "Nomor Itinerary"
                    },
                    {
                        key: "ts_created",
                        name: "Tanggal Input",
                        render: (data) => moment(data.ts_created).format("YYYY-MM-DD")
                    },
                    {
                        key: "start_date",
                        name: "Tangal Keberangkatan",
                        render: (data) => moment(data.start_date).format("YYYY-MM-DD")
                    },
                    {
                        key: "car",
                        name: "Armada",
                        render: (data) => data.car.name
                    },
                    {
                        key: "is_close",
                        name: "Status",
                        render: (data) => data.is_close ? "Close" : "Open"
                    }
                ]}
                dataList={itineraryList}
                actionList={(props.onPrint || props.onDelete)
                    ? (data) => (
                        <Box display={"flex"} justifyContent={"center"} gap={1}>
                            {props.onPrint && (<Button startIcon={<Print />} color="info" onClick={() => props.onPrint && props.onPrint(data.id)}>Print</Button>)}
                            {props.onDelete && (<Button startIcon={<Delete />} color="error" onClick={() => props.onDelete && props.onDelete(data.id)}>Hapus</Button>)}
                        </Box>
                    )
                    : undefined
                }
                pagination={{
                    setSkip,
                    setTake,
                    total: itineraryListTotal
                }}
            />
        </Box>
    )
}