import { Box, Button } from "@mui/material";
import { FormEvent } from "react";
import { InitialCarForm, InterfaceCarForm } from "../data/car.interface";
import FormText from "../../../shared/form/FormText";
import { useDispatch } from "react-redux";
import alertSuccess from "../../../shared/alert/alertSuccess";
import { useFormValues } from "../../../hooks/useFormValues";
import { createCar, editCar, getCar } from "../data/car.api";
import CarTypeSelect from "../../carType/components/CarTypeSelect";

export default function CarForm(props: { mode: "ADD" | "EDIT", data?: InterfaceCarForm, onSubmit: () => void }) {

    const dispatch = useDispatch<any>()

    const { values, handleChange, handleChangeValues } = useFormValues<InterfaceCarForm>(props.data || InitialCarForm)

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault()
        try {
            if (props.mode === "ADD") {
                await dispatch(createCar(values)).unwrap()
                await alertSuccess("Berhasil menyimpan data")
            }
            else if (props.mode === "EDIT") {
                await dispatch(editCar(values)).unwrap()
                await alertSuccess("Berhasil mengubah data")
            }
            await dispatch(getCar())
            props.onSubmit()
        } catch (error) { }
    }

    return (
        <Box component={"form"} onSubmit={handleSubmit}>
            <FormText label="Nama" name="name" value={values.name} onChange={handleChange} />
            <FormText label="Plat Nomor" name="number" value={values.number} onChange={handleChange} />
            <CarTypeSelect value={values.type_id} onChange={value => handleChangeValues("type_id", value)} />
            <Box marginTop={1} display={"flex"} justifyContent={"flex-end"}>
                <Button type="submit">Simpan</Button>
            </Box>
        </Box>
    )
}