import React, { useEffect, useState } from "react";
import { Box, Button, Card, CardContent, CardMedia, Input, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store"; // Adjust the import based on your store configuration
import { attachmentGet, attachmentUpload } from "../data/itinerary.api";
import alertSuccess from "../../../shared/alert/alertSuccess";
import alertError from "../../../shared/alert/alertError";
import { baseUrl } from "../../../state";

export default function ItineraryCloseFormAttachment(props: { itineraryId: number }) {

    const dispatch = useDispatch<AppDispatch>();

    const { attachmentList } = useSelector((state: RootState) => state.itinerary)

    const [file, setFile] = useState<File | null>(null);
    const [filePreview, setFilePreview] = useState<string>("");
    const [name, setName] = useState<string>("");

    useEffect(() => {
        dispatch(attachmentGet(props.itineraryId))
    }, [dispatch, props.itineraryId])

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            setFile(event.target.files[0]);
            const objectUrl = URL.createObjectURL(event.target.files[0]);
            setFilePreview(objectUrl);
        }
    };

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        if (file && name) {
            const data = {
                itinerary_id: props.itineraryId,
                name: name,
                file: file
            };
            try {
                await dispatch(attachmentUpload(data)).unwrap();
                alertSuccess("Berhasil mengunggah file");
                dispatch(attachmentGet(props.itineraryId))
            } catch (error) {
                alertError(error)
            }
        } else {
            alertError("Harap isi nama dan pilih file gambar");
        }
    };

    return (
        <Box display={"flex"} gap={1} overflow={"auto"}>
            <Box width={200}>
                <Card sx={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                    <CardMedia
                        component="img"
                        alt={name}
                        image={filePreview}
                        style={{ width: "200px", maxHeight: "200px", objectFit: "cover" }}
                    />
                    <CardContent component="form" onSubmit={handleSubmit}>
                        <Input
                            type="file"
                            inputProps={{ accept: "image/*" }}
                            onChange={handleFileChange}
                            style={{ display: 'none' }}
                            id="image-upload-input"
                        />
                        <label htmlFor="image-upload-input">
                            <Button fullWidth component="span" variant="contained" color="primary" sx={{ textTransform: "unset" }}>
                                Pilih Gambar
                            </Button>
                        </label>
                        <TextField
                            label="Name"
                            value={name}
                            onChange={handleNameChange}
                            fullWidth
                            margin="normal"
                        />
                        <Button fullWidth type="submit" variant="contained" color="primary">
                            Upload
                        </Button>
                    </CardContent>
                </Card>
            </Box>
            {attachmentList.map((attachment, index) => (
                <Box width={200}>
                    <Card key={index} sx={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                        <CardMedia
                            component="img"
                            alt={attachment.file_name}
                            image={`${baseUrl}/${attachment.file_path}`}
                            style={{ width: "200px", maxHeight: "200px", objectFit: "cover" }}
                        />
                        <CardContent>
                            <Typography>{attachment.name}</Typography>
                            <a href={`${baseUrl}/${attachment.file_path}`} target="__blank" download={attachment.name}>Download</a>
                        </CardContent>
                    </Card>
                </Box>
            ))}
        </Box>
    );
}