import { Box, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useEffect } from "react";
import CustomTable from "../../../shared/CustomTable";
import { InterfaceFlowData, TypeFlow } from "../data/flow.interface";
import { Delete, Edit } from "@mui/icons-material";
import moneyParser from "../../../shared/moneyParser";
import { getFlowIn, getFlowOut, getFlowTransfer } from "../data/flow.api";

export default function FlowTable(props: {
    type: TypeFlow,
    onEdit?: (data: InterfaceFlowData) => void,
    onDelete?: (id: number) => void
}) {

    const dispatch = useDispatch<any>()

    const { flowTransferList, flowInList, flowOutList } = useSelector((state: RootState) => state.flow)

    useEffect(() => {
        switch (props.type) {
            case "CASH_TRANSFER":
                dispatch(getFlowTransfer())
                break
            case "CASH_IN":
                dispatch(getFlowIn())
                break
            case "CASH_OUT":
                dispatch(getFlowOut())
                break
        }
    }, [dispatch, props.type])

    const dataList: InterfaceFlowData[] = []

    switch (props.type) {
        case "CASH_TRANSFER":
            dataList.push(...flowTransferList)
            break
        case "CASH_IN":
            dataList.push(...flowInList)
            break
        case "CASH_OUT":
            dataList.push(...flowOutList)
            break
    }

    return (
        <Box>
            <CustomTable
                columns={[
                    {
                        name: "Akun Sumber",
                        key: "account_source_id",
                        render: (data) => data.account_source.name
                    },
                    {
                        name: "Akun Tujuan",
                        key: "account_id",
                        render: (data) => data.account.name
                    },
                    {
                        name: "Nominal",
                        key: "value",
                        render: (data) => moneyParser(data.value)
                    },
                    {
                        name: "Keterangan",
                        key: "notes"
                    }
                ]}
                dataList={dataList}
                actionList={(props.onEdit || props.onDelete)
                    ? (data) => (
                        <Box display={"flex"} justifyContent={"center"} gap={1}>
                            {props.onEdit && (<Button startIcon={<Edit />} color="warning" onClick={() => props.onEdit && props.onEdit(data)}>Edit</Button>)}
                            {props.onDelete && (<Button startIcon={<Delete />} color="error" onClick={() => props.onDelete && props.onDelete(data.id)}>Hapus</Button>)}
                        </Box>
                    )
                    : undefined
                }
            />
        </Box>
    )
}