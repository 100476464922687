import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { TypeGender } from "../data/customer.interface";

export default function CustomerGenderSelect(props: { value: TypeGender, onChange: (value: TypeGender) => void }) {

    const typeList: TypeGender[] = ["Lakilaki", "Perempuan"]

    return (
        <FormControl margin="dense" fullWidth >
            <InputLabel size="small">Tipe</InputLabel>
            <Select
                value={props.value}
                label="Tipe"
                onChange={(e) => props.onChange(e.target.value as TypeGender)}
            >
                {typeList.map((employeeType) => (
                    <MenuItem key={employeeType} value={employeeType}>{employeeType}</MenuItem>
                ))}
            </Select>
        </FormControl >
    )
}