import { Card, CardContent, Container, Tab, Tabs } from "@mui/material";
import BookingInput from "../features/booking/components/input/Input";
import DeliveryInput from "../features/delivery/components/input/Input";
import { useState } from "react";
import useAccess from "../hooks/useAccess";

export default function SalesPage() {

    const access = useAccess()

    const [tabSelected, setTabSelected] = useState(0)

    return (
        <Container>
            <Tabs sx={{ marginBottom: 1 }} value={tabSelected} onChange={(_, value) => setTabSelected(value)}>
                <Tab value={0} label="Tiket" />
                <Tab value={1} label="Pengiriman" />
            </Tabs>
            {tabSelected === 0 && (
                <Card>
                    <CardContent>
                        <BookingInput isAllowSubmit={access("CREATE")} />
                    </CardContent>
                </Card>
            )}
            {tabSelected === 1 && (
                <Card>
                    <CardContent>
                        <DeliveryInput />
                    </CardContent>
                </Card>
            )}
        </Container>
    )
}