import { useState } from "react";

export const useFormValues = <T>(initialData: T) => {
    const [values, setValues] = useState<T>(initialData);

    const handleChangeValues = (name: keyof T, value: any) => {
        setValues({
            ...values,
            [name]: value
        })
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const name = e.target.name as keyof T
        const value = e.target.value as any
        handleChangeValues(name, value)
    }

    return {
        values,
        setValues,
        handleChange,
        handleChangeValues
    }
}
