import { createSlice } from '@reduxjs/toolkit'
import { createSchedule, deleteSchedule, editSchedule, getSchedule } from './schedule.api'
import { InterfaceScheduleData } from './schedule.interface'
import alertError from '../../../shared/alert/alertError'

export interface ScheduleState {
    loading: boolean,
    scheduleList: InterfaceScheduleData[]
}

const initialState: ScheduleState = {
    loading: false,
    scheduleList: []
}

export const scheduleSlice = createSlice({
    name: 'schedule',
    initialState,
    reducers: {},
    extraReducers(builder) {

        builder.addCase(getSchedule.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getSchedule.fulfilled, (state, action) => {
            state.loading = false
            state.scheduleList = action.payload.data.data
        })
        builder.addCase(getSchedule.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(createSchedule.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createSchedule.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createSchedule.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(editSchedule.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editSchedule.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editSchedule.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(deleteSchedule.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteSchedule.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteSchedule.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })
    },
})

export default scheduleSlice.reducer