import { createSlice } from '@reduxjs/toolkit'
import { editSetting, getSetting } from './setting.api'
import { InterfaceSettingData } from './setting.interface'
import alertError from '../../../shared/alert/alertError'

export interface SettingState {
    loading: boolean,
    settingList: InterfaceSettingData[]
}

const initialState: SettingState = {
    loading: false,
    settingList: []
}

export const settingSlice = createSlice({
    name: 'setting',
    initialState,
    reducers: {},
    extraReducers(builder) {

        builder.addCase(getSetting.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getSetting.fulfilled, (state, action) => {
            state.loading = false
            state.settingList = action.payload.data.data
        })
        builder.addCase(getSetting.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(editSetting.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editSetting.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editSetting.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })
    },
})

export default settingSlice.reducer