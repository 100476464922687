import { Box, Button, Card, CardContent, CardHeader, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InterfaceDeliveryCreatePaid, InterfaceDeliveryDetail } from "../data/delivery.interface";
import moneyParser from "../../../shared/moneyParser";
import { createDeliveryPaid, deleteDeliveryPaid, getDeliverDetail } from "../data/delivery.api";
import { AxiosResponse } from "axios";
import { RootState } from "../../../store";
import { useSetting } from "../../../hooks/useSetting";
import AccountSelect from "../../account/components/AccountSelect";
import FormMoney from "../../../shared/form/FormMoney";

export default function DeliveryEdit(props: { id: number, onChange: () => void }) {

    const dispatch = useDispatch<any>()
    const setting = useSetting()

    const { loading } = useSelector((state: RootState) => state.delivery)

    const accountLoan = setting.getValue("ACCOUNT_LOAN")

    const initialBookingPaid: InterfaceDeliveryCreatePaid = useMemo(() => ({
        delivery_id: props.id,
        name: "Pelunasan",
        account_source_id: accountLoan,
        account_id: 0,
        value: 0
    }), [props.id, accountLoan])

    const [deliveryDetail, setDeliveryDetail] = useState<InterfaceDeliveryDetail | undefined>(undefined)
    const [bookingPaid, setBookingPaid] = useState<InterfaceDeliveryCreatePaid>(initialBookingPaid)

    const toPay = (deliveryDetail?.total || 0) - (deliveryDetail?.total_paid || 0)

    useEffect(() => {
        getData()
        // eslint-disable-next-line
    }, [props.id])

    useEffect(() => {
        setBookingPaid({
            ...initialBookingPaid,
            value: toPay
        })
    }, [initialBookingPaid, toPay])

    const getData = () => {
        dispatch(getDeliverDetail(props.id))
            .unwrap()
            .then((response: AxiosResponse) => {
                if (response.status === 200) {
                    const deliveryData: InterfaceDeliveryDetail = response.data.data
                    setDeliveryDetail(deliveryData)
                }
            })
    }

    const handleSubmitPaid = async () => {
        try {
            await dispatch(createDeliveryPaid(bookingPaid)).unwrap()
            props.onChange()
            getData()
        } catch (error) { }
    }

    const handleDeletePaid = async (id: number) => {
        try {
            await dispatch(deleteDeliveryPaid(id)).unwrap()
            props.onChange()
            getData()
        }
        catch (error) { }
    }

    if (!deliveryDetail) return (<div>Loading...</div>)

    const paidLIst = deliveryDetail.paid_list || []

    console.log(toPay)

    return (
        <Box>
            <Card>
                <CardContent>
                    <Table>
                        <TableRow>
                            <TableCell>Tipe Paket</TableCell>
                            <TableCell>{deliveryDetail.package_type_id}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Harga Per KG</TableCell>
                            <TableCell>{moneyParser(deliveryDetail.price)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Biaya Packing</TableCell>
                            <TableCell>{moneyParser(deliveryDetail.price_packing)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Diskon</TableCell>
                            <TableCell>{moneyParser(deliveryDetail.discount_price)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Total Harga</TableCell>
                            <TableCell>{moneyParser(deliveryDetail.total)}</TableCell>
                        </TableRow>
                    </Table>
                </CardContent>
            </Card>
            <Card sx={{ marginTop: 1 }}>
                <CardHeader
                    title="Paket"
                />
                <CardContent>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Nama</TableCell>
                                <TableCell>Berat</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {deliveryDetail.package_list
                                .map((pack, i) => {
                                    return (
                                        <TableRow key={i}>
                                            <TableCell>{pack.name}</TableCell>
                                            <TableCell>{pack.weight}</TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </CardContent>
            </Card>
            <Card sx={{ marginTop: 1 }}>
                <CardHeader
                    title="Pembayaran"
                />
                <CardContent>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Nama</TableCell>
                                <TableCell>Akun</TableCell>
                                <TableCell>Nilai</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paidLIst
                                .sort((a, b) => a.id - b.id)
                                .map((paid, i) => {
                                    return (
                                        <TableRow key={i}>
                                            <TableCell>{paid.name}</TableCell>
                                            <TableCell>{paid.account.name}</TableCell>
                                            <TableCell colSpan={paid.name === "Pelunasan" ? 1 : 2}>{moneyParser(paid.value)}</TableCell>
                                            {paid.name === "Pelunasan" && (
                                                <TableCell>
                                                    <Button color="error" onClick={() => handleDeletePaid(paid.id)}>Hapus Pembayaran</Button>
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    )
                                })
                            }
                            {(toPay > 0 && !loading) && (
                                <TableRow>
                                    <TableCell>{bookingPaid.name}</TableCell>
                                    <TableCell>
                                        <AccountSelect required disabledHead headId={setting.getValue("ACCOUNT_MAIN_FLOW")} label={"Akun"} value={bookingPaid.account_id} onChange={value => setBookingPaid(last => ({ ...last, account_id: value || 0 }))} />
                                    </TableCell>
                                    <TableCell>
                                        <FormMoney max={toPay} value={bookingPaid.value} onChange={(e) => setBookingPaid(last => ({ ...last, value: parseInt(e.target.value) }))} />
                                    </TableCell>
                                    <TableCell>
                                        <Button sx={{ marginTop: "4px" }} onClick={() => handleSubmitPaid()}>Simpan</Button>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </CardContent>
            </Card>
        </Box>
    )
}