import { Box } from "@mui/material";
import FormDate from "../../../../shared/form/FormDate";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import CitySelect from "../../../city/components/CitySelect";
import TerminalSelect from "../../../terminal/components/TerminalSelect";
import alertError from "../../../../shared/alert/alertError";
import { InterfaceDeliveryInput } from "../../data/delivery.interface";
import { setDeliveryInput } from "../../data/delivery.reducer";

export default function InputA() {

    const dispatch = useDispatch<any>()

    const { deliveryInput } = useSelector((state: RootState) => state.delivery)

    const handleChangeValues = (name: keyof InterfaceDeliveryInput, value: any) => {
        let dataToUpdate: InterfaceDeliveryInput = {}
        if (name === "start_city") {
            if (value.id === deliveryInput.finish_city?.id) {
                alertError("Kota keberangkatan tidak boleh sama dengan kota tujuan")
                return
            }
            dataToUpdate = {
                start_terminal: undefined
            }
        }
        if (name === "finish_city") {
            if (value.id === deliveryInput.start_city?.id) {
                alertError("Kota tujuan tidak boleh sama dengan kota keberangkatan")
                return
            }
            dataToUpdate = {
                finish_terminal: undefined
            }
        }
        if (
            deliveryInput.schedule?.start_city.id !== deliveryInput.start_city?.id &&
            deliveryInput.schedule?.finish_city.id !== deliveryInput.finish_city?.id
        ) {
            dataToUpdate = {
                schedule: undefined
            }
        }
        dispatch(setDeliveryInput({
            ...deliveryInput,
            ...dataToUpdate,
            [name]: value
        }))
    }

    return (
        <Box>
            <FormDate label="Tanggal Input" name="ts_created" value={deliveryInput.ts_created?.split(" ")[0]} onChange={e => handleChangeValues("ts_created", e.target.value)} />
            <FormDate label="Tanggal Masuk Barang" name="start_date" value={deliveryInput.start_date} onChange={e => handleChangeValues("start_date", e.target.value)} />
            <CitySelect terminalOnly label="Kota Keberangkatan" value={deliveryInput.start_city?.id} onChange={(_, valueDetail) => handleChangeValues("start_city", (valueDetail || undefined))} />
            {deliveryInput.start_city && (
                <TerminalSelect city_id={deliveryInput.start_city.id} label="Terminal Keberangkatan" value={deliveryInput.start_terminal?.id} onChange={(_, valueDetail) => handleChangeValues("start_terminal", (valueDetail || undefined))} />
            )}
            <CitySelect terminalOnly label="Kota Tujuan" value={deliveryInput.finish_city?.id} onChange={(_, valueDetail) => handleChangeValues("finish_city", (valueDetail || undefined))} />
            {deliveryInput.finish_city && (
                <TerminalSelect city_id={deliveryInput.finish_city.id} label="Terminal Tujuan" value={deliveryInput.finish_terminal?.id} onChange={(_, valueDetail) => handleChangeValues("finish_terminal", (valueDetail || undefined))} />
            )}
        </Box>
    )
}