import { Box, Button, Card, CardContent, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useEffect, useState } from "react";
import { InterfaceSettingData } from "../data/setting.interface";
import { editSetting, getSetting } from "../data/setting.api";
import FormMoney from "../../../shared/form/FormMoney";
import AccountSelect from "../../account/components/AccountSelect";

export default function SettingTable() {

    const dispatch = useDispatch<any>()

    const [settingList, setSettingList] = useState<InterfaceSettingData[]>([])

    const settingListOriginal = useSelector((state: RootState) => state.setting).settingList

    useEffect(() => {
        dispatch(getSetting())
    }, [dispatch])

    useEffect(() => {
        setSettingList(settingListOriginal)
    }, [settingListOriginal])

    const handleSubmit = async (id: number, value: number | null) => {
        try {
            await dispatch(editSetting({
                id: id,
                value: value
            })).unwrap()
            await dispatch(getSetting())
        } catch (error) { }
    }

    const renderValue = (data: InterfaceSettingData) => {
        switch (data.type) {
            case "Money":
                return (
                    <Box display={"flex"} gap={1}>
                        <FormMoney
                            value={data.value}
                            onChange={(e) => setSettingList(settingList.map((item) => {
                                if (item.id === data.id) {
                                    const value: number = parseInt(e.target.value)
                                    return {
                                        ...item,
                                        value: value
                                    }
                                }
                                return item
                            }))}
                        />
                        <Box display="flex" flexGrow={0} alignItems={"center"}>
                            <Button
                                disabled={data.value === settingListOriginal.find((item) => item.id === data.id)?.value}
                                onClick={async () => handleSubmit(data.id, data.value)}
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                )
            case "Account":
                return (
                    <Box display={"flex"} gap={1}>
                        <AccountSelect
                            disabledHead
                            value={data.value}
                            onChange={(value) => setSettingList(settingList.map((item) => {
                                if (item.id === data.id) {
                                    return {
                                        ...item,
                                        value: value
                                    }
                                }
                                return item
                            }))}
                        />
                        <Box display="flex" flexGrow={0} alignItems={"center"}>
                            <Button
                                disabled={data.value === settingListOriginal.find((item) => item.id === data.id)?.value}
                                onClick={async () => handleSubmit(data.id, data.value)}
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                )
            case "AccountHead":
                return (
                    <Box display={"flex"} gap={1}>
                        <AccountSelect
                            disabledChild
                            value={data.value}
                            onChange={(value) => setSettingList(settingList.map((item) => {
                                if (item.id === data.id) {
                                    return {
                                        ...item,
                                        value: value
                                    }
                                }
                                return item
                            }))}
                        />
                        <Box display="flex" flexGrow={0} alignItems={"center"}>
                            <Button
                                disabled={data.value === settingListOriginal.find((item) => item.id === data.id)?.value}
                                onClick={async () => handleSubmit(data.id, data.value)}
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                )
            default:
                return data.value
        }
    }

    return (
        <Card>
            <CardContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Nama</TableCell>
                            <TableCell>Nilai</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {settingList.map((data, index) => (
                            <TableRow key={index}>
                                <TableCell>{data.name}</TableCell>
                                <TableCell>{renderValue(data)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    )
}