import { TextField } from "@mui/material";
import { ChangeEvent, ChangeEventHandler } from "react";

export default function FormNumber(props: {
    label: string,
    name?: string,
    value: number,
    required?: boolean,
    disabled?: boolean,
    onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>,
    readonly?: boolean
}) {

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const valueNumber = parseFloat(e.target.value)
        props.onChange && props.onChange({
            ...e,
            target: {
                ...e.target,
                name: props.name || "",
                value: valueNumber as unknown as string
            }
        })
    }

    return (
        <TextField
            fullWidth
            type="number"
            label={props.label}
            name={props.name}
            value={props.value}
            onChange={handleChange}
            required={props.required}
            disabled={props.disabled}
            InputProps={{ readOnly: props.readonly }}
        />
    )
}