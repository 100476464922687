import { Box, Button } from "@mui/material";
import { InitialSeatForm, InterfaceSeatForm } from "../data/seat.interface";
import { useFormValues } from "../../../hooks/useFormValues";
import { useDispatch } from "react-redux";
import { FormEvent } from "react";
import { createSeat, deleteSeat, editSeat } from "../data/seat.api";
import alertSuccess from "../../../shared/alert/alertSuccess";
import FormText from "../../../shared/form/FormText";
import FormMoney from "../../../shared/form/FormMoney";
import alertDelete from "../../../shared/alert/alertDelete";

export default function SeatForm(props: { data?: InterfaceSeatForm, onSubmit: () => void }) {

    const dispatch = useDispatch<any>()

    const { values, handleChange } = useFormValues<InterfaceSeatForm>(props.data || InitialSeatForm)

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault()
        try {
            if (values.id === 0) {
                await dispatch(createSeat(values)).unwrap()
                await alertSuccess("Berhasil menyimpan data")
            }
            else {
                await dispatch(editSeat(values)).unwrap()
                await alertSuccess("Berhasil mengubah data")
            }
            props.onSubmit()
        } catch (error) { }
    }

    const handleDelete = async () => {
        alertDelete()
            .then(async isConfirmed => {
                if (isConfirmed) {
                    if (values.id !== 0) {
                        await dispatch(deleteSeat(props.data?.id || 0)).unwrap()
                        await alertSuccess("Berhasil menghapus data")
                        props.onSubmit()
                    }
                }
            })
    }

    return (
        <Box component={"form"} onSubmit={handleSubmit}>
            <FormText label="Nama" name="name" value={values.name} onChange={handleChange} />
            <FormMoney label="Harga" name="price" value={values.price} onChange={handleChange} />
            <Box marginTop={1} display={"flex"} justifyContent={"flex-end"} gap={1}>
                {props.data?.id ? (<Button color="error" onClick={handleDelete}>Hapus</Button>) : ""}
                <Button type="submit">Simpan</Button>
            </Box>
        </Box>
    )
}