import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Content from './Content';
import Sidebar from './Sidebar';
import { ReactNode } from 'react';
import { IconButton, Tooltip, Typography, useTheme } from '@mui/material';
import { LogoutOutlined } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { resetLoginData } from '../../features/auth/data/auth.reducer';
import logo_sm from '../../assets/img/logo_sm.png';
import { RootState } from '../../store';

export default function Layout(props: { component: ReactNode }) {

    const dispatch = useDispatch<any>()
    const theme = useTheme()

    const { loginData } = useSelector((state: RootState) => state.auth)

    const handleLogout = () => {
        dispatch(resetLoginData())
        window.location.replace("/")
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <AppBar position="fixed" sx={{ zIndex: theme.zIndex.drawer + 1 }}>
                <Toolbar>
                    <Box display={"flex"} flexGrow={1} justifyContent={"space-between"} alignItems={"center"}>
                        <Box>
                            <img src={logo_sm} alt='head' height={38} />
                        </Box>
                        <Box display={"flex"} gap={2}>
                            <Typography variant='h6' color={theme.palette.primary.main} >{loginData.name}</Typography>
                            <Tooltip title="Logout">
                                <IconButton color='primary' onClick={handleLogout}>
                                    <LogoutOutlined />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
            <Sidebar />
            <Content component={props.component} />
        </Box>
    )
}