import { Box, Button, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useEffect, useState } from "react";
import CustomTable from "../../../shared/CustomTable";
import { InterfaceBookingData } from "../data/booking.interface";
import { Delete, Edit, Print } from "@mui/icons-material";
import { getBooking } from "../data/booking.api";
import moment from "moment";
import moneyParser from "../../../shared/moneyParser";
import { amber, green } from "@mui/material/colors";

export default function BookingTableAll(props: {
    onEdit?: (data: InterfaceBookingData) => void,
    onDelete?: (id: number) => void,
    onPrint?: (id: number) => void
}) {

    const dispatch = useDispatch<any>()

    const { bookingList, bookingListTotal } = useSelector((state: RootState) => state.booking)

    const [skip, setSkip] = useState(0)
    const [take, setTake] = useState(5)

    useEffect(() => {
        dispatch(getBooking({ skip, take }))
    }, [dispatch, skip, take])

    return (
        <Box>
            <CustomTable
                columns={[
                    {
                        key: "id",
                        name: "ID"
                    },
                    {
                        key: "ts_created",
                        name: "Tanggal Input",
                        render: (data) => moment(data.ts_created).format("YYYY-MM-DD")
                    },
                    {
                        key: "schedule",
                        name: "Keberangkatan",
                        render: (data) => data.schedule.start_city.name
                    },
                    {
                        key: "schedule",
                        name: "Tujuan",
                        render: (data) => data.schedule.finish_city.name
                    },
                    {
                        key: "total_customer",
                        name: "Jumlah Penumpang"
                    },
                    {
                        key: "total",
                        name: "Harga",
                        render: (data) => moneyParser(data.total)
                    },
                    {
                        key: "id",
                        name: "Lunas",
                        render: (data) => data.total === data.total_paid
                            ? <Typography variant="body1" align="left" color={green[800]}>Sudah</Typography>
                            : <Typography variant="body1" align="left" color={amber[800]}>Belum</Typography>
                    }
                ]}
                dataList={bookingList}
                actionList={(props.onEdit || props.onDelete)
                    ? (data) => (
                        <Box display={"flex"} justifyContent={"center"} gap={1}>
                            <Button startIcon={<Print />} color="info" onClick={() => props.onPrint && props.onPrint(data.id)}>Cetak</Button>
                            {props.onEdit && (<Button startIcon={<Edit />} color="warning" onClick={() => props.onEdit && props.onEdit(data)}>Edit</Button>)}
                            {props.onDelete && (<Button startIcon={<Delete />} color="error" onClick={() => props.onDelete && props.onDelete(data.id)}>Hapus</Button>)}
                        </Box>
                    )
                    : undefined
                }
                pagination={{
                    setSkip,
                    setTake,
                    total: bookingListTotal
                }}
            />
        </Box>
    )
}