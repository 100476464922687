import { Autocomplete, Box, TextField } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAccount } from "../data/account.api";
import { RootState } from "../../../store";
import { InterfaceAccountDataTree } from "../data/account.interface";

export default function AccountSelect(props: {
    label?: string,
    disabledHead?: boolean,
    disabledChild?: boolean,
    headId?: number | null,
    value: number | null | undefined,
    onChange?: (value: number | null) => void,
    required?: boolean,
    disabled?: boolean,
    disabledIdList?: number[],
    readonly?: boolean
}) {

    const dispatch = useDispatch<any>()

    const { loading, accountList } = useSelector((state: RootState) => state.account)

    useEffect(() => {
        dispatch(getAccount())
    }, [dispatch])

    const getSubAccount = (indent: number, parrent: number | null): any => {
        return accountList
            .filter(account => account.parrent_id === parrent)
            .map(account => {
                return [
                    {
                        ...account,
                        indent: indent
                    },
                    ...getSubAccount(indent + 1, account.id)
                ]
            })
    }

    const accountTree = getSubAccount(0, null)
    const accountListOrdered: InterfaceAccountDataTree[] = accountTree.flat(Infinity)

    return (
        <Box display={"flex"} flexGrow={1}>
            {loading
                ? (
                    <TextField fullWidth label={props.label || "Akun Keuangan"} />
                )
                : (
                    <Autocomplete
                        fullWidth
                        disabled={props.disabled}
                        options={props.headId ? accountListOrdered.filter(account => account.id === props.headId || account.parrent_id === props.headId) : accountListOrdered}
                        getOptionLabel={options => options.name}
                        renderOption={(props, option, { selected }) => (
                            <li {...props} style={{ paddingLeft: `${(option.indent + 1)}rem` }}>
                                {option.name}
                            </li>
                        )}
                        getOptionDisabled={option => (
                            props.disabledHead
                                ? option.is_head
                                : props.disabledChild
                                    ? option.is_head === false
                                    : (props.disabledIdList || []).includes(option.id)
                        )}
                        value={accountListOrdered.find(account => account.id === props.value)}
                        onChange={(_, value) => props.onChange && props.onChange(value?.id || null)}
                        readOnly={props.readonly}
                        renderInput={(params) => <TextField {...params} label={props.label || "Akun Keuangan"} fullWidth required={props.required} />}
                    />
                )
            }
        </Box>
    )
}