import { Box, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useEffect } from "react";
import CustomTable from "../../../shared/CustomTable";
import { InterfaceSparepartUsedData } from "../data/sparepartUsed.interface";
import { Delete, Edit } from "@mui/icons-material";
import { getSparepartUsed } from "../data/sparepartUsed.api";
import moment from "moment";

export default function SparepartUsedTable(props: {
    onEdit?: (data: InterfaceSparepartUsedData) => void,
    onDelete?: (id: number) => void
}) {

    const dispatch = useDispatch<any>()

    const { sparepartUsedList } = useSelector((state: RootState) => state.sparepartUsed)

    useEffect(() => {
        dispatch(getSparepartUsed())
    }, [dispatch])

    return (
        <Box>
            <CustomTable
                columns={[
                    {
                        name: "Tanggal",
                        key: "ts_created",
                        render: (data) => moment(data.ts_created).format("YYYY-MM-DD")
                    },
                    {
                        name: "Sparepart",
                        key: "sparepart_id",
                        render: (data) => data.sparepart.name
                    },
                    {
                        name: "Jumlah",
                        key: "qty"
                    },
                    {
                        name: "Keterangan",
                        key: "note"
                    }
                ]}
                dataList={sparepartUsedList}
                actionList={(props.onEdit || props.onDelete)
                    ? (data) => (
                        <Box display={"flex"} justifyContent={"center"} gap={1}>
                            {props.onEdit && (<Button startIcon={<Edit />} color="warning" onClick={() => props.onEdit && props.onEdit(data)}>Edit</Button>)}
                            {props.onDelete && (<Button startIcon={<Delete />} color="error" onClick={() => props.onDelete && props.onDelete(data.id)}>Hapus</Button>)}
                        </Box>
                    )
                    : undefined
                }
            />
        </Box>
    )
}