import { Box, Button, Card, CardContent, CardHeader, Checkbox, Grid, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createBookingPaid, deleteBooking, deleteBookingPaid, editBooking, editBookingCustomer, getBookingDetail } from "../data/booking.api";
import { InterfaceBookingDetailData, InterfaceBookingPaidCreate } from "../data/booking.interface";
import { AxiosResponse } from "axios";
import alertDelete from "../../../shared/alert/alertDelete";
import alertSuccess from "../../../shared/alert/alertSuccess";
import moneyParser from "../../../shared/moneyParser";
import AccountSelect from "../../account/components/AccountSelect";
import { useSetting } from "../../../hooks/useSetting";
import FormMoney from "../../../shared/form/FormMoney";
import { RootState } from "../../../store";
import TerminalSelect from "../../terminal/components/TerminalSelect";
import { useFormValues } from "../../../hooks/useFormValues";

export default function BookingEdit(props: { bookingId: number, onChange: (close?: boolean) => void }) {

    const dispatch = useDispatch<any>()
    const setting = useSetting()

    const accountLoan = setting.getValue("ACCOUNT_LOAN")

    const initialBookingPaid = useMemo(() => ({
        booking_id: props.bookingId,
        name: "Pelunasan",
        account_source_id: accountLoan,
        account_id: 0,
        value: 0
    }), [props.bookingId, accountLoan])

    const { loading } = useSelector((state: RootState) => state.booking)

    const [bookingDetail, setBookingDetail] = useState<InterfaceBookingDetailData | null>(null)
    const [bookingPaid, setBookingPaid] = useState<InterfaceBookingPaidCreate>(initialBookingPaid)

    const { values, setValues, handleChangeValues } = useFormValues<{ start_terminal_id: number, finish_terminal_id: number }>({ start_terminal_id: 0, finish_terminal_id: 0 })

    useEffect(() => {
        getData()
        // eslint-disable-next-line
    }, [props.bookingId])

    useEffect(() => {
        if (bookingDetail !== null) {
            setBookingPaid({
                ...initialBookingPaid,
                value: bookingDetail?.total - bookingDetail?.total_paid
            })
        }
    }, [bookingDetail, initialBookingPaid])

    const getData = () => {
        dispatch(getBookingDetail(props.bookingId))
            .unwrap()
            .then((response: AxiosResponse) => {
                if (response.status === 200) {
                    const bookingData: InterfaceBookingDetailData = response.data.data
                    setBookingDetail(bookingData)
                    setValues({
                        start_terminal_id: bookingData.start_terminal_id,
                        finish_terminal_id: bookingData.finish_terminal_id
                    })
                }
            })
    }

    const handleEditReady = async (id: number, is_ready: boolean) => {
        await dispatch(editBookingCustomer({ id: id, is_ready: is_ready }))
        getData()
        props.onChange()
    }

    const handleDelete = () => {
        alertDelete()
            .then(async isConfirmed => {
                if (isConfirmed) {
                    try {
                        await dispatch(deleteBooking(props.bookingId)).unwrap()
                        await alertSuccess("Berhasil menghapus data")
                        props.onChange(true)
                    } catch (error) { }
                }
            })
    }

    const handleSubmitPaid = async () => {
        try {
            await dispatch(createBookingPaid(bookingPaid)).unwrap()
            props.onChange()
            getData()
        } catch (error) { }
    }

    const handleDeletePaid = async (id: number) => {
        try {
            await dispatch(deleteBookingPaid(id)).unwrap()
            props.onChange()
            getData()
        }
        catch (error) { }
    }

    const handleEditTerminal = async () => {
        try {
            await dispatch(editBooking({ start_terminal_id: values.start_terminal_id, finish_terminal_id: values.finish_terminal_id, id: props.bookingId }))
            props.onChange()
            getData()
        } catch (error) { }
    }

    if (bookingDetail === null) return (<Box>Loading...</Box>)

    const customerList = bookingDetail.customer_list || []
    const paidLIst = bookingDetail.paid_list || []

    return (
        <Box>
            <Card>
                <CardHeader
                    title="Pelanggan"
                />
                <CardContent>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Nama</TableCell>
                                <TableCell>Kursi</TableCell>
                                <TableCell>Jadi Berangkat?</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {customerList
                                .sort((a, b) => a.customer.name.localeCompare(b.customer.name))
                                .map((customer, i) => {
                                    return (
                                        <TableRow key={i}>
                                            <TableCell>{(i === 0 || customerList[i - 1].customer.name !== customer.customer.name) ? customer.customer.name : ''}</TableCell>
                                            <TableCell>{customer.seat.name}</TableCell>
                                            <TableCell><Checkbox checked={customer.is_ready} onChange={(_, checked) => handleEditReady(customer.id, checked)} /></TableCell>
                                        </TableRow>
                                    )
                                })}
                        </TableBody>
                    </Table>
                </CardContent>
            </Card>
            <Card sx={{ marginTop: 1 }}>
                <CardHeader
                    title="Terminal"
                />
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={5}>
                            <TerminalSelect city_id={bookingDetail.schedule.start_city.id} label="Terminal Keberangkatan" value={bookingDetail.start_terminal_id} onChange={(value) => handleChangeValues("start_terminal_id", value)} />
                        </Grid>
                        <Grid item xs={5}>
                            <TerminalSelect city_id={bookingDetail.schedule.finish_city.id} label="Terminal Tujuan" value={bookingDetail.finish_terminal_id} onChange={(value) => handleChangeValues("finish_terminal_id", value)} />
                        </Grid>
                        <Grid item xs={2}>
                            <Button
                                sx={{ marginTop: 1 }}
                                fullWidth
                                disabled={
                                    bookingDetail.start_terminal_id === values.start_terminal_id &&
                                    bookingDetail.finish_terminal_id === values.finish_terminal_id
                                }
                                onClick={handleEditTerminal}
                            >
                                Simpan
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Card sx={{ marginTop: 1 }}>
                <CardHeader
                    title="Pembayaran"
                />
                <CardContent>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Nama</TableCell>
                                <TableCell>Akun</TableCell>
                                <TableCell>Nilai</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paidLIst
                                .sort((a, b) => a.id - b.id)
                                .map((paid, i) => {
                                    return (
                                        <TableRow key={i}>
                                            <TableCell>{paid.name}</TableCell>
                                            <TableCell>{paid.account.name}</TableCell>
                                            <TableCell colSpan={paid.name === "Pelunasan" ? 1 : 2}>{moneyParser(paid.value)}</TableCell>
                                            {paid.name === "Pelunasan" && (
                                                <TableCell>
                                                    <Button color="error" onClick={() => handleDeletePaid(paid.id)}>Hapus Pembayaran</Button>
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    )
                                })
                            }
                            {(bookingDetail.total_paid < bookingDetail.total && !loading) && (
                                <TableRow>
                                    <TableCell>{bookingPaid.name}</TableCell>
                                    <TableCell>
                                        <AccountSelect required disabledHead headId={setting.getValue("ACCOUNT_MAIN_FLOW")} label={"Akun"} value={bookingPaid.account_id} onChange={value => setBookingPaid(last => ({ ...last, account_id: value || 0 }))} />
                                    </TableCell>
                                    <TableCell>
                                        <FormMoney value={bookingPaid.value} onChange={(e) => setBookingPaid(last => ({ ...last, value: parseInt(e.target.value) }))} />
                                    </TableCell>
                                    <TableCell>
                                        <Button sx={{ marginTop: "4px" }} onClick={() => handleSubmitPaid()}>Simpan</Button>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </CardContent>
            </Card>
            <Box marginTop={1} display={"flex"} justifyContent={"flex-end"}>
                <Button color="error" onClick={handleDelete} variant="outlined">
                    Hapus Transaksi
                </Button>
            </Box>
        </Box>
    )
}