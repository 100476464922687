import { Box, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useEffect } from "react";
import CustomTable from "../../../shared/CustomTable";
import { InterfacePackageTypeData } from "../data/packageType.interface";
import { Delete, Edit } from "@mui/icons-material";
import { getPackageType } from "../data/packageType.api";
import moneyParser from "../../../shared/moneyParser";

export default function PackageTypeTable(props: {
    onEdit?: (data: InterfacePackageTypeData) => void,
    onDelete?: (id: number) => void
}) {

    const dispatch = useDispatch<any>()

    const { packageTypeList } = useSelector((state: RootState) => state.packageType)

    useEffect(() => {
        dispatch(getPackageType())
    }, [dispatch])

    return (
        <Box>
            <CustomTable
                columns={[
                    {
                        name: "Kode",
                        key: "code"
                    },
                    {
                        name: "Nama",
                        key: "name"
                    },
                    {
                        name: "Harga",
                        key: "price",
                        render: (data) => data.price ? moneyParser(data.price) : "-"
                    },
                    {
                        name: "Aktif",
                        key: "is_active",
                        render: (data) => data.is_active ? "Ya" : "Tidak"
                    }
                ]}
                dataList={packageTypeList}
                actionList={(props.onEdit || props.onDelete)
                    ? (data) => (
                        <Box display={"flex"} justifyContent={"center"} gap={1}>
                            {props.onEdit && (<Button startIcon={<Edit />} color="warning" onClick={() => props.onEdit && props.onEdit(data)}>Edit</Button>)}
                            {props.onDelete && (<Button startIcon={<Delete />} color="error" onClick={() => props.onDelete && props.onDelete(data.id)}>Hapus</Button>)}
                        </Box>
                    )
                    : undefined
                }
            />
        </Box>
    )
}