export interface InterfaceProvinceData {
    id: number,
    ts_created: string,
    name: string
}

export interface InterfaceProvinceForm {
    id: number,
    name: string
}

export interface InterfaceProvinceCreate {
    name: string
}

export interface InterfaceProvinceEdit {
    id: number,
    name?: string
}

export const InitialProvinceForm: InterfaceProvinceForm = {
    id: 0,
    name: ""
}