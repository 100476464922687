import { createSlice } from '@reduxjs/toolkit'
import { createCarType, deleteCarType, editCarType, getCarType } from './carType.api'
import { InterfaceCarTypeData } from './carType.interface'
import alertError from '../../../shared/alert/alertError'

export interface CarTypeState {
    loading: boolean,
    carTypeList: InterfaceCarTypeData[]
}

const initialState: CarTypeState = {
    loading: false,
    carTypeList: []
}

export const carTypeSlice = createSlice({
    name: 'carType',
    initialState,
    reducers: {},
    extraReducers(builder) {

        builder.addCase(getCarType.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getCarType.fulfilled, (state, action) => {
            state.loading = false
            state.carTypeList = action.payload.data.data
        })
        builder.addCase(getCarType.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(createCarType.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createCarType.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createCarType.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(editCarType.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editCarType.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editCarType.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(deleteCarType.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteCarType.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteCarType.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })
    },
})

export default carTypeSlice.reducer