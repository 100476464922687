import { InterfaceCityData } from "../../city/data/city.interface"

export type TypeDay = "Senin" | "Selasa" | "Rabu" | "Kamis" | "Jumat" | "Sabtu" | "Minggu"

export interface InterfaceScheduleData {
    id: number,
    day_list: TypeDay[],
    ts_created: string,
    start_city_id: number,
    start_time: string,
    finish_city_id: number,
    finish_time: string,
    note: string,
    price_delivery: number,
    is_show_on_calendar: boolean,
    cost_gas: number,
    cost_maintenance: number,
    start_city: InterfaceCityData,
    finish_city: InterfaceCityData
}

export interface InterfaceScheduleForm {
    id: number,
    day_list: TypeDay[],
    ts_created: string,
    start_city_id: number,
    start_time: string,
    finish_city_id: number,
    finish_time: string,
    note: string,
    price_delivery: number,
    is_show_on_calendar: boolean,
    cost_gas: number,
    cost_maintenance: number
}

export interface InterfaceScheduleCreate {
    day_list: TypeDay[],
    ts_created: string,
    start_city_id: number,
    start_time: string,
    finish_city_id: number,
    finish_time: string,
    note: string,
    price_delivery: number,
    is_show_on_calendar: boolean,
    cost_gas: number,
    cost_maintenance: number
}

export interface InterfaceScheduleEdit {
    id: number,
    day_list?: TypeDay[],
    ts_created?: string,
    start_city_id?: number,
    start_time?: string,
    finish_city_id?: number,
    finish_time?: string,
    note: string,
    price_delivery: number,
    is_show_on_calendar: boolean,
    cost_gas: number,
    cost_maintenance: number
}

export const InitialScheduleForm: InterfaceScheduleForm = {
    id: 0,
    day_list: [],
    ts_created: "",
    start_city_id: 0,
    start_time: "",
    finish_city_id: 0,
    finish_time: "",
    note: "",
    price_delivery: 0,
    is_show_on_calendar: false,
    cost_gas: 0,
    cost_maintenance: 0
}