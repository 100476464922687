export interface InterfaceBuyData {
    id: number,
    ts_created: string,
    supplier_id: number,
    description: string,
    due_date: string | null,
    supplier: {
        name: string
    },
    sparepart_list: InterfaceBuySparepartData[],
    paid_list: InterfaceBuyPaidData[],
    total: number,
    total_paid: number
}

export interface InterfaceBuyForm {
    id: number,
    supplier_id: number,
    description: string,
    due_date: string | null,
    sparepart_list: InterfaceBuySparepartForm[]
    paid_list: interfaceBuyPaidForm[]
}

export interface InterfaceBuyCreate {
    supplier_id: number,
    description: string,
    due_date: string | null,
    sparepart_list: InterfaceBuySparepartForm[],
    paid_list: interfaceBuyPaidForm[]
}

export interface InterfaceBuyEdit {
    id: number,
    supplier_id: number,
    description: string,
    due_date: string | null
}

export const InitialBuyForm: InterfaceBuyForm = {
    id: 0,
    supplier_id: 0,
    description: "",
    due_date: null,
    sparepart_list: [],
    paid_list: []
}


export interface InterfaceBuySparepartData {
    id: number,
    ts_created: string,
    buy_id: number,
    sparepart_id: number,
    qty: number,
    price: number
}

export interface InterfaceBuySparepartForm {
    id: number,
    sparepart_id: number,
    qty: number,
    price: number
}

export const InitialBuySparepartForm: InterfaceBuySparepartForm = {
    id: 0,
    sparepart_id: 0,
    qty: 1,
    price: 0
}


export interface InterfaceBuyPaidData {
    id: number,
    ts_created: string,
    name: string,
    buy_id: number,
    account_source_id: number,
    account_id: number,
    value: number,
}

export interface interfaceBuyPaidForm {
    id?: number,
    name: string,
    account_source_id: number,
    account_id: number,
    value: number,
}

export interface interfaceBuyPayForm {
    account_id: number,
    value: number
}

export const InitialBuyPaidForm: interfaceBuyPaidForm = {
    name: "",
    account_source_id: 0,
    account_id: 0,
    value: 0
}

export const InitialBuyPayForm: interfaceBuyPayForm = {
    account_id: 0,
    value: 0
}    