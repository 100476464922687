export interface InterfaceCarData {
    id: number,
    ts_created: string,
    name: string,
    number: string,
    type_id: number,
    type: {
        name: string
    }
}

export interface InterfaceCarForm {
    id: number,
    name: string,
    number: string,
    type_id: number
}

export interface InterfaceCarCreate {
    name: string,
    number: string,
    type_id: number
}

export interface InterfaceCarEdit {
    id: number,
    name?: string,
    number?: string,
    type_id?: number
}

export const InitialCarForm: InterfaceCarForm = {
    id: 0,
    name: "",
    number: "",
    type_id: 0
}