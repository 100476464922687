import { createSlice } from '@reduxjs/toolkit'
import { createDelivery, createDeliveryPaid, deleteDelivery, deleteDeliveryPaid, editDelivery, getDeliverDetail, getDelivery, getDeliveryForItinerary, getDeliveryReceivable } from './delivery.api'
import { InterfaceDeliveryData, InterfaceDeliveryInput } from './delivery.interface'
import alertError from '../../../shared/alert/alertError'

export interface DeliveryState {
    loading: boolean,
    deliveryList: InterfaceDeliveryData[],
    deliveryListTotal: number,
    deliveryReceivableList: InterfaceDeliveryData[],
    deliveryInput: InterfaceDeliveryInput,
    deliveryItineraryList: InterfaceDeliveryData[]
}

const initialState: DeliveryState = {
    loading: false,
    deliveryList: [],
    deliveryListTotal: 0,
    deliveryReceivableList: [],
    deliveryInput: {},
    deliveryItineraryList: []
}

export const deliverySlice = createSlice({
    name: 'delivery',
    initialState,
    reducers: {
        setDeliveryInput: (state, action: { type: string, payload: InterfaceDeliveryInput }) => {
            state.deliveryInput = action.payload
        }
    },
    extraReducers(builder) {

        builder.addCase(getDelivery.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getDelivery.fulfilled, (state, action) => {
            state.loading = false
            state.deliveryList = action.payload.data.data
            state.deliveryListTotal = action.payload.data.total_data
        })
        builder.addCase(getDelivery.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(getDeliveryReceivable.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getDeliveryReceivable.fulfilled, (state, action) => {
            state.loading = false
            state.deliveryReceivableList = action.payload.data.data
        })
        builder.addCase(getDeliveryReceivable.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(getDeliverDetail.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getDeliverDetail.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(getDeliverDetail.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(getDeliveryForItinerary.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getDeliveryForItinerary.fulfilled, (state, action) => {
            state.loading = false
            state.deliveryItineraryList = action.payload.data.data
        })
        builder.addCase(getDeliveryForItinerary.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(createDelivery.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createDelivery.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createDelivery.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(editDelivery.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editDelivery.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editDelivery.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(deleteDelivery.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteDelivery.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteDelivery.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(createDeliveryPaid.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createDeliveryPaid.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createDeliveryPaid.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(deleteDeliveryPaid.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteDeliveryPaid.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteDeliveryPaid.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })
    },
})

export const { setDeliveryInput } = deliverySlice.actions

export default deliverySlice.reducer