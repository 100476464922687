import { Container, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import alertDelete from "../../shared/alert/alertDelete";
import { deleteDelivery, getDelivery } from "../../features/delivery/data/delivery.api";
import alertSuccess from "../../shared/alert/alertSuccess";
import useAccess from "../../hooks/useAccess";
import DeliveryTableAll from "../../features/delivery/components/DeliveryTableAll";
import { useState } from "react";
import { InterfaceDeliveryData } from "../../features/delivery/data/delivery.interface";
import CustomModal from "../../shared/CustomModal";
import DeliveryEdit from "../../features/delivery/components/DeliveryEdit";

export default function DeliveryPage() {

    const dispatch = useDispatch<any>()
    const access = useAccess()

    const [dataSelected, setDataSelected] = useState<InterfaceDeliveryData | undefined>(undefined)
    const [countUpdate, setCountUpdate] = useState(0)

    const handleEdit = (data: InterfaceDeliveryData) => {
        setDataSelected(data)
    }

    const handleDelete = (id: number) => {
        alertDelete()
            .then(async isConfirmed => {
                if (isConfirmed) {
                    try {
                        await dispatch(deleteDelivery(id)).unwrap()
                        await alertSuccess("Berhasil menghapus data")
                        await dispatch(getDelivery({ skip: 0, take: 5 }))
                    } catch (error) { }
                }
            })
    }

    const handlePrint = (id: number) => {
        window.open(`/#/delivery/print/${id}`, "_blank")
    }

    return (
        <Container>
            <CustomModal
                open={Boolean(dataSelected)}
                title="Edit Delivery"
                onClose={() => setDataSelected(undefined)}
                size="lg"
                component={dataSelected && <DeliveryEdit id={dataSelected?.id} onChange={() => setCountUpdate(last => last + 1)} />}
            />
            <Typography variant="h3">Pengiriman</Typography>
            <br />
            <DeliveryTableAll onEdit={access("EDIT") ? handleEdit : undefined} onDelete={access("DELETE") ? handleDelete : undefined} countUpdate={countUpdate} onPrint={handlePrint} />
        </Container>
    )
}