import { Container } from "@mui/material";
import Calendar from "../features/booking/components/calendar/Calendar";

export default function BookingPage() {

    return (
        <Container>
            <Calendar />
        </Container>
    )
}