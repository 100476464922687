import { createSlice } from '@reduxjs/toolkit'
import { createUser, deleteUser, editUser, getMenu, getUser } from './user.api'
import { InterfaceMenuData, InterfaceUserData } from './user.interface'
import alertError from '../../../shared/alert/alertError'

export interface UserState {
    loading: boolean,
    userList: InterfaceUserData[],
    menuList: InterfaceMenuData[]
}

const initialState: UserState = {
    loading: false,
    userList: [],
    menuList: []
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers(builder) {

        builder.addCase(getUser.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getUser.fulfilled, (state, action) => {
            state.loading = false
            state.userList = action.payload.data.data
        })
        builder.addCase(getUser.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(getMenu.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getMenu.fulfilled, (state, action) => {
            state.loading = false
            state.menuList = action.payload.data.data
        })
        builder.addCase(getMenu.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(createUser.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createUser.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createUser.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(editUser.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editUser.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editUser.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(deleteUser.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteUser.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteUser.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })
    },
})

export default userSlice.reducer