import { createSlice } from '@reduxjs/toolkit'
import { createSeat, deleteSeat, editSeat, getSeat } from './seat.api'
import { InterfaceSeatData } from './seat.interface'
import alertError from '../../../shared/alert/alertError'

export interface SeatState {
    loading: boolean,
    seatList: InterfaceSeatData[]
}

const initialState: SeatState = {
    loading: false,
    seatList: []
}

export const seatSlice = createSlice({
    name: 'seat',
    initialState,
    reducers: {},
    extraReducers(builder) {

        builder.addCase(getSeat.pending, (state, action) => {
            state.loading = true
            state.seatList = []
        })
        builder.addCase(getSeat.fulfilled, (state, action) => {
            state.loading = false
            state.seatList = action.payload.data.data
        })
        builder.addCase(getSeat.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(createSeat.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createSeat.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createSeat.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(editSeat.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editSeat.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editSeat.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(deleteSeat.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteSeat.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteSeat.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })
    },
})

export default seatSlice.reducer