import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { TypeEmployeeType } from "../data/employee.interface";

export default function EmployeeTypeSelect(props: { value: TypeEmployeeType, onChange: (value: TypeEmployeeType) => void }) {

    const typeList: TypeEmployeeType[] = ["ALL", "STAFF", "SOPIR", "SOPIR_CADANGAN", "KERNET"];

    return (
        <FormControl margin="dense" fullWidth >
            <InputLabel size="small">Tipe</InputLabel>
            <Select
                value={props.value}
                label="Tipe"
                onChange={(e) => props.onChange(e.target.value as TypeEmployeeType)}
            >
                {typeList.map((employeeType) => (
                    <MenuItem key={employeeType} value={employeeType}>{employeeType}</MenuItem>
                ))}
            </Select>
        </FormControl >
    )
}