import { Box, Card, CardContent, Checkbox, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSchedule } from "../../../schedule/data/schedule.api";
import { RootState } from "../../../../store";
import SeatSelector from "../../../seat/components/SeatSelector";
import { InterfaceSeatData } from "../../../seat/data/seat.interface";
import { setBookingInput } from "../../data/booking.reducer";
import { InterfaceBookingInputCustomerList } from "../../data/booking.interface";
import { amber, green, red } from "@mui/material/colors";

export default function InputD() {

    const dispatch = useDispatch<any>()

    const { bookingInput } = useSelector((state: RootState) => state.booking)

    const [customerIdSelected, setCustomerIdSelected] = useState<number | undefined>(undefined)

    useEffect(() => {
        dispatch(getSchedule({}))
        setCustomerIdSelected(bookingInput.customer_list?.[0]?.customer.id)
        // eslint-disable-next-line
    }, [dispatch])

    const handleClickSeat = (seat: InterfaceSeatData) => {
        const customerSelectedList = bookingInput.customer_list?.filter(customer => customer.customer.id === customerIdSelected) || []
        const customerSelectedListModified: InterfaceBookingInputCustomerList[] = []
        if (customerSelectedList.some(customer => customer.seat?.id === seat.id)) {
            for (const i in customerSelectedList) {
                const element = customerSelectedList[i]
                if (element.seat?.id === seat.id) {
                    customerSelectedListModified.push({
                        ...element,
                        seat: undefined
                    })
                }
                else {
                    customerSelectedListModified.push(element)
                }
            }
        }
        else {
            let founded = false
            for (const i in customerSelectedList) {
                const element = customerSelectedList[i]
                if (!founded && element.seat === undefined) {
                    customerSelectedListModified.push({
                        ...element,
                        seat: seat
                    })
                    founded = true
                }
                else {
                    customerSelectedListModified.push(element)
                }
            }
        }
        dispatch(setBookingInput({
            ...bookingInput,
            customer_list: [
                ...bookingInput.customer_list?.filter(customer => customer.customer.id !== customerIdSelected) || [],
                ...customerSelectedListModified
            ]
        }))
    }

    const customerList = bookingInput.customer_list || []
    const customerListUnique = customerList.filter((data, index, self) => self.findIndex(t => t.customer.id === data.customer.id) === index)
    const seatIdChoosedList = customerList.filter(customer => customer.customer.id === customerIdSelected && customer.seat !== undefined).map(customer => customer.seat?.id || 0)
    const seatIdBookedList = customerList.filter(customer => customer.customer.id !== customerIdSelected && customer.seat !== undefined).map(customer => customer.seat?.id || 0)

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <List disablePadding>
                        {customerListUnique
                            .sort((a, b) => a.customer.name.localeCompare(b.customer.name))
                            .map((customer, index) => {
                                const customerSame = customerList.filter((c) => c.customer.id === customer.customer.id)
                                return (
                                    <ListItem key={index} secondaryAction={<Typography>{customerSame.filter(c => c.seat !== undefined).map(c => c.seat?.name).toString()}</Typography>}>
                                        <ListItemIcon>
                                            <Checkbox checked={customer.customer.id === customerIdSelected} onChange={(e) => e.target.checked && setCustomerIdSelected(customer.customer.id)} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={customer.customer.name}
                                            secondary={`${customerSame.length} Kursi`}
                                        />
                                    </ListItem>
                                )
                            })}
                    </List>
                </Grid>
                <Grid item xs={8}>
                    <table>
                        <tbody>
                            <tr>
                                <td><Box height={20} width={20} bgcolor={amber[200]} /></td>
                                <td><Typography>Tersedia</Typography></td>
                            </tr>
                            <tr>
                                <td><Box height={20} width={20} bgcolor={green[200]} /></td>
                                <td><Typography>Terpilih</Typography></td>
                            </tr>
                            <tr>
                                <td><Box height={20} width={20} bgcolor={red[200]} /></td>
                                <td><Typography>Booked</Typography></td>
                            </tr>
                        </tbody>
                    </table>
                    <Card sx={{ marginTop: 1 }}>
                        <CardContent>
                            {bookingInput.schedule?.id && bookingInput.start_date && (<SeatSelector scheduleId={bookingInput.schedule?.id} startDate={bookingInput.start_date} choosedIdList={seatIdChoosedList} bookedList={seatIdBookedList} onClick={handleClickSeat} />)}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}