export interface InterfaceTerminalData {
    id: number,
    ts_created: string,
    code: string,
    name: string,
    city_id: number,
    is_active: boolean,
    city: {
        name: string
    }
}

export interface InterfaceTerminalForm {
    id: number,
    code: string,
    name: string,
    city_id: number,
    is_active: boolean,
}

export interface InterfaceTerminalCreate {
    code: string,
    name: string,
    city_id: number,
    is_active: boolean,
}

export interface InterfaceTerminalEdit {
    id: number,
    code?: string,
    name?: string,
    city_id?: number,
    is_active?: boolean,
}

export const InitialTerminalForm: InterfaceTerminalForm = {
    id: 0,
    code: "",
    name: "",
    city_id: 0,
    is_active: true
}