export interface InterfaceEmployeeCashData {
    id: number,
    ts_created: string,
    employee_id: number,
    account_source_id: number,
    account_id: number,
    value: number,
    is_approved: boolean,
    account_source: {
        name: string
    },
    account: {
        name: string
    },
    employee: {
        name: string
    }
}

export interface InterfaceEmployeeCashForm {
    id: number,
    employee_id: number,
    account_source_id: number,
    account_id: number,
    value: number,
    is_approved: boolean
}

export interface InterfaceEmployeeCashCreate {
    employee_id: number,
    account_source_id: number,
    account_id: number,
    value: number
    is_approved: boolean
}

export interface InterfaceEmployeeCashEdit {
    id: number,
    employee_id: number,
    account_source_id: number,
    account_id: number,
    value: number
    is_approved: boolean
}

export const InitialEmployeeCashForm: InterfaceEmployeeCashForm = {
    id: 0,
    employee_id: 0,
    account_source_id: 0,
    account_id: 0,
    value: 0,
    is_approved: false
}