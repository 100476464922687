import { Box, Tab, Tabs } from "@mui/material";
import { InterfaceItineraryDeliveryData } from "../data/itinerary.interface";
import { useState } from "react";
import DeliveryEdit from "../../delivery/components/DeliveryEdit";

export default function ItineraryCloseFormDelivery(props: { deliveryList: InterfaceItineraryDeliveryData[], onChange: () => void }) {

    const [idSelected, setIdSelected] = useState(props.deliveryList.map(delivery => delivery.delivery.id)[0])

    return (
        <Box>
            <Tabs value={idSelected} onChange={(e, value) => setIdSelected(value)}>
                {props.deliveryList.map(delivery => (
                    <Tab key={delivery.delivery.id} value={delivery.delivery.id} label={delivery.delivery.id} />
                ))}
            </Tabs>
            <DeliveryEdit id={idSelected} onChange={props.onChange} />
        </Box>
    )
}