import { configureStore } from '@reduxjs/toolkit'

import authReducer from './features/auth/data/auth.reducer';

import bookingReducer from './features/booking/data/booking.reducer';
import deliveryReducer from './features/delivery/data/delivery.reducer';

import itineraryReducer from './features/itinerary/data/itinerary.reducer';

import employeeCashReducer from './features/employeeCash/data/employeeCash.reducer';
import salaryReducer from './features/salary/data/salary.reducer';
import reportReducer from './features/report/data/report.reducer';
import flowReducer from './features/flow/data/flow.reducer';

import customerReducer from './features/customer/data/customer.reducer';

import carReducer from './features/car/data/car.reducer';
import carTypeReducer from './features/carType/data/carType.reducer';

import buyReducer from './features/buy/data/buy.reducer';
import supplierReducer from './features/supplier/data/supplier.reducer';

import sparepartUsedReducer from './features/sparepartUsed/data/sparepartUsed.reducer';
import sparepartReducer from './features/sparepart/data/sparepart.reducer';
import sparepartCategoryReducer from './features/sparepartCategory/data/sparepartCategory.reducer';

import settingReducer from './features/setting/data/setting.reducer';
import accountReducer from './features/account/data/account.reducer';
import packageTypeReducer from './features/packageType/data/packageType.reducer';
import scheduleReducer from './features/schedule/data/schedule.reducer';
import seatReducer from './features/seat/data/seat.reducer';
import terminalReducer from './features/terminal/data/terminal.reducer';
import warehouseReducer from './features/warehouse/data/warehouse.reducer';
import cityReducer from './features/city/data/city.reducer';
import provinceReducer from './features/province/data/province.reducer';
import employeeReducer from './features/employee/data/employee.reducer';
import userReducer from './features/user/data/user.reducer';

const authStorage = localStorage.getItem("authState")

export const store = configureStore({
    reducer: {

        auth: authReducer,

        booking: bookingReducer,
        delivery: deliveryReducer,

        itinerary: itineraryReducer,

        employeeCash: employeeCashReducer,
        salary: salaryReducer,
        report: reportReducer,
        flow: flowReducer,

        customer: customerReducer,

        car: carReducer,
        carType: carTypeReducer,

        buy: buyReducer,
        supplier: supplierReducer,

        sparepartUsed: sparepartUsedReducer,
        sparepart: sparepartReducer,
        sparepartCategory: sparepartCategoryReducer,

        setting: settingReducer,
        account: accountReducer,
        packageType: packageTypeReducer,
        schedule: scheduleReducer,
        seat: seatReducer,
        terminal: terminalReducer,
        warehouse: warehouseReducer,
        city: cityReducer,
        province: provinceReducer,
        employee: employeeReducer,
        user: userReducer

    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        }),
    preloadedState: {
        auth: typeof (authStorage) === "string" ? JSON.parse(authStorage) : undefined
    }
})

store.subscribe(() => {
    const serializedState = JSON.stringify(store.getState().auth)
    localStorage.setItem('authState', serializedState)
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch