import { Autocomplete, Box, TextField } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { getCarType } from "../data/carType.api";

export default function CarTypeSelect(props: {
    value: number | null,
    onChange: (value: number | null) => void,
    required?: boolean,
    disabled?: boolean,
    disabledIdList?: number[]
}) {

    const dispatch = useDispatch<any>()

    const { loading, carTypeList } = useSelector((state: RootState) => state.carType)

    useEffect(() => {
        dispatch(getCarType())
    }, [dispatch])

    return (
        <Box>
            {loading
                ? (
                    <TextField fullWidth label="Tipe Armada" />
                )
                : (
                    <Autocomplete
                        fullWidth
                        disabled={props.disabled}
                        options={carTypeList}
                        getOptionLabel={options => options.name}
                        getOptionDisabled={option => (props.disabledIdList || []).includes(option.id)}
                        value={carTypeList.filter(carType => carType.id === props.value)[0]}
                        onChange={(_, value) => props.onChange(value?.id || null)}
                        renderInput={(params) => <TextField {...params} label="Tipe Armada" required={props.required} />}
                    />
                )
            }
        </Box>
    )
}