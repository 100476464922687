import { createSlice } from '@reduxjs/toolkit'
import { createTerminal, deleteTerminal, editTerminal, getTerminal } from './terminal.api'
import { InterfaceTerminalData } from './terminal.interface'
import alertError from '../../../shared/alert/alertError'

export interface TerminalState {
    loading: boolean,
    terminalList: InterfaceTerminalData[]
}

const initialState: TerminalState = {
    loading: false,
    terminalList: []
}

export const terminalSlice = createSlice({
    name: 'terminal',
    initialState,
    reducers: {},
    extraReducers(builder) {

        builder.addCase(getTerminal.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getTerminal.fulfilled, (state, action) => {
            state.loading = false
            state.terminalList = action.payload.data.data
        })
        builder.addCase(getTerminal.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(createTerminal.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createTerminal.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createTerminal.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(editTerminal.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editTerminal.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editTerminal.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(deleteTerminal.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteTerminal.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteTerminal.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })
    },
})

export default terminalSlice.reducer