import { Box, Button, Card, CardContent, Container, Divider, Typography } from "@mui/material";
import { useRef } from "react";
import ReactToPrint from "react-to-print";
import Balance from "../../features/report/components/Balance";

export default function BalancePage() {

    const toPrint = useRef(null)

    return (
        <Container>
            <Card>
                <CardContent>
                    <ReactToPrint
                        trigger={() => {
                            return <Button sx={{ marginTop: 2 }} variant="contained" fullWidth>Print</Button>;
                        }}
                        content={() => toPrint.current}
                    />
                </CardContent>
            </Card>
            <Box ref={toPrint} padding={2}>
                <Typography variant="h3">Neraca</Typography>
                <Divider sx={{ marginY: 3 }} />
                <Balance />
            </Box>
        </Container>
    )
}