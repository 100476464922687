import { Autocomplete, Box, TextField } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { getSparepart } from "../data/sparepart.api";
import { InterfaceSparepartData } from "../data/sparepart.interface";

export default function SparepartSelect(props: {
    value: number | null,
    onChange: (value: number | null, valueDetail: InterfaceSparepartData | null) => void,
    required?: boolean,
    disabled?: boolean,
    disabledIdList?: number[],
    readOnly?: boolean
}) {

    const dispatch = useDispatch<any>()

    const { loading, sparepartList } = useSelector((state: RootState) => state.sparepart)

    useEffect(() => {
        dispatch(getSparepart())
    }, [dispatch])

    return (
        <Box>
            {loading
                ? (
                    <TextField fullWidth label="Sparepart" />
                )
                : (
                    <Autocomplete
                        fullWidth
                        disabled={props.disabled}
                        options={sparepartList}
                        getOptionLabel={options => options.name}
                        getOptionDisabled={option => (props.disabledIdList || []).includes(option.id)}
                        value={sparepartList.filter(sparepart => sparepart.id === props.value)[0]}
                        onChange={(_, value) => props.onChange(value?.id || null, value || null)}
                        readOnly={props.readOnly}
                        renderInput={(params) => <TextField {...params} label="Sparepart" required={props.required} />}
                    />
                )
            }
        </Box>
    )
}