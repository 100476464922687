import { Box, Card, CardContent, CardHeader, Container } from "@mui/material";
import useAccess from "../../hooks/useAccess";
import ItineraryCreate from "../../features/itinerary/components/create/ItineraryCreate";
import ItineraryTableOpen from "../../features/itinerary/components/ItineraryTableOpen";
import alertDelete from "../../shared/alert/alertDelete";
import { useDispatch } from "react-redux";
import { deleteItinerary, getItineraryOpen } from "../../features/itinerary/data/itinerary.api";
import alertSuccess from "../../shared/alert/alertSuccess";
import { useState } from "react";
import CustomModal from "../../shared/CustomModal";
import { ItineraryCloseForm } from "../../features/itinerary/components/itineraryCloseForm";

export default function ItineraryCreatePage() {

    const dispatch = useDispatch<any>()
    const access = useAccess()

    const [countReload, setCountReload] = useState(0)
    const [idSelected, setIdSelected] = useState<number | undefined>(undefined)

    const handleDelete = (id: number) => {
        alertDelete()
            .then(async isConfirmed => {
                if (isConfirmed) {
                    try {
                        await dispatch(deleteItinerary(id)).unwrap()
                        await alertSuccess("Berhasil menghapus data")
                        await dispatch(getItineraryOpen({ skip: 0, take: 5 }))
                    } catch (error) { }
                }
            })
    }

    const handlePrint = (id: number) => {
        window.open("/#/itinerary/print/" + id, "_blank")
    }

    const handleClose = (id: number) => {
        setIdSelected(id)
    }

    return (
        <Container>
            {access("CREATE") && (
                <Card>
                    <CardHeader title="Buat Itinerary" />
                    <CardContent>
                        <ItineraryCreate onSubmit={() => setCountReload(last => last + 1)} />
                    </CardContent>
                </Card>
            )}
            <CustomModal
                open={Boolean(idSelected)}
                title="Tutup Manifest"
                size="lg"
                onClose={() => setIdSelected(undefined)}
                component={<ItineraryCloseForm id={idSelected} onSubmit={() => { setCountReload(last => last + 1); setIdSelected(undefined) }} />}
            />
            <Box marginTop={1}>
                <ItineraryTableOpen countReload={countReload} onPrint={access("PRINT") ? handlePrint : undefined} onDelete={access("DELETE") ? handleDelete : undefined} onClose={access("EDIT") ? handleClose : undefined} />
            </Box>
        </Container>
    )
}