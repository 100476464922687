import { AttachMoney, DirectionsBus, Home, MiscellaneousServices, People, Receipt, ReceiptLong, ShoppingCart, Source } from "@mui/icons-material"

import BookingPage from "./pages/BookingPage"
import DeliveryBookingPage from "./pages/DeliveryPage"

import TicketPage from "./pages/transaction/TicketPage"
import DeliveryPage from "./pages/transaction/DeliveryPage"
import ReceivablePage from "./pages/transaction/ReceivablePage"

import SalesPage from "./pages/SalesPage"

import ItineraryCreatePage from "./pages/itinerary/ItineraryCreatePage"
import ItineraryPage from "./pages/itinerary/ItineraryPage"
import ItineraryReportPage from "./pages/itinerary/ItineraryReportPage"

import EmployeeCashPage from "./pages/finance/EmployeeCashPage"
import Salary from "./pages/finance/SalaryPage"
import JournalPage from "./pages/finance/JournalPage"
import ProfitPage from "./pages/finance/ProfitPage"
import BalancePage from "./pages/finance/BalancePage"
import BigBookPage from "./pages/finance/BigBoxPage"
import FlowInPage from "./pages/finance/FlowInPage"
import FlowOutPage from "./pages/finance/FlowOutPage"
import FlowTransferPage from "./pages/finance/FlowTransferPage"

import CustomerPage from "./pages/CustomerPage"

import CarPage from "./pages/car/CarPage"
import CarTypePage from "./pages/car/CarTypePage"

import BuyPage from "./pages/buy/BuyPage"
import BuyDebtPage from "./pages/buy/BuyDebtPage"
import SupplierPage from "./pages/buy/SupplierPage"

import SparepartUsedPage from "./pages/sparepart/SparepartUsedPage"
import SparepartPage from "./pages/sparepart/SparepartPage"
import SparepartCategoryPage from "./pages/sparepart/SparepartCategoryPage"

import SettingPage from "./pages/master/SettingPage"
import AccountPage from "./pages/master/AccountPage"
import PackageTypePage from "./pages/master/PackageTypePage"
import SchedulePage from "./pages/master/SchedulePage"
import TerminalPage from "./pages/master/TerminalPage"
import WarehousePage from "./pages/master/WarehousePage"
import CityPage from "./pages/master/CityPage"
import ProvincePage from "./pages/master/ProvincePage"
import EmployeePage from "./pages/master/EmployeePage"
import UserPage from "./pages/master/UserPage"

const routeList = [
    {
        name: "Tiket",
        path: "/booking",
        icon: <Home />,
        component: <BookingPage />
    },
    {
        name: "Pengiriman",
        path: "/delivery",
        icon: <Home />,
        component: <DeliveryBookingPage />
    },
    {
        name: "Sales",
        path: "/sales",
        icon: <Receipt />,
        component: <SalesPage />
    },
    {
        name: "Transaksi",
        path: "/transaction",
        icon: <ReceiptLong />,
        child: [
            {
                name: "Tiket",
                path: "/ticket",
                component: <TicketPage />
            },
            {
                name: "Pengiriman",
                path: "/delivery",
                component: <DeliveryPage />
            },
            {
                name: "Piutang",
                path: "/receivable",
                component: <ReceivablePage />
            }
        ]
    },
    {
        name: "Itinerary",
        path: "/itinerary",
        icon: <ReceiptLong />,
        child: [
            {
                name: "Buat Itinerary",
                path: "/create",
                component: <ItineraryCreatePage />
            },
            {
                name: "Daftar Itinerary",
                path: "",
                component: <ItineraryPage />
            },
            {
                name: "Laporan Itinerary",
                path: "/report",
                component: <ItineraryReportPage />
            }
        ]
    },
    {
        name: "Keuangan",
        path: "/finance",
        icon: <AttachMoney />,
        child: [
            {
                name: "Kasbon Karyawan",
                path: "/employee/cash",
                component: <EmployeeCashPage />
            },
            {
                name: "Gaji Karyawan",
                path: "/salary",
                component: <Salary />
            },
            {
                name: "Jurnal",
                path: "/report/journal",
                component: <JournalPage />
            },
            {
                name: "Laba Rugi",
                path: "/report/profit",
                component: <ProfitPage />
            },
            {
                name: "Neraca",
                path: "/report/balance",
                component: <BalancePage />
            },
            {
                name: "Buku Besar",
                path: "/report/bigbook",
                component: <BigBookPage />
            },
            {
                name: "Kas Masuk",
                path: "/flow/in",
                component: <FlowInPage />
            },
            {
                name: "Kas Keluar",
                path: "/flow/out",
                component: <FlowOutPage />
            },
            {
                name: "Kas Transfer",
                path: "/flow/transfer",
                component: <FlowTransferPage />
            },
        ]
    },
    {
        name: "Pelanggan",
        path: "/customer",
        icon: <People />,
        component: <CustomerPage />
    },
    {
        name: "Armada",
        path: "/car",
        icon: <DirectionsBus />,
        child: [
            {
                name: "Daftar Armada",
                path: "",
                component: <CarPage />
            },
            {
                name: "Tipe Armada",
                path: "/type",
                component: <CarTypePage />
            }
        ]
    },
    {
        name: "Pembelian",
        path: "/buy",
        icon: <ShoppingCart />,
        child: [
            {
                name: "Pembelian",
                path: "",
                component: <BuyPage />
            },
            {
                name: "Hutang",
                path: "/debt",
                component: <BuyDebtPage />
            },
            {
                name: "Supplier",
                path: "/supplier",
                component: <SupplierPage />
            }
        ]
    },
    {
        name: "Sparepart",
        path: "/sparepart",
        icon: <MiscellaneousServices />,
        child: [
            {
                name: "Pemakaian",
                path: "/used",
                component: <SparepartUsedPage />
            },
            {
                name: "Daftar Sparepart",
                path: "",
                component: <SparepartPage />
            },
            {
                name: "Kategori",
                path: "/category",
                component: <SparepartCategoryPage />
            }
        ]
    },
    {
        name: "Master",
        path: "/master",
        icon: <Source />,
        child: [
            {
                name: "Pengaturan",
                path: "/setting",
                component: <SettingPage />
            },
            {
                name: "Akun Keuangan",
                path: "/account",
                component: <AccountPage />
            },
            {
                name: "Tipe Paket",
                path: "/package/type",
                component: <PackageTypePage />
            },
            {
                name: "Jadwal",
                path: "/schedule",
                component: <SchedulePage />
            },
            {
                name: "Terminal",
                path: "/terminal",
                component: <TerminalPage />
            },
            {
                name: "Gudang",
                path: "/warehouse",
                component: <WarehousePage />
            },
            {
                name: "Kota",
                path: "/city",
                component: <CityPage />
            },
            {
                name: "Provinsi",
                path: "/province",
                component: <ProvincePage />
            },
            {
                name: "Karyawan",
                path: "/employee",
                component: <EmployeePage />
            },
            {
                name: "User",
                path: "/user",
                component: <UserPage />
            }
        ]
    }
]

export default routeList 