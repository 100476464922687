import { Box, Button, Card, CardContent, Divider, Grid, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import moment from "moment";
import moneyParser from "../../../../shared/moneyParser";
import { FormEvent, Fragment, useEffect, useState } from "react";
import FormCheck from "../../../../shared/form/FormCheck";
import AccountSelect from "../../../account/components/AccountSelect";
import { useSetting } from "../../../../hooks/useSetting";
import FormMoney from "../../../../shared/form/FormMoney";
import { InitialBookingCreatePaid, InterfaceBookingCreate, InterfaceBookingCreatePaid, InterfaceBookingInput } from "../../data/booking.interface";
import { createBooking } from "../../data/booking.api";
import alertSuccess from "../../../../shared/alert/alertSuccess";
import TerminalSelect from "../../../terminal/components/TerminalSelect";
import { setBookingInput } from "../../data/booking.reducer";

export default function InputE(props: { isAllowSubmit: boolean, onSubmit: (id: number) => void }) {

    const dispatch = useDispatch<any>()
    const setting = useSetting()

    const { bookingInput } = useSelector((state: RootState) => state.booking)

    const [isCredit, setIsCredit] = useState<boolean>(false)
    const [paidList, setPaidList] = useState<InterfaceBookingCreatePaid[]>([])

    const customerList = bookingInput.customer_list || []
    const customerListUnique = customerList.filter((data, index, self) => self.findIndex(t => t.customer.id === data.customer.id) === index)

    useEffect(() => {
        const accountBooking = setting.getValue("ACCOUNT_BOOKING")
        if (accountBooking && paidList.length === 0) {
            const value: InterfaceBookingCreatePaid = {
                name: "Pembayaran",
                account_source_id: accountBooking,
                account_id: 0,
                value: 0
            }
            setPaidList([value])
        }
    }, [paidList, setting])

    useEffect(() => {
        const total = bookingInput.customer_list?.reduce((total, customer) => total + ((customer.price || customer.seat?.price || 0) - ((customer.price || customer.seat?.price || 0) * (customer.discount || 0) / 100)), 0) || 0
        if (paidList.length > 0) { // pastikan paid_list tidak kosong
            if (isCredit) { // jika kredit
                if (paidList[0].name !== "Uang Muka") {
                    setPaidList(
                        [
                            ...paidList.map((paid, i) => i === 0 ? { ...paid, name: "Uang Muka", value: 0 } : paid),  // ubah nama uang muka
                            {                                                                                       // tambah akun hutang
                                ...InitialBookingCreatePaid,
                                name: "Hutang",
                                account_source_id: setting.getValue("ACCOUNT_BOOKING"),
                                account_id: setting.getValue("ACCOUNT_LOAN"),
                                value: 0
                            }
                        ]
                    )
                }
                else if (total !== paidList[0].value + paidList[1].value) { // jika total tidak sama dengan uang muka + hutang
                    setPaidList(
                        paidList.map((paid, i) => i === 1 ? { ...paid, value: total - paidList[0].value } : paid), // ubah nilai hutang
                    )
                }
            }
            else { // jika tunai
                if (total !== paidList[0]?.value) { // jika total tidak sama dengan pembayaran
                    setPaidList(
                        paidList
                            .map((paid, i) => i === 0 ? { ...paid, name: "Pembayaran", value: total } : paid) // ubah nama pembayaran dan nilai
                            .filter((_, i) => i === 0) // hapus akun hutang jika ada
                    )
                }
                else {
                    setPaidList(
                        paidList
                            .map((paid, i) => i === 0 ? { ...paid, name: "Pembayaran" } : paid) // ubah nama pembayaran saja
                            .filter((_, i) => i === 0) // hapus akun hutang jika ada
                    )
                }
            }
        }
    }, [isCredit, setting, paidList, bookingInput])

    const handleChangeValues = (name: keyof InterfaceBookingInput, value: any) => {
        dispatch(setBookingInput({
            ...bookingInput,
            [name]: value
        }))
    }

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault()
        if (
            bookingInput.ts_created &&
            bookingInput.schedule?.id &&
            bookingInput.start_date &&
            bookingInput.customer_list
        ) {
            const data: InterfaceBookingCreate = {
                ts_created: bookingInput.ts_created,
                schedule_id: bookingInput.schedule?.id,
                start_date: bookingInput.start_date,
                start_terminal_id: bookingInput.start_terminal?.id,
                finish_terminal_id: bookingInput.finish_terminal?.id,
                customer_list: bookingInput.customer_list.map(customer => {
                    return {
                        customer_id: customer.customer?.id || 0,
                        seat_id: customer.seat?.id || 0,
                        price: customer?.price || customer.seat?.price || 0,
                        discount: customer?.discount || 0
                    }
                }),
                paid_list: paidList.filter(paid => paid.account_id !== 0)
            }
            try {
                await dispatch(createBooking(data))
                    .unwrap()
                    .then((response: any) => {
                        alertSuccess("Berhasil menyimpan data")
                        const data = response.data.data
                        const id = data.id
                        window.open(`/#/booking/print/${id}`, "_blank")
                        props.onSubmit(id)
                    })
            } catch (error) { }
        }
    }

    const total = customerList.reduce((prev, curr) => prev + (curr.price || curr.seat?.price || 0), 0)
    const totalDiscount = customerList.reduce((prev, curr) => prev + ((curr.price || curr.seat?.price || 0) * (curr.discount || 0) / 100), 0)

    return (
        <Box>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <Card>
                        <CardContent>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Tanggal input</TableCell>
                                        <TableCell>:</TableCell>
                                        <TableCell>{moment(bookingInput.ts_created).format("YYYY-MM-DD")}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Tanggal keberangkatan</TableCell>
                                        <TableCell>:</TableCell>
                                        <TableCell>{moment(bookingInput.start_date).format("YYYY-MM-DD")}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Tanggal keberangkatan</TableCell>
                                        <TableCell>:</TableCell>
                                        <TableCell>{moment(bookingInput.start_date).format("YYYY-MM-DD")}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ fontWeight: "bold" }}>Keberangkatan</TableCell>
                                        <TableCell>:</TableCell>
                                        <TableCell>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>Kota</td>
                                                        <td>:</td>
                                                        <td>{bookingInput.start_city?.name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Terminal</td>
                                                        <td>:</td>
                                                        <td>{bookingInput.start_terminal?.name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Jam</td>
                                                        <td>:</td>
                                                        <td>{bookingInput.schedule?.start_time}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ fontWeight: "bold" }}>Tujuan</TableCell>
                                        <TableCell>:</TableCell>
                                        <TableCell>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>Kota</td>
                                                        <td>:</td>
                                                        <td>{bookingInput.finish_city?.name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Terminal</td>
                                                        <td>:</td>
                                                        <td>{bookingInput.finish_terminal?.name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Jam</td>
                                                        <td>:</td>
                                                        <td>{bookingInput.schedule?.finish_time}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{ fontWeight: "bold" }}>Penumpang</TableCell>
                                        <TableCell>:</TableCell>
                                        <TableCell>
                                            {customerListUnique.map(customer => {
                                                const customerSame = customerList.filter((c) => c.customer.id === customer.customer.id)
                                                const total = customerSame.reduce((total, customer) => total + (customer.price || customer.seat?.price || 0), 0)
                                                return (
                                                    <Box key={customer.customer.id}>
                                                        <table>
                                                            <tbody>
                                                                <tr>
                                                                    <td>Nama Penumpang</td>
                                                                    <td>:</td>
                                                                    <td>{customer.customer.name}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Nomor Telepon</td>
                                                                    <td>:</td>
                                                                    <td>{customer.customer.phone}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Email</td>
                                                                    <td>:</td>
                                                                    <td>{customer.customer.email}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Kursi</td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <ul>
                                                                            {customerSame.map(customer => (
                                                                                <li key={customer.seat?.id}>{customer.seat?.name} ({moneyParser(customer.seat?.price || 0)})</li>
                                                                            ))}
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Total</td>
                                                                    <td>:</td>
                                                                    <td>{moneyParser(total)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Diskon</td>
                                                                    <td>:</td>
                                                                    <td>{moneyParser(total * (customer.discount || 0) / 100)} ({customer.discount}%)</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ fontWeight: "bold" }}>Total Akhir</td>
                                                                    <td style={{ fontWeight: "bold" }}>:</td>
                                                                    <td style={{ fontWeight: "bold" }}>{moneyParser(total - (total * (customer.discount || 0) / 100))}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <Divider sx={{ marginBottom: 1 }} />
                                                    </Box>
                                                )
                                            })}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid component={"form"} item xs={6} onSubmit={handleSubmit}>
                    <Card>
                        <CardContent>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Total</TableCell>
                                        <TableCell>{moneyParser(total)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Total Diskon</TableCell>
                                        <TableCell>{moneyParser(totalDiscount)} ({((total - totalDiscount) / total).toFixed(0)}%)</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Total Akhir</TableCell>
                                        <TableCell>{moneyParser(total - totalDiscount)}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </CardContent>
                    </Card>
                    <Card sx={{ marginTop: 1 }}>
                        <CardContent>
                            <Grid container columnSpacing={1}>
                                <Grid item xs={6}>
                                    <AccountSelect label="Akun Sumber" value={setting.getValue("ACCOUNT_BOOKING")} />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormMoney value={total - totalDiscount} />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormCheck label={"Kredit"} name="is_credit" value={isCredit} onChange={(e) => setIsCredit(e.target.value as unknown as boolean)} />
                                </Grid>
                                {paidList.length >= 1 && (
                                    <Fragment>
                                        <Grid item xs={6}>
                                            <AccountSelect disabledHead headId={setting.getValue("ACCOUNT_MAIN_FLOW")} label={paidList[0].name} value={paidList[0].account_id} onChange={(value) => setPaidList(paidList.map((paid, i) => i === 0 ? { ...paid, account_id: value || 0 } : paid))} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormMoney value={paidList[0].value} onChange={(e) => setPaidList(paidList.map((paid, i) => i === 0 ? { ...paid, value: parseInt(e.target.value) } : paid))} disabled={!isCredit} />
                                        </Grid>
                                    </Fragment>
                                )}
                                {paidList.length >= 2 && (
                                    <Fragment>
                                        <Grid item xs={6}>
                                            <AccountSelect required disabledHead label={paidList[1].name} value={paidList[1].account_id} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormMoney value={paidList[1].value} onChange={(e) => setPaidList(paidList.map((paid, i) => i === 0 ? { ...paid, value: parseInt(e.target.value) } : paid))} disabled />
                                        </Grid>
                                    </Fragment>
                                )}
                            </Grid>
                        </CardContent>
                    </Card>
                    <Card sx={{ marginTop: 1 }}>
                        <CardContent>
                            {bookingInput.start_city && (
                                <TerminalSelect city_id={bookingInput.start_city.id} label="Terminal Keberangkatan" value={bookingInput.start_terminal?.id} onChange={(_, valueDetail) => handleChangeValues("start_terminal", (valueDetail || undefined))} />
                            )}
                            {bookingInput.finish_city && (
                                <TerminalSelect city_id={bookingInput.finish_city.id} label="Terminal Tujuan" value={bookingInput.finish_terminal?.id} onChange={(_, valueDetail) => handleChangeValues("finish_terminal", (valueDetail || undefined))} />
                            )}
                        </CardContent>
                    </Card>
                    <Button fullWidth sx={{ marginTop: 1 }} type="submit" disabled={!(props.isAllowSubmit)}>Submit</Button>
                </Grid>
            </Grid>
        </Box>
    )
}