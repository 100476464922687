import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getBookingDetail } from "../data/booking.api";
import { AxiosResponse } from "axios";
import { InterfaceBookingDetailData } from "../data/booking.interface";
import { Fragment, useEffect, useState } from "react";
import moment from "moment";
import moneyParser from "../../../shared/moneyParser";
import logo from "../../../assets/img/logo.png";

export default function BookingPrint() {

    const dispatch = useDispatch<any>()
    const { id } = useParams()

    const [bookingDetail, setBookingDetail] = useState<InterfaceBookingDetailData | null>(null)

    useEffect(() => {
        if (id !== undefined) {
            dispatch(getBookingDetail(parseInt(id)))
                .unwrap()
                .then((response: AxiosResponse) => {
                    if (response.status === 200) {
                        const bookingData: InterfaceBookingDetailData = response.data.data
                        setBookingDetail(bookingData)
                    }
                })
        }
    }, [dispatch, id])

    return (
        <Container maxWidth="xl">
            <table style={{ width: "100%", fontWeight: "bold" }}>
                <tbody>
                    {bookingDetail?.customer_list.map((customer) => (
                        <tr key={customer.id}>
                            <td className="ticket">
                                {bookingDetail.total_paid < bookingDetail.total && (
                                    <div className="watermark">
                                        <span>Belum Dibayar</span>
                                    </div>
                                )}
                                <Divider />
                                <Grid container spacing={1}>
                                    <Grid item xs={5}>
                                        <img src={logo} alt="logo" height={56} />
                                        <Typography fontWeight={"bold"}>
                                            Syarat dan ketentuan
                                        </Typography>
                                        <ul style={{ marginTop: 0 }}>
                                            <li>Penumpang diharap berada di perwakilan 30 menit sebelum waktu keberangkatan</li>
                                            <li>penumpang yang membawa barang berharga agar menjaga dengan baik, kehilangan bukan tanggung jawab BA</li>
                                        </ul>
                                        <Typography fontWeight={"bold"}> PT. Bintang Asri Transindo</Typography>
                                        <Typography fontWeight={"bold"}> Jl. H. Supu Yusuf no.28, Kota Kendari</Typography>
                                        <Typography fontWeight={"bold"}> 0811303177</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography fontWeight={"bold"} variant="h6" marginBottom={1}>
                                            {bookingDetail.start_terminal?.name || '-'}-{bookingDetail.finish_terminal?.name || '-'}
                                        </Typography>
                                        <Typography fontWeight={"bold"} marginBottom={1}>
                                            Nama:
                                            {customer.customer.name}
                                        </Typography>
                                        <Typography fontWeight={"bold"}>
                                            Armada:
                                            {bookingDetail.itinerary?.itinerary.car.name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Typography fontWeight={"bold"} variant="h6">{moment(bookingDetail.start_date).format('dddd, DD MMMM YYYY')}{` `}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography fontWeight={"bold"} variant="h6">{customer.seat.name}</Typography>
                                                <Typography fontWeight={"bold"} variant="h6">Kelas: ...</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography fontWeight={"bold"} marginBottom={1}>
                                                    Nomor Booking {bookingDetail.id}
                                                </Typography>
                                                <Typography fontWeight={"bold"}>
                                                    <Box display={"flex"} flexDirection={"row"}>
                                                        <Box>
                                                            Harga:
                                                        </Box>
                                                        <Box display={"flex"} flexDirection={"column"}>
                                                            {customer.discount !== 0
                                                                ? (
                                                                    <Fragment>
                                                                        <span>
                                                                            <s>{moneyParser(customer.price)}</s>(Disc.{customer.discount}%)
                                                                        </span>
                                                                        <span>{moneyParser(customer.price * ((100 - customer.discount) / 100))}</span>
                                                                    </Fragment>
                                                                )
                                                                : (
                                                                    <span>{moneyParser(customer.price)}</span>
                                                                )
                                                            }
                                                        </Box>
                                                    </Box>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </Container>
    )
}