import { Box, Button, Divider } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSchedule } from "../../../schedule/data/schedule.api";
import { RootState } from "../../../../store";
import { setDeliveryInput } from "../../data/delivery.reducer";
import FormText from "../../../../shared/form/FormText";
import { InterfaceDeliveryInput } from "../../data/delivery.interface";

export default function InputC(props: { onNext: () => void }) {

    const dispatch = useDispatch<any>()

    const { deliveryInput } = useSelector((state: RootState) => state.delivery)

    useEffect(() => {
        dispatch(getSchedule({}))
    }, [dispatch])

    const handleChangeValues = (name: keyof InterfaceDeliveryInput, value: any) => {
        dispatch(setDeliveryInput({
            ...deliveryInput,
            [name]: value
        }))
    }

    return (
        <Box component={"form"} onSubmit={(e) => { e.preventDefault(); props.onNext() }}>
            <FormText label="Pengirim" name="sender_name" value={deliveryInput.sender_name || ''} onChange={(e) => handleChangeValues("sender_name", e.target.value)} required />
            <FormText label="Alamat Pengirim" name="sender_address" value={deliveryInput.sender_address || ''} onChange={(e) => handleChangeValues("sender_address", e.target.value)} required />
            <FormText label="Telepon Pengirim" name="sender_phone" value={deliveryInput.sender_phone || ''} onChange={(e) => handleChangeValues("sender_phone", e.target.value)} required />
            <Divider sx={{ marginY: 2 }} />
            <FormText label="Penerima" name="receiver_name" value={deliveryInput.receiver_name || ''} onChange={(e) => handleChangeValues("receiver_name", e.target.value)} required />
            <FormText label="Alamat Penerima" name="receiver_address" value={deliveryInput.receiver_address || ''} onChange={(e) => handleChangeValues("receiver_address", e.target.value)} required />
            <FormText label="Telepon Penerima" name="receiver_phone" value={deliveryInput.receiver_phone || ''} onChange={(e) => handleChangeValues("receiver_phone", e.target.value)} required />
            <Button sx={{ visibility: "hidden", height: 0, padding: 0 }} type="submit">Next</Button>
        </Box>
    )
}