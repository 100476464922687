import { createSlice } from '@reduxjs/toolkit'
import { createEmployeeCash, deleteEmployeeCash, editEmployeeCash, getEmployeeCash } from './employeeCash.api'
import { InterfaceEmployeeCashData } from './employeeCash.interface'
import alertError from '../../../shared/alert/alertError'

export interface EmployeeCashState {
    loading: boolean,
    employeeCashList: InterfaceEmployeeCashData[],
}

const initialState: EmployeeCashState = {
    loading: false,
    employeeCashList: [],
}

export const employeeCashSlice = createSlice({
    name: 'employeeCash',
    initialState,
    reducers: {},
    extraReducers(builder) {

        builder.addCase(getEmployeeCash.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getEmployeeCash.fulfilled, (state, action) => {
            state.loading = false
            state.employeeCashList = action.payload.data.data
        })
        builder.addCase(getEmployeeCash.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(createEmployeeCash.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createEmployeeCash.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createEmployeeCash.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(editEmployeeCash.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editEmployeeCash.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editEmployeeCash.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(deleteEmployeeCash.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteEmployeeCash.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteEmployeeCash.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })
    },
})

export default employeeCashSlice.reducer