import { Box, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useEffect } from "react";
import CustomTable from "../../../shared/CustomTable";
import { InterfaceSparepartData } from "../data/sparepart.interface";
import { Delete, Edit } from "@mui/icons-material";
import { getSparepart } from "../data/sparepart.api";
import moneyParser from "../../../shared/moneyParser";

export default function SparepartTable(props: {
    onEdit?: (data: InterfaceSparepartData) => void,
    onDelete?: (id: number) => void
}) {

    const dispatch = useDispatch<any>()

    const { sparepartList } = useSelector((state: RootState) => state.sparepart)

    useEffect(() => {
        dispatch(getSparepart())
    }, [dispatch])

    return (
        <Box>
            <CustomTable
                columns={[
                    {
                        name: "Kode",
                        key: "code"
                    },
                    {
                        name: "Nama",
                        key: "name"
                    },
                    {
                        name: "Kategori",
                        key: "category_id",
                        render: (data) => data.category.name
                    },
                    {
                        name: "Harga",
                        key: "price",
                        render: (data) => moneyParser(data.price)
                    },
                    {
                        name: "Merk",
                        key: "brand"
                    },
                    {
                        name: "Tipe",
                        key: "type"
                    },
                    {
                        name: "Aksesoris",
                        key: "acc"
                    },
                    {
                        name: "Gudang",
                        key: "warehouse",
                        render: (data) => data.warehouse.name
                    },
                    {
                        name: "Pemakaian",
                        key: "used_list",
                        render: (data) => data.used_list.reduce((acc, curr) => acc + curr.qty, 0)
                    },
                    {
                        name: "Stok",
                        key: "used_list",
                        render: (data) => 0 - data.used_list.reduce((acc, curr) => acc + curr.qty, 0)
                    }
                ]}
                dataList={sparepartList}
                actionList={(props.onEdit || props.onDelete)
                    ? (data) => (
                        <Box display={"flex"} justifyContent={"center"} gap={1}>
                            {props.onEdit && (<Button startIcon={<Edit />} color="warning" onClick={() => props.onEdit && props.onEdit(data)}>Edit</Button>)}
                            {props.onDelete && (<Button startIcon={<Delete />} color="error" onClick={() => props.onDelete && props.onDelete(data.id)}>Hapus</Button>)}
                        </Box>
                    )
                    : undefined
                }
            />
        </Box>
    )
}