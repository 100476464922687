import { createSlice } from '@reduxjs/toolkit'
import { createProvince, deleteProvince, editProvince, getProvince } from './province.api'
import { InterfaceProvinceData } from './province.interface'
import alertError from '../../../shared/alert/alertError'

export interface ProvinceState {
    loading: boolean,
    provinceList: InterfaceProvinceData[]
}

const initialState: ProvinceState = {
    loading: false,
    provinceList: []
}

export const provinceSlice = createSlice({
    name: 'province',
    initialState,
    reducers: {},
    extraReducers(builder) {

        builder.addCase(getProvince.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getProvince.fulfilled, (state, action) => {
            state.loading = false
            state.provinceList = action.payload.data.data
        })
        builder.addCase(getProvince.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(createProvince.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createProvince.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createProvince.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(editProvince.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editProvince.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editProvince.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(deleteProvince.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteProvince.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteProvince.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })
    },
})

export default provinceSlice.reducer