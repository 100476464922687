import { createSlice } from '@reduxjs/toolkit'
import { InterfaceReportData } from './report.interface'
import alertError from '../../../shared/alert/alertError'
import { getReport } from './report.api'

export interface ReportState {
    loading: boolean,
    reportList: InterfaceReportData[]
}

const initialState: ReportState = {
    loading: false,
    reportList: []
}

export const reportSlice = createSlice({
    name: 'report',
    initialState,
    reducers: {},
    extraReducers(builder) {

        builder.addCase(getReport.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getReport.fulfilled, (state, action) => {
            state.loading = false
            state.reportList = action.payload.data.data
        })
        builder.addCase(getReport.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

    },
})

export default reportSlice.reducer