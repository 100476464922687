import { Box, Button } from "@mui/material";
import { FormEvent } from "react";
import { InitialProvinceForm, InterfaceProvinceForm } from "../data/province.interface";
import FormText from "../../../shared/form/FormText";
import { useDispatch } from "react-redux";
import alertSuccess from "../../../shared/alert/alertSuccess";
import { useFormValues } from "../../../hooks/useFormValues";
import { createProvince, editProvince, getProvince } from "../data/province.api";

export default function ProvinceForm(props: { mode: "ADD" | "EDIT", data?: InterfaceProvinceForm, onSubmit: () => void }) {

    const dispatch = useDispatch<any>()

    const { values, handleChange } = useFormValues<InterfaceProvinceForm>(props.data || InitialProvinceForm)

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault()
        try {
            if (props.mode === "ADD") {
                await dispatch(createProvince(values)).unwrap()
                await alertSuccess("Berhasil menyimpan data")
            }
            else if (props.mode === "EDIT") {
                await dispatch(editProvince(values)).unwrap()
                await alertSuccess("Berhasil mengubah data")
            }
            await dispatch(getProvince())
            props.onSubmit()
        } catch (error) { }
    }

    return (
        <Box component={"form"} onSubmit={handleSubmit}>
            <FormText label="Nama" name="name" value={values.name} onChange={handleChange} />
            <Box marginTop={1} display={"flex"} justifyContent={"flex-end"}>
                <Button type="submit">Simpan</Button>
            </Box>
        </Box>
    )
}