import { createSlice } from '@reduxjs/toolkit'
import { createEmployee, deleteEmployee, editEmployee, getEmployee } from './employee.api'
import { InterfaceEmployeeData } from './employee.interface'
import alertError from '../../../shared/alert/alertError'

export interface EmployeeState {
    loading: boolean,
    employeeList: InterfaceEmployeeData[]
}

const initialState: EmployeeState = {
    loading: false,
    employeeList: []
}

export const employeeSlice = createSlice({
    name: 'employee',
    initialState,
    reducers: {},
    extraReducers(builder) {

        builder.addCase(getEmployee.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getEmployee.fulfilled, (state, action) => {
            state.loading = false
            state.employeeList = action.payload.data.data
        })
        builder.addCase(getEmployee.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(createEmployee.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createEmployee.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createEmployee.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(editEmployee.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editEmployee.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editEmployee.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(deleteEmployee.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteEmployee.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteEmployee.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })
    },
})

export default employeeSlice.reducer