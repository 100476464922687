import { useParams } from "react-router-dom"
import { Box, Grid } from "@mui/material"
import SeatPrint from "../../seat/components/SeatPrint"

export default function SchedulePrint() {

    const { id, startDate } = useParams()

    if (id === undefined || startDate === undefined) {
        return <>Loading</>
    }

    return (
        <Box>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <SeatPrint scheduleId={parseInt(id)} startDate={startDate} />
                </Grid>
            </Grid>
        </Box>
    )
}