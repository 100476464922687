import { Box, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSeat } from "../data/seat.api";
import { RootState } from "../../../store";
import moneyParser from "../../../shared/moneyParser";
import { InitialSeatForm, InterfaceSeatForm } from "../data/seat.interface";
import CustomModal from "../../../shared/CustomModal";
import SeatForm from "./SeatForm";
import { amber } from "@mui/material/colors";

export default function SeatEditor(props: { scheduleId?: number }) {

    const dispatch = useDispatch<any>()
    const theme = useTheme()

    const { seatList } = useSelector((state: RootState) => state.seat)

    const [seatToEdit, setSeatToEdit] = useState<InterfaceSeatForm | undefined>(undefined)
    const [additionalRow, setAdditionalRow] = useState<number>(0)
    const [additionalColumn, setAdditionalColumn] = useState<number>(0)

    useEffect(() => {
        if (props.scheduleId) {
            dispatch(getSeat({ schedule_id: props.scheduleId }))
        }
    }, [dispatch, props.scheduleId])

    const handleSubmit = () => {
        setSeatToEdit(undefined)
        setAdditionalColumn(0)
        setAdditionalRow(0)
        if (props.scheduleId) {
            dispatch(getSeat({ schedule_id: props.scheduleId }))
        }
    }

    const maxX = seatList.map(seat => seat.x).reduce((prev, current) => Math.max(prev, current), 0) + additionalRow
    const maxY = seatList.map(seat => seat.y).reduce((prev, current) => Math.max(prev, current), 0) + additionalColumn

    return (
        <Box>
            <CustomModal
                open={Boolean(seatToEdit)}
                title="Edit Kursi"
                onClose={() => setSeatToEdit(undefined)}
                component={<SeatForm data={seatToEdit} onSubmit={handleSubmit} />}
            />
            <Box display={"flex"} gap={1}>
                {Array.from({ length: maxY }, (_, y) => (
                    <Box key={y} display={"flex"} gap={1} flexDirection={"column"}>
                        {Array.from({ length: maxX }, (_, x) => {
                            const seat = seatList.find(seat => seat.x === x + 1 && seat.y === y + 1)
                            if (seat) {
                                return (
                                    <Box
                                        component={"button"}
                                        border={0}
                                        key={x}
                                        height={100}
                                        width={100}
                                        padding={1}
                                        bgcolor={amber[100]}
                                        sx={{
                                            cursor: "pointer",
                                            ":hover": {
                                                bgcolor: amber[200]
                                            }
                                        }}
                                        onClick={() => setSeatToEdit(seat)}
                                    >
                                        <Typography variant="h6">{seat?.name}</Typography>
                                        <Typography variant="body1">{moneyParser(seat?.price)}</Typography>
                                    </Box>
                                )
                            }
                            return (
                                <Box
                                    component={"button"}
                                    border={0}
                                    key={x}
                                    height={100}
                                    width={100}
                                    padding={1}
                                    bgcolor={theme.palette.background.paper}
                                    sx={{
                                        cursor: "pointer",
                                        ":hover": {
                                            bgcolor: amber[100]
                                        }
                                    }}
                                    onClick={() => setSeatToEdit({
                                        ...InitialSeatForm,
                                        schedule_id: props.scheduleId || 0,
                                        x: x + 1,
                                        y: y + 1
                                    })}
                                >
                                    <Typography variant="h6" color={theme.palette.background.paper}>Tambah Kursi</Typography>
                                </Box>
                            )
                        })}
                        {y === 0 && (
                            <Box
                                component={"button"}
                                border={0}
                                height={100}
                                width={100}
                                padding={1}
                                marginTop={2}
                                borderRadius={"50%"}
                                bgcolor={amber[200]}
                                sx={{
                                    cursor: "pointer",
                                    ":hover": {
                                        bgcolor: amber[300]
                                    }
                                }}
                                onClick={() => setAdditionalRow(additionalRow + 1)}
                            >
                                <Typography variant="h6">Tambah Baris</Typography>
                            </Box>
                        )}
                    </Box>
                ))}
                <Box
                    component={"button"}
                    border={0}
                    height={100}
                    width={100}
                    padding={1}
                    marginLeft={2}
                    borderRadius={"50%"}
                    bgcolor={amber[200]}
                    sx={{
                        cursor: "pointer",
                        ":hover": {
                            bgcolor: amber[300]
                        }
                    }}
                    onClick={() => setAdditionalColumn(additionalColumn + 1)}
                >
                    <Typography variant="h6">Tambah Kolom</Typography>
                </Box>
            </Box>
        </Box>
    )
}