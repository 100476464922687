export default function moneyParser(value: number) {
    var valueStr = String(Math.round(value)).replace(/[^0-9.]/g, '')
    var valueArr = valueStr.split('')
    var valueArrReverse = valueArr.reverse()
    var valueArrReverseNew: any = []
    valueArrReverse.map((val, index) => {
        valueArrReverseNew.push(val)
        if (index % 3 === 2) {
            if (index !== valueArrReverse.length - 1) valueArrReverseNew.push('.')
        }
        return null
    })
    return `${value < 0 ? '-' : ''}${String(valueArrReverseNew.reverse().join(''))}`
}