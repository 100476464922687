import { Box, Card, CardContent, IconButton, Tooltip, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { TreeItem, TreeView } from "@mui/x-tree-view";
import { Add, ChevronRight, Delete, Edit, ExpandMore } from "@mui/icons-material";
import { InterfaceSparepartCategoryData } from "../data/sparepartCategory.interface";
import { useEffect } from "react";
import { getSparepartCategory } from "../data/sparepartCategory.api";

export default function SparepartCategoryTree(props: {
    onAdd?: (data: InterfaceSparepartCategoryData) => void,
    onEdit?: (data: InterfaceSparepartCategoryData) => void,
    onDelete?: (id: number) => void
}) {

    const dispatch = useDispatch<any>()

    const { sparepartCategoryList } = useSelector((state: RootState) => state.sparepartCategory)

    useEffect(() => {
        dispatch(getSparepartCategory())
    }, [dispatch])

    const renderItem = (parrent: null | number) => {
        return sparepartCategoryList.filter(sparepartCategory => sparepartCategory.parrent_id === parrent).map(sparepartCategory => (
            <TreeItem
                key={sparepartCategory.id}
                nodeId={String(sparepartCategory.id)}
                label={
                    <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                        <Typography variant="body1">
                            {sparepartCategory.name}
                        </Typography>
                        <Box>
                            {props.onAdd && (
                                <Tooltip title="Tambah Subkategori">
                                    <IconButton onClick={() => props.onAdd && props.onAdd(sparepartCategory)}>
                                        <Add />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {props.onEdit && (
                                <Tooltip title="Edit">
                                    <IconButton onClick={() => props.onEdit && props.onEdit(sparepartCategory)}>
                                        <Edit />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {props.onDelete && (
                                <Tooltip title="Hapus">
                                    <IconButton onClick={() => props.onDelete && props.onDelete(sparepartCategory.id)}>
                                        <Delete />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Box>
                    </Box>
                }
            >
                {renderItem(sparepartCategory.id)}
            </TreeItem>
        ))
    }

    return (
        <Card>
            <CardContent>
                <TreeView
                    aria-label="file system navigator"
                    defaultCollapseIcon={<ExpandMore />}
                    defaultExpandIcon={<ChevronRight />}
                >
                    {renderItem(null)}
                </TreeView>
            </CardContent>
        </Card>
    )
}