import { Box, Card, CardContent, CardHeader, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAccount } from "../../account/data/account.api";
import { RootState } from "../../../store";
import moneyParser from "../../../shared/moneyParser";
import moment from "moment";
import { getReport } from "../data/report.api";

export default function BigBook(props: {
    date_start: string,
    date_end: string
}) {

    const dispatch = useDispatch<any>();

    const { accountList } = useSelector((state: RootState) => state.account)
    const { reportList } = useSelector((state: RootState) => state.report)

    useEffect(() => {
        dispatch(getAccount())
        dispatch(getReport())
    }, [dispatch])

    const momentDateStart = moment(props.date_start)
    const momentDateEnd = moment(props.date_end)

    return (
        <Box display={"flex"} flexDirection={"column"} gap={1}>
            {accountList
                .filter(account => !account.is_head)
                .map(account => {
                    const transactionBefore = reportList.filter(report => moment(report.ts_created).isBefore(momentDateStart))
                    const creditBegin = transactionBefore.filter(report => report.account_source.id === account.id).reduce((total, r) => total + r.value, 0)
                    const debetBegin = transactionBefore.filter(report => report.account.id === account.id).reduce((total, r) => total + r.value, 0)
                    const reportFiltered = reportList.filter(report => {
                        return (
                            (
                                report.account_source.id === account.id ||
                                report.account.id === account.id
                            ) &&
                            moment(report.ts_created).isBetween(momentDateStart, momentDateEnd)
                        )
                    })
                    return (
                        <Card key={account.id} >
                            <CardHeader title={account.name} subheader={account.code} />
                            <CardContent>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell colSpan={4}>Saldo Awal</TableCell>
                                            <TableCell align="right">{moneyParser(debetBegin - creditBegin)}</TableCell>
                                        </TableRow>
                                        {reportFiltered
                                            .map((report, reportIndex) => {
                                                const credit = report.account_source.id === account.id ? report.value : 0
                                                const debet = report.account.id === account.id ? report.value : 0
                                                const totalCredit = reportFiltered.filter((report, i) => i <= reportIndex && report.account_source.id === account.id).reduce((total, r) => total + r.value, 0)
                                                const totalDebet = reportFiltered.filter((report, i) => i <= reportIndex && report.account.id === account.id).reduce((total, r) => total + r.value, 0)
                                                return (
                                                    <TableRow key={reportIndex}>
                                                        <TableCell>{moment(report.ts_created).format("YYYY-MM-DD HH:mm:ss")}</TableCell>
                                                        <TableCell>{report.name}</TableCell>
                                                        <TableCell align="right">{moneyParser(credit)}</TableCell>
                                                        <TableCell align="right">{moneyParser(debet)}</TableCell>
                                                        <TableCell align="right">{moneyParser((debetBegin - creditBegin) + (totalDebet - totalCredit))}</TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                        <TableRow>
                                            <TableCell colSpan={2}>Total</TableCell>
                                            <TableCell align="right">{moneyParser(reportFiltered.filter(report => report.account_source.id === account.id).reduce((total, r) => total + r.value, 0))}</TableCell>
                                            <TableCell align="right">{moneyParser(reportFiltered.filter(report => report.account.id === account.id).reduce((total, r) => total + r.value, 0))}</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </CardContent>
                        </Card>
                    )
                })}
        </Box>
    )
}