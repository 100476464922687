import { Box } from "@mui/material";
import FormDate from "../../../../shared/form/FormDate";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { setBookingInput } from "../../data/booking.reducer";
import { InterfaceBookingInput } from "../../data/booking.interface";
import CitySelect from "../../../city/components/CitySelect";
import TerminalSelect from "../../../terminal/components/TerminalSelect";
import alertError from "../../../../shared/alert/alertError";

export default function InputA() {

    const dispatch = useDispatch<any>()

    const { bookingInput } = useSelector((state: RootState) => state.booking)

    const handleChangeValues = (name: keyof InterfaceBookingInput, value: any) => {
        let dataToUpdate: InterfaceBookingInput = {}
        if (name === "start_city") {
            if (value.id === bookingInput.finish_city?.id) {
                alertError("Kota keberangkatan tidak boleh sama dengan kota tujuan")
                return
            }
            dataToUpdate = {
                start_terminal: undefined
            }
        }
        if (name === "finish_city") {
            if (value.id === bookingInput.start_city?.id) {
                alertError("Kota tujuan tidak boleh sama dengan kota keberangkatan")
                return
            }
            dataToUpdate = {
                finish_terminal: undefined
            }
        }
        if (
            bookingInput.schedule?.start_city.id !== bookingInput.start_city?.id &&
            bookingInput.schedule?.finish_city.id !== bookingInput.finish_city?.id
        ) {
            dataToUpdate = {
                schedule: undefined
            }
        }
        dispatch(setBookingInput({
            ...bookingInput,
            ...dataToUpdate,
            [name]: value
        }))
    }

    return (
        <Box>
            <FormDate label="Tanggal Input" name="ts_created" value={bookingInput.ts_created?.split(" ")[0]} onChange={e => handleChangeValues("ts_created", e.target.value)} />
            <FormDate label="Tanggal Keberangkatan" name="start_date" value={bookingInput.start_date} onChange={e => handleChangeValues("start_date", e.target.value)} />
            <CitySelect terminalOnly label="Kota Keberangkatan" value={bookingInput.start_city?.id} onChange={(_, valueDetail) => handleChangeValues("start_city", (valueDetail || undefined))} />
            {bookingInput.start_city && (
                <TerminalSelect city_id={bookingInput.start_city.id} label="Terminal Keberangkatan" value={bookingInput.start_terminal?.id} onChange={(_, valueDetail) => handleChangeValues("start_terminal", (valueDetail || undefined))} />
            )}
            <CitySelect terminalOnly label="Kota Tujuan" value={bookingInput.finish_city?.id} onChange={(_, valueDetail) => handleChangeValues("finish_city", (valueDetail || undefined))} />
            {bookingInput.finish_city && (
                <TerminalSelect city_id={bookingInput.finish_city.id} label="Terminal Tujuan" value={bookingInput.finish_terminal?.id} onChange={(_, valueDetail) => handleChangeValues("finish_terminal", (valueDetail || undefined))} />
            )}
        </Box>
    )
}