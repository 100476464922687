import { Box, Button, Typography, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useEffect } from "react";
import CustomTable from "../../../shared/CustomTable";
import { InterfaceEmployeeCashData } from "../data/employeeCash.interface";
import { Delete, Edit } from "@mui/icons-material";
import moneyParser from "../../../shared/moneyParser";
import { getEmployeeCash } from "../data/employeeCash.api";

export default function EmployeeCashTable(props: {
    onEdit?: (data: InterfaceEmployeeCashData) => void,
    onDelete?: (id: number) => void
}) {

    const dispatch = useDispatch<any>()
    const theme = useTheme()

    const { employeeCashList } = useSelector((state: RootState) => state.employeeCash)

    useEffect(() => {
        dispatch(getEmployeeCash())
    }, [dispatch])

    return (
        <Box>
            <CustomTable
                columns={[
                    {
                        name: "Pegawai",
                        key: "employee_id",
                        render: (data) => data.employee.name
                    },
                    {
                        name: "Akun Sumber",
                        key: "account_source_id",
                        render: (data) => data.account_source.name
                    },
                    {
                        name: "Nominal",
                        key: "value",
                        render: (data) => moneyParser(data.value)
                    },
                    {
                        name: "Disetujui",
                        key: "is_approved",
                        render: (data) => data.is_approved
                            ? <Typography color={theme.palette.success.main}>Ya</Typography>
                            : <Typography color={theme.palette.error.main}>Tidak</Typography>
                    }
                ]}
                dataList={employeeCashList}
                actionList={(props.onEdit || props.onDelete)
                    ? (data) => (
                        <Box display={"flex"} justifyContent={"center"} gap={1}>
                            {props.onEdit && (<Button startIcon={<Edit />} color="warning" onClick={() => props.onEdit && props.onEdit(data)}>Edit</Button>)}
                            {props.onDelete && (<Button startIcon={<Delete />} color="error" onClick={() => props.onDelete && props.onDelete(data.id)}>Hapus</Button>)}
                        </Box>
                    )
                    : undefined
                }
            />
        </Box>
    )
}