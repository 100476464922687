import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSchedule } from "../../../schedule/data/schedule.api";
import { RootState } from "../../../../store";
import { setItineraryInput } from "../../data/itinerary.reducer";

export default function ItineraryCreateB() {

    const dispatch = useDispatch<any>()

    const { itineraryInput } = useSelector((state: RootState) => state.itinerary)
    const { scheduleList } = useSelector((state: RootState) => state.schedule)

    useEffect(() => {
        dispatch(getSchedule({}))
    }, [dispatch])

    return (
        <Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Kota Keberangkatan</TableCell>
                        <TableCell>Kota Tujuan</TableCell>
                        <TableCell>Jam Keberangkatan</TableCell>
                        <TableCell>Jam Tiba</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {scheduleList
                        .map((schedule) => (
                            <TableRow key={schedule.id}>
                                <TableCell>{schedule.start_city.name}</TableCell>
                                <TableCell>{schedule.finish_city.name}</TableCell>
                                <TableCell>{schedule.start_time}</TableCell>
                                <TableCell>{schedule.finish_time}</TableCell>
                                <TableCell>
                                    <Button variant="contained" color="primary" disabled={schedule.id === itineraryInput.schedule?.id} onClick={() => dispatch(setItineraryInput({ ...itineraryInput, schedule: schedule }))}>Pilih</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </Box>
    )
}