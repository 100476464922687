import { Container, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import alertDelete from "../../shared/alert/alertDelete";
import { deleteItinerary, getItinerary } from "../../features/itinerary/data/itinerary.api";
import alertSuccess from "../../shared/alert/alertSuccess";
import useAccess from "../../hooks/useAccess";
import ItineraryTableAll from "../../features/itinerary/components/ItineraryTableAll";

export default function ItineraryPage() {

    const dispatch = useDispatch<any>()
    const access = useAccess()

    const handleDelete = (id: number) => {
        alertDelete()
            .then(async isConfirmed => {
                if (isConfirmed) {
                    try {
                        await dispatch(deleteItinerary(id)).unwrap()
                        await alertSuccess("Berhasil menghapus data")
                        await dispatch(getItinerary({ skip: 0, take: 5 }))
                    } catch (error) { }
                }
            })
    }

    const handlePrint = (id: number) => {
        window.open("/#/itinerary/print/" + id, "_blank")
    }

    return (
        <Container>
            <Typography variant="h3">Itinerary</Typography>
            <br />
            <ItineraryTableAll onPrint={access("PRINT") ? handlePrint : undefined} onDelete={access("DELETE") ? handleDelete : undefined} />
        </Container>
    )
}