import { createSlice } from '@reduxjs/toolkit'
import { createWarehouse, deleteWarehouse, editWarehouse, getWarehouse } from './warehouse.api'
import { InterfaceWarehouseData } from './warehouse.interface'
import alertError from '../../../shared/alert/alertError'

export interface WarehouseState {
    loading: boolean,
    warehouseList: InterfaceWarehouseData[]
}

const initialState: WarehouseState = {
    loading: false,
    warehouseList: []
}

export const warehouseSlice = createSlice({
    name: 'warehouse',
    initialState,
    reducers: {},
    extraReducers(builder) {

        builder.addCase(getWarehouse.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getWarehouse.fulfilled, (state, action) => {
            state.loading = false
            state.warehouseList = action.payload.data.data
        })
        builder.addCase(getWarehouse.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(createWarehouse.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createWarehouse.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createWarehouse.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(editWarehouse.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editWarehouse.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editWarehouse.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(deleteWarehouse.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteWarehouse.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteWarehouse.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })
    },
})

export default warehouseSlice.reducer