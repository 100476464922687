import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSetting } from "../features/setting/data/setting.api";
import { RootState } from "../store";
import { TypeSettingCode } from "../features/setting/data/setting.interface";

export const useSetting = () => {

    const dispatch = useDispatch<any>()

    const { settingList } = useSelector((state: RootState) => state.setting)

    useEffect(() => {
        dispatch(getSetting())
    }, [dispatch])

    const getValue = (code: TypeSettingCode): number => {
        const result = settingList.find(setting => setting.code === code)?.value
        return result as number
    }

    return { getValue }
}