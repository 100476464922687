import { Autocomplete, Box, TextField } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { getCity } from "../data/city.api";
import { InterfaceCityData } from "../data/city.interface";

export default function CitySelect(props: {
    label?: string,
    value: number | null | undefined,
    onChange: (value: number | null, valueDetail: InterfaceCityData | null) => void,
    required?: boolean,
    disabled?: boolean,
    disabledIdList?: number[],
    terminalOnly?: boolean
}) {

    const dispatch = useDispatch<any>()

    const { loading, cityList } = useSelector((state: RootState) => state.city)

    useEffect(() => {
        dispatch(getCity({
            terminalOnly: props.terminalOnly
        }))
    }, [dispatch, props.terminalOnly])

    return (
        <Box>
            {loading
                ? (
                    <TextField fullWidth label={props.label || "Kota"} />
                )
                : (
                    <Autocomplete
                        fullWidth
                        disabled={props.disabled}
                        options={cityList}
                        getOptionLabel={options => options.name}
                        getOptionDisabled={option => (props.disabledIdList || []).includes(option.id)}
                        value={cityList.filter(city => city.id === props.value)[0]}
                        onChange={(_, value) => props.onChange(value?.id || null, value || null)}
                        renderInput={(params) => <TextField {...params} label={props.label || "Kota"} required={props.required} />}
                    />
                )
            }
        </Box>
    )
}