export interface InterfaceSupplierData {
    id: number,
    ts_created: string,
    code: string,
    name: string,
    phone: string,
    address: string,
    city_id: number,
    city: {
        name: string,
    }
}

export interface InterfaceSupplierForm {
    id: number,
    code: string,
    name: string,
    phone: string,
    address: string,
    city_id: number
}

export interface InterfaceSupplierCreate {
    code: string,
    name: string,
    phone: string,
    address: string,
    city_id: number
}

export interface InterfaceSupplierEdit {
    id: number,
    code: string,
    name: string,
    phone: string,
    address: string,
    city_id: number
}

export const InitialSupplierForm: InterfaceSupplierForm = {
    id: 0,
    code: "",
    name: "",
    phone: "",
    address: "",
    city_id: 0
}