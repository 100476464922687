import { Box, Button } from "@mui/material";
import { FormEvent, useEffect } from "react";
import { InitialCustomerForm, InterfaceCustomerForm } from "../data/customer.interface";
import FormText from "../../../shared/form/FormText";
import { useDispatch, useSelector } from "react-redux";
import alertSuccess from "../../../shared/alert/alertSuccess";
import { useFormValues } from "../../../hooks/useFormValues";
import { createCustomer, editCustomer, getCustomer } from "../data/customer.api";
import CustomerGenderSelect from "./CustomerGenderSelect";
import { RootState } from "../../../store";

export default function CustomerForm(props: { mode: "ADD" | "EDIT", data?: InterfaceCustomerForm, onSubmit: () => void }) {

    const dispatch = useDispatch<any>()

    const { skip, take, search } = useSelector((state: RootState) => state.customer)

    const { values, handleChange, handleChangeValues } = useFormValues<InterfaceCustomerForm>(props.data || InitialCustomerForm)

    useEffect(() => {
        handleChangeValues("name", search)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search])

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault()
        try {
            if (props.mode === "ADD") {
                await dispatch(createCustomer(values)).unwrap()
                await alertSuccess("Berhasil menyimpan data")
            }
            else if (props.mode === "EDIT") {
                await dispatch(editCustomer(values)).unwrap()
                await alertSuccess("Berhasil mengubah data")
            }
            await dispatch(getCustomer({ skip, take, search }))
            props.onSubmit()
        } catch (error) { }
    }

    return (
        <Box component={"form"} onSubmit={handleSubmit}>
            <FormText focused={search === ''} label="Nama" name="name" value={values.name} onChange={handleChange} />
            <CustomerGenderSelect value={values.gender} onChange={value => handleChangeValues("gender", value)} />
            <FormText focused={search !== ''} label="Nomor Telepon" name="phone" value={values.phone} onChange={handleChange} required />
            <FormText label="Email" name="email" value={values.email} onChange={handleChange} />
            <Box marginTop={1} display={"flex"} justifyContent={"flex-end"}>
                <Button type="submit">Simpan</Button>
            </Box>
        </Box>
    )
}