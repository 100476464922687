import { createSlice } from '@reduxjs/toolkit'
import { createSalary, deleteSalary, editSalary, getCash, getCommision, getSalary } from './salary.api'
import { InterfaceCashData, InterfaceCommisionData, InterfaceSalaryData } from './salary.interface'
import alertError from '../../../shared/alert/alertError'

export interface SalaryState {
    loading: boolean,
    salaryList: InterfaceSalaryData[],
    commisionList: InterfaceCommisionData[],
    cashList: InterfaceCashData[]
}

const initialState: SalaryState = {
    loading: false,
    salaryList: [],
    commisionList: [],
    cashList: []
}

export const salarySlice = createSlice({
    name: 'salary',
    initialState,
    reducers: {},
    extraReducers(builder) {

        builder.addCase(getSalary.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getSalary.fulfilled, (state, action) => {
            state.loading = false
            state.salaryList = action.payload.data.data
        })
        builder.addCase(getSalary.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(getCommision.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getCommision.fulfilled, (state, action) => {
            state.loading = false
            state.commisionList = action.payload.data.data
        })
        builder.addCase(getCommision.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(getCash.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getCash.fulfilled, (state, action) => {
            state.loading = false
            state.cashList = action.payload.data.data
        })
        builder.addCase(getCash.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(createSalary.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createSalary.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createSalary.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(editSalary.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editSalary.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editSalary.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(deleteSalary.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteSalary.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteSalary.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })
    },
})

export default salarySlice.reducer