import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../../state";
import { InterfaceSparepartCategoryCreate, InterfaceSparepartCategoryEdit } from "./sparepartCategory.interface";
import { RootState } from "../../../store";

export const getSparepartCategory = createAsyncThunk('getSparepartCategory', async (_, thunkAPI) => {
    try {
        const response = await axios({
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + (thunkAPI.getState() as RootState).auth.loginData.key
            },
            url: baseUrl + '/sparepart/category'
        })
        return response
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error)
    }
})

export const createSparepartCategory = createAsyncThunk('createSparepartCategory', async (data: InterfaceSparepartCategoryCreate, thunkAPI) => {
    try {
        const response = await axios({
            method: 'post',
            headers: {
                'Authorization': 'Bearer ' + (thunkAPI.getState() as RootState).auth.loginData.key
            },
            url: baseUrl + '/sparepart/category',
            data: data
        })
        return response
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error)
    }
})

export const editSparepartCategory = createAsyncThunk('editSparepartCategory', async (data: InterfaceSparepartCategoryEdit, thunkAPI) => {
    try {
        const response = await axios({
            method: 'put',
            headers: {
                'Authorization': 'Bearer ' + (thunkAPI.getState() as RootState).auth.loginData.key
            },
            url: baseUrl + '/sparepart/category',
            data: data
        })
        return response
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error)
    }
})

export const deleteSparepartCategory = createAsyncThunk('deleteSparepartCategory', async (id: number, thunkAPI) => {
    try {
        const response = await axios({
            method: 'delete',
            headers: {
                'Authorization': 'Bearer ' + (thunkAPI.getState() as RootState).auth.loginData.key
            },
            url: baseUrl + '/sparepart/category',
            params: {
                id: id
            }
        })
        return response
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error)
    }
})