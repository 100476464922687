import { Autocomplete, Box, TextField } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { getTerminal } from "../data/terminal.api";
import { InterfaceTerminalData } from "../data/terminal.interface";

export default function TerminalSelect(props: {
    label?: string,
    value: number | null | undefined,
    onChange: (value: number | null, valueDetail: InterfaceTerminalData | null) => void,
    required?: boolean,
    disabled?: boolean,
    city_id: number
}) {

    const dispatch = useDispatch<any>()

    const { loading, terminalList } = useSelector((state: RootState) => state.terminal)

    useEffect(() => {
        dispatch(getTerminal({}))
    }, [dispatch, props.city_id])

    return (
        <Box>
            {loading
                ? (
                    <TextField fullWidth label={props.label || "Terminal"} />
                )
                : (
                    <Autocomplete
                        fullWidth
                        disabled={props.disabled}
                        options={terminalList.filter(terminal => terminal.city_id === props.city_id)}
                        getOptionLabel={options => options.name}
                        getOptionDisabled={option => option.is_active === false}
                        value={terminalList.filter(terminal => terminal.id === props.value)[0]}
                        onChange={(_, value) => props.onChange(value?.id || null, value || null)}
                        renderInput={(params) => <TextField {...params} label={props.label || "Terminal"} required={props.required} />}
                    />
                )
            }
        </Box>
    )
}