import { createSlice } from '@reduxjs/toolkit'
import { createCar, deleteCar, editCar, getCar } from './car.api'
import { InterfaceCarData } from './car.interface'
import alertError from '../../../shared/alert/alertError'

export interface CarState {
    loading: boolean,
    carList: InterfaceCarData[]
}

const initialState: CarState = {
    loading: false,
    carList: []
}

export const carSlice = createSlice({
    name: 'car',
    initialState,
    reducers: {},
    extraReducers(builder) {

        builder.addCase(getCar.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getCar.fulfilled, (state, action) => {
            state.loading = false
            state.carList = action.payload.data.data
        })
        builder.addCase(getCar.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(createCar.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createCar.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createCar.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(editCar.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editCar.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editCar.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(deleteCar.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteCar.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteCar.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })
    },
})

export default carSlice.reducer