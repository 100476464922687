import { Box, Toolbar } from "@mui/material";
import { ReactNode } from "react";

export default function Content(props: { component: ReactNode }) {
    return (
        <Box flexGrow={1}>
            <Toolbar />
            {props.component}
        </Box>
    )
}