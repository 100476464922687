import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { TypeDay } from "../data/schedule.interface";

export default function DaySelect(props: { value: TypeDay[], onChange: (value: TypeDay[]) => void }) {

    const typeList: TypeDay[] = ["Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu", "Minggu"];

    return (
        <FormControl margin="dense" fullWidth >
            <InputLabel size="small">Hari</InputLabel>
            <Select
                multiple
                value={props.value}
                label="Hari"
                onChange={(e) => props.onChange(e.target.value as TypeDay[])}
            >
                {typeList.map((employeeType) => (
                    <MenuItem key={employeeType} value={employeeType}>{employeeType}</MenuItem>
                ))}
            </Select>
        </FormControl >
    )
}