export interface InterfaceCityData {
    id: number,
    ts_created: string,
    name: string,
    province_id: number,
    province: {
        name: string
    }
}

export interface InterfaceCityForm {
    id: number,
    name: string,
    province_id: number
}

export interface InterfaceCityCreate {
    name: string,
    province_id: number
}

export interface InterfaceCityEdit {
    id: number,
    name?: string,
    province_id?: number
}

export const InitialCityForm: InterfaceCityForm = {
    id: 0,
    name: "",
    province_id: 0
}