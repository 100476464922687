import "./assets/style.scss"
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { CssBaseline } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from './store';
import Layout from './containers/components/Layout';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { Fragment, ReactNode, useEffect } from 'react';
import routeList from './routeList';
import routeListPrint from "./routeListPrint";

import Login from "./features/auth/components/Login";
import { checkLogin } from "./features/auth/data/auth.api";
import Loading from "./containers/components/Loading";

function App() {

  const navigate = useNavigate()
  const dispatch = useDispatch<any>()

  const loginData = useSelector((state: RootState) => state.auth.loginData)

  useEffect(() => {
    if (loginData.id) {
      dispatch(checkLogin())
    }
    else {
      navigate('/login')
    }
  }, [dispatch, loginData.id, navigate])

  const allowUrlList = loginData.access.map((access) => access.url)
  let pathAndComponentList: { path: string, component: ReactNode }[] = []
  routeList.map(route => {
    if (route.child) {
      route.child.map(child => {
        if (allowUrlList.includes(route.path + child.path)) {
          pathAndComponentList.push({ path: route.path + child.path, component: child.component })
        }
        return null
      })
    }
    else if (route.path) {
      if (allowUrlList.includes(route.path)) {
        pathAndComponentList.push({ path: route.path, component: route.component })
      }
    }
    return null
  })

  return (
    <Fragment>
      <CssBaseline />
      <Loading />
      <Routes>
        {pathAndComponentList.map((item: any, i: number) => (
          <Route key={i} path={item.path} element={<Layout component={item.component} />} />
        ))}
        {routeListPrint.map((item, i) => (
          <Route key={i + "print"} path={item.path} element={item.component} />
        ))}
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<Navigate to={pathAndComponentList[0]?.path || "/login"} />} />
      </Routes>
    </Fragment>
  )
}

export default App;
