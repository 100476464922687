import Swal from "sweetalert2";

export default function alertSuccess(msg: any) {
    return new Promise(resolve => {
        Swal.fire({
            icon: "success",
            title: "Success",
            text: msg
        })
            .then(result => {
                resolve(result)
            })
    })
}