import { createSlice } from '@reduxjs/toolkit'
import { createBuy, createBuyPaid, deleteBuy, editBuy, getBuy, payBuy } from './buy.api'
import { InterfaceBuyData } from './buy.interface'
import alertError from '../../../shared/alert/alertError'

export interface BuyState {
    loading: boolean,
    buyList: InterfaceBuyData[]
}

const initialState: BuyState = {
    loading: false,
    buyList: []
}

export const buySlice = createSlice({
    name: 'buy',
    initialState,
    reducers: {},
    extraReducers(builder) {

        builder.addCase(getBuy.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getBuy.fulfilled, (state, action) => {
            state.loading = false
            state.buyList = action.payload.data.data
        })
        builder.addCase(getBuy.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(createBuy.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createBuy.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createBuy.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(editBuy.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editBuy.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editBuy.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(deleteBuy.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteBuy.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteBuy.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(payBuy.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(payBuy.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(payBuy.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(createBuyPaid.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createBuyPaid.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createBuyPaid.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })
    },
})

export default buySlice.reducer