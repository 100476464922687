import Swal from "sweetalert2";

export default function alertError(error: any): Promise<boolean> {
    return new Promise(resolve => {
        Swal.fire({
            icon: "error",
            title: "Error",
            text: error?.response?.data?.message || error.message || error
        })
            .then(result => {
                resolve(result.isConfirmed)
            })
    })
}