import { Box, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useEffect } from "react";
import CustomTable from "../../../shared/CustomTable";
import { InterfaceCustomerData } from "../data/customer.interface";
import { Add, Delete, Edit } from "@mui/icons-material";
import { getCustomer } from "../data/customer.api";
import { setSearch, setSkip, setTake } from "../data/customer.reducer";

export default function CustomerTable(props: {
    onEdit?: (data: InterfaceCustomerData) => void,
    onSelect?: (data: InterfaceCustomerData) => void,
    onDelete?: (id: number) => void,
    onAdd?: () => void
}) {

    const dispatch = useDispatch<any>()

    const { customerList, customerListTotal, skip, take, search } = useSelector((state: RootState) => state.customer)

    useEffect(() => {
        dispatch(getCustomer({
            skip,
            take,
            search
        }))
    }, [dispatch, skip, take, search])

    return (
        <Box>
            <CustomTable
                columns={[
                    {
                        name: "Nama",
                        key: "name"
                    },
                    {
                        name: "Jenis Kelamin",
                        key: "gender"
                    },
                    {
                        name: "Nomor Telepon",
                        key: "phone"
                    },
                    {
                        name: "Email",
                        key: "email"
                    }
                ]}
                dataList={customerList}
                actionList={(props.onEdit || props.onDelete)
                    ? (data: InterfaceCustomerData) => (
                        <Box display={"flex"} justifyContent={"center"} gap={1}>
                            {props.onEdit && (<Button startIcon={<Edit />} color="warning" onClick={() => props.onEdit && props.onEdit(data)}>Edit</Button>)}
                            {props.onDelete && (<Button startIcon={<Delete />} color="error" onClick={() => props.onDelete && props.onDelete(data.id)}>Hapus</Button>)}
                            {props.onSelect && (<Button startIcon={<Add />} onClick={() => props.onSelect && props.onSelect(data)}>Pilih</Button>)}
                        </Box>
                    )
                    : undefined
                }
                pagination={{
                    setSkip: (e) => dispatch(setSkip(e)),
                    setTake: (e) => dispatch(setTake(e)),
                    total: customerListTotal
                }}
                onSearch={(e) => dispatch(setSearch(e))}
                onAdd={props.onAdd}
            />
        </Box>
    )
}