import { Autocomplete, Box, TextField } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { getCar } from "../data/car.api";
import { InterfaceCarData } from "../data/car.interface";

export default function CarSelect(props: {
    value: number | null | undefined,
    onChange: (value: number | null | undefined, valueDetail: InterfaceCarData | null) => void,
    required?: boolean,
    disabled?: boolean,
    disabledIdList?: number[]
}) {

    const dispatch = useDispatch<any>()

    const { loading, carList } = useSelector((state: RootState) => state.car)

    useEffect(() => {
        dispatch(getCar())
    }, [dispatch])

    return (
        <Box>
            {loading
                ? (
                    <TextField fullWidth label="Armada" />
                )
                : (
                    <Autocomplete
                        fullWidth
                        disabled={props.disabled}
                        options={carList}
                        getOptionLabel={options => options.name}
                        getOptionDisabled={option => (props.disabledIdList || []).includes(option.id)}
                        value={carList.filter(car => car.id === props.value)[0]}
                        onChange={(_, value) => props.onChange(value?.id || null || undefined, value || null)}
                        renderInput={(params) => <TextField {...params} label="Armada" required={props.required} />}
                    />
                )
            }
        </Box>
    )
}