import { createSlice } from '@reduxjs/toolkit'
import { createFlow, deleteFlow, editFlow, getFlowIn, getFlowOut, getFlowTransfer } from './flow.api'
import { InterfaceFlowData } from './flow.interface'
import alertError from '../../../shared/alert/alertError'

export interface FlowState {
    loading: boolean,
    flowTransferList: InterfaceFlowData[],
    flowInList: InterfaceFlowData[],
    flowOutList: InterfaceFlowData[]
}

const initialState: FlowState = {
    loading: false,
    flowTransferList: [],
    flowInList: [],
    flowOutList: []
}

export const flowSlice = createSlice({
    name: 'flow',
    initialState,
    reducers: {},
    extraReducers(builder) {

        builder.addCase(getFlowTransfer.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getFlowTransfer.fulfilled, (state, action) => {
            state.loading = false
            state.flowTransferList = action.payload.data.data
        })
        builder.addCase(getFlowTransfer.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(getFlowIn.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getFlowIn.fulfilled, (state, action) => {
            state.loading = false
            state.flowInList = action.payload.data.data
        })
        builder.addCase(getFlowIn.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(getFlowOut.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getFlowOut.fulfilled, (state, action) => {
            state.loading = false
            state.flowOutList = action.payload.data.data
        })
        builder.addCase(getFlowOut.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(createFlow.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createFlow.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createFlow.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(editFlow.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editFlow.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editFlow.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(deleteFlow.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteFlow.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteFlow.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })
    },
})

export default flowSlice.reducer