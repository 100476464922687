import { Divider, Typography } from "@mui/material";
import { Fragment } from "react";
import logo from "../assets/img/logo.png"

export default function Header() {
    return (
        <Fragment>
            <Typography align="center">
                <img src={logo} alt="logo" height={64} />
            </Typography>
            <Typography variant="h5" align="center"> Tlp 0811303177 </Typography>
            <Typography variant="h5" align="center"> JL. H.Supu Yusuf no 28 (belakang eks MTQ) </Typography>
            <Divider />
            <br />
        </Fragment>
    )
}