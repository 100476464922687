import { Cancel } from "@mui/icons-material";
import { Box, Button, Card, CardContent, FormControl, IconButton, MenuItem, Pagination, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";

export default function CustomTable<T extends { [key: string]: any }>(props: {
    columns: Array<{
        name: string,
        key: keyof T,
        align?: "left" | "center" | "right",
        render?: (data: T) => ReactNode
    }>,
    dataList: T[],
    actionList?: (data: T) => ReactNode,
    pagination?: {
        setSkip: (skip: number) => void,
        setTake: (take: number) => void,
        total: number
    }
    onSearch?: (value: string) => void,
    onAdd?: () => void
}) {

    const [rowPerPage, setRowPerPage] = useState<number>(5)
    const [page, setPage] = useState<number>(1)
    const [search, setSearch] = useState("")
    const [searchTimeout, setSearchTimeout] = useState<any>()

    useEffect(() => {
        setPage(1)
        if (props.pagination) {
            props.pagination.setSkip(0)
            props.pagination.setTake(rowPerPage)
        }
        // eslint-disable-next-line
    }, [rowPerPage])

    useEffect(() => {
        if (props.pagination) {
            props.pagination.setSkip((page - 1) * rowPerPage)
        }
    }, [page, rowPerPage, props.pagination])

    useEffect(() => {
        clearTimeout(searchTimeout)
        const x = setTimeout(() => {
            props.onSearch && props.onSearch(search)
            setPage(1)
        }, 300)
        setSearchTimeout(x)
        // eslint-disable-next-line
    }, [search])

    return (
        <Card>
            <CardContent sx={{ overflowX: "auto" }}>
                {props.onSearch !== undefined && (
                    <Box display={"flex"} gap={1}>
                        <TextField
                            label="Cari"
                            value={search}
                            onChange={e => setSearch(e.target.value)}
                            InputProps={
                                (search !== "")
                                    ? {
                                        endAdornment: (
                                            <IconButton onClick={() => setSearch("")}>
                                                <Cancel />
                                            </IconButton>
                                        )
                                    }
                                    : undefined
                            }
                        />
                    </Box>
                )}
                <Table>
                    <TableHead>
                        <TableRow>
                            {props.columns.map((column, i) => (
                                <TableCell key={i}>{column.name}</TableCell>
                            ))}
                            {props.actionList && (<TableCell align="center">Aksi</TableCell>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(props.onAdd !== undefined && search !== "" && props.dataList.length === 0) && (
                            <TableRow>
                                <TableCell colSpan={props.columns.length + (props.actionList ? 1 : 0)} align={"center"}>Data tidak ditemukan <Button sx={{ marginLeft: 1 }} onClick={props.onAdd}>Tambah</Button></TableCell>
                            </TableRow>
                        )}
                        {props.dataList
                            .filter((_, i) => Boolean(props.pagination) || ((i >= ((page - 1) * rowPerPage)) && (i < (((page - 1) * rowPerPage) + rowPerPage))))
                            .map((data, rowIndex) => (
                                <TableRow key={rowIndex}>
                                    {props.columns.map((column, colIndex) => (
                                        <TableCell key={colIndex} align={column.align}>
                                            {column.render
                                                ? column.render(data)
                                                : data[column.key]
                                            }
                                        </TableCell>
                                    ))}
                                    {props.actionList &&
                                        <TableCell align={"center"}>
                                            {props.actionList(data)}
                                        </TableCell>
                                    }
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
                <Box marginTop={2} display={"flex"} gap={2} justifyContent={"right"} alignItems={"center"}>
                    <Box>
                        <FormControl fullWidth>
                            <Select
                                id="demo-simple-select"
                                value={rowPerPage}
                                onChange={e => setRowPerPage(Number(e.target.value))}
                            >
                                <MenuItem value={5}>5</MenuItem>
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                                <MenuItem value={100}>100</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Pagination count={Math.ceil((props.pagination?.total || props.dataList.length) / rowPerPage)} color="primary" page={page} onChange={(e, value) => setPage(value)} />
                </Box>
            </CardContent>
        </Card>
    )
}
