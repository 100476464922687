import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../../state";
import { RootState } from "../../../store";

export const getReport = createAsyncThunk('getReport', async (_, thunkAPI) => {
    try {
        const response = await axios({
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + (thunkAPI.getState() as RootState).auth.loginData.key
            },
            url: baseUrl + '/report'
        })
        return response
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error)
    }
})