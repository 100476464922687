import { Box, Button, Divider, Tab, Tabs, useTheme } from "@mui/material";
import { InterfaceBookingData } from "../../data/booking.interface";
import { Fragment, useEffect, useState } from "react";
import SeatBookingEditor from "../../../seat/components/SeatBookingEditor";
import Input from "../input/Input";
import CustomModal from "../../../../shared/CustomModal";
import moment from "moment";
import { useDispatch } from "react-redux";
import { setBookingInput } from "../../data/booking.reducer";
import { InterfaceSeatData } from "../../../seat/data/seat.interface";
import BookingEdit from "../BookingEdit";
import useAccess from "../../../../hooks/useAccess";
import ItineraryCreate from "../../../itinerary/components/create/ItineraryCreate";
import { resetItineraryInput } from "../../../itinerary/data/itinerary.reducer";
import { getItineraryByScheduleAndStartDate } from "../../../itinerary/data/itinerary.api";

export default function CalendarDatePreview(props: { bookingList: InterfaceBookingData[], onChange: () => void }) {

    const dispatch = useDispatch<any>()
    const theme = useTheme()
    const access = useAccess("/booking")
    const accessItinerary = useAccess("/itinerary/create")

    const [tabSelected, setTabSelected] = useState(0)
    const [modalBookingInput, setModalBookingInput] = useState(false)
    const [seatSelected, setSeatSelected] = useState<InterfaceSeatData | null>(null)
    const [countReload, setCountReload] = useState(0)
    const [modalItinerary, setModalItinerary] = useState(false)
    const [isItineraryExist, setIsItineraryExist] = useState(false)

    const startDate = moment(props.bookingList[0].start_date).format("YYYY-MM-DD")

    useEffect(() => {
        checkItinerary()
        // eslint-disable-next-line
    }, [dispatch, tabSelected, props.bookingList, startDate])

    const checkItinerary = () => {
        dispatch(getItineraryByScheduleAndStartDate({ schedule_id: props.bookingList[tabSelected].schedule.id, start_date: startDate }))
            .unwrap()
            .then((response: any) => {
                const dataList: number[] = response.data.data
                setIsItineraryExist(dataList.length > 0)
            })
    }

    const handleAddBooking = () => {
        setModalBookingInput(true)
        dispatch(setBookingInput({}))
    }

    const handleChangeData = (close?: boolean) => {
        props.onChange()
        setCountReload(countReload + 1)
        if (close) {
            setModalBookingInput(false)
            setSeatSelected(null)
        }
    }

    const handleMakeItinerary = () => {
        setModalItinerary(true)
    }

    const handleCloseModalItierary = () => {
        setModalItinerary(false)
        dispatch(resetItineraryInput())
        checkItinerary()
    }

    const scheduleList = props.bookingList.map(booked => booked.schedule).filter((value, index, self) => self.findIndex(s => s.id === value.id) === index)
    const scheduleId = scheduleList[tabSelected].id

    return (
        <Fragment>
            <CustomModal
                open={modalBookingInput || (seatSelected?.customer_list !== undefined && seatSelected.customer_list.length === 0)}
                onClose={() => { setModalBookingInput(false); setSeatSelected(null) }}
                title={"Input Booking"}
                size="lg"
                component={<Input startDate={startDate} scheduleId={scheduleId} isAllowSubmit={true} onSubmit={() => handleChangeData(true)} />}
            />
            <CustomModal
                open={seatSelected?.customer_list !== undefined && seatSelected.customer_list.length > 0}
                onClose={() => setSeatSelected(null)}
                title={"Detail Seat"}
                size="lg"
                component={seatSelected?.customer_list !== undefined && seatSelected.customer_list?.length > 0 && <BookingEdit bookingId={seatSelected.customer_list[0].booking.id} onChange={handleChangeData} />}
            />
            <CustomModal
                open={modalItinerary}
                onClose={handleCloseModalItierary}
                title={"Buat Itinerary"}
                size="lg"
                component={<ItineraryCreate start_date={startDate} schedule_id={scheduleId} onSubmit={handleCloseModalItierary} />}
            />
            <Box position={"sticky"} top={0} bgcolor={theme.palette.background.paper} zIndex={2}>
                <Tabs value={tabSelected}>
                    {scheduleList.map((booked, i) => (
                        <Tab key={i} value={i} label={`${booked.start_city.name} - ${booked.finish_city.name}`} onClick={() => setTabSelected(i)} />
                    ))}
                </Tabs>
                <Box display={"flex"} justifyContent={"space-between"} marginY={1} >
                    <Box>
                        <Button onClick={handleMakeItinerary} disabled={!accessItinerary("CREATE") || isItineraryExist}>Buat Itinerary</Button>
                    </Box>
                    <Box display={"flex"} gap={1} justifyContent={"flex-end"}>
                        <Button onClick={handleAddBooking} disabled={!access("CREATE") || isItineraryExist}>Tambah Booking</Button>
                        <Button color="info" onClick={() => window.open("/#/schedule/print/" + scheduleId + "/" + startDate, "_blank")}>Print</Button>
                    </Box>
                </Box>
                <Divider />
            </Box>
            <Box>
                <SeatBookingEditor scheduleId={scheduleId} startDate={startDate} countReload={countReload} onClick={seat => setSeatSelected(seat)} />
            </Box>
        </Fragment>
    )
}