import { Autocomplete, Box, TextField } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { getPackageType } from "../data/packageType.api";
import { InterfacePackageTypeData } from "../data/packageType.interface";

export default function PackageTypeSelect(props: {
    value: number | null | undefined,
    onChange: (value: number | null, valueDetail: InterfacePackageTypeData | null) => void,
    required?: boolean,
    disabled?: boolean,
    disabledIdList?: number[]
}) {

    const dispatch = useDispatch<any>()

    const { loading, packageTypeList } = useSelector((state: RootState) => state.packageType)

    useEffect(() => {
        dispatch(getPackageType())
    }, [dispatch])

    return (
        <Box>
            {loading
                ? (
                    <TextField fullWidth label="Tipe Paket" />
                )
                : (
                    <Autocomplete
                        fullWidth
                        disabled={props.disabled}
                        options={packageTypeList}
                        getOptionLabel={options => options.name}
                        getOptionDisabled={option => (props.disabledIdList || []).includes(option.id)}
                        value={packageTypeList.filter(packageType => packageType.id === props.value)[0]}
                        onChange={(_, value) => props.onChange(value?.id || null, value || null)}
                        renderInput={(params) => <TextField {...params} label="Tipe Paket" required={props.required} />}
                    />
                )
            }
        </Box>
    )
}