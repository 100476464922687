import { Box, Button } from "@mui/material";
import { FormEvent } from "react";
import { InitialSparepartForm, InterfaceSparepartForm } from "../data/sparepart.interface";
import FormText from "../../../shared/form/FormText";
import { useDispatch } from "react-redux";
import alertSuccess from "../../../shared/alert/alertSuccess";
import { useFormValues } from "../../../hooks/useFormValues";
import { createSparepart, editSparepart, getSparepart } from "../data/sparepart.api";
import SparepartCategorySelect from "../../sparepartCategory/components/SparepartCategorySelect";
import FormMoney from "../../../shared/form/FormMoney";
import WarehouseSelect from "../../warehouse/components/WarehouseSelect";

export default function SparepartForm(props: { mode: "ADD" | "EDIT", data?: InterfaceSparepartForm, onSubmit: () => void }) {

    const dispatch = useDispatch<any>()

    const { values, handleChange, handleChangeValues } = useFormValues<InterfaceSparepartForm>(props.data || InitialSparepartForm)

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault()
        try {
            if (props.mode === "ADD") {
                await dispatch(createSparepart(values)).unwrap()
                await alertSuccess("Berhasil menyimpan data")
            }
            else if (props.mode === "EDIT") {
                await dispatch(editSparepart(values)).unwrap()
                await alertSuccess("Berhasil mengubah data")
            }
            await dispatch(getSparepart())
            props.onSubmit()
        } catch (error) { }
    }

    return (
        <Box component={"form"} onSubmit={handleSubmit}>
            <FormText label="Kode" name="code" value={values.code} onChange={handleChange} />
            <FormText label="Nama" name="name" value={values.name} onChange={handleChange} />
            <SparepartCategorySelect value={values.category_id} onChange={value => handleChangeValues("category_id", value)} />
            <FormMoney label="Harga" name="price" value={values.price} onChange={handleChange} />
            <FormText label="Merk" name="brand" value={values.brand} onChange={handleChange} />
            <FormText label="Tipe" name="type" value={values.type} onChange={handleChange} />
            <FormText label="Aksesoris" name="acc" value={values.acc} onChange={handleChange} />
            <WarehouseSelect value={values.warehouse_id} onChange={value => handleChangeValues("warehouse_id", value)} />
            <Box marginTop={1} display={"flex"} justifyContent={"flex-end"}>
                <Button type="submit">Simpan</Button>
            </Box>
        </Box>
    )
}