import { Box, Button } from "@mui/material";
import { FormEvent, useEffect } from "react";
import { InitialSparepartCategoryForm, InterfaceSparepartCategoryForm } from "../data/sparepartCategory.interface";
import FormText from "../../../shared/form/FormText";
import { useDispatch } from "react-redux";
import alertSuccess from "../../../shared/alert/alertSuccess";
import { useFormValues } from "../../../hooks/useFormValues";
import { createSparepartCategory, editSparepartCategory, getSparepartCategory } from "../data/sparepartCategory.api";
import SparepartCategorySelect from "./SparepartCategorySelect";

export default function SparepartCategoryForm(props: { mode: "ADD" | "EDIT", parrentId?: number, data?: InterfaceSparepartCategoryForm, onSubmit: () => void }) {

    const dispatch = useDispatch<any>()

    const { values, handleChange, handleChangeValues } = useFormValues<InterfaceSparepartCategoryForm>(props.data || InitialSparepartCategoryForm)

    useEffect(() => {
        if (props.parrentId) {
            handleChangeValues("parrent_id", props.parrentId)
        }
    }, [props.parrentId, handleChangeValues])

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault()
        try {
            if (props.mode === "ADD") {
                await dispatch(createSparepartCategory(values)).unwrap()
                await alertSuccess("Berhasil menyimpan data")
            }
            else if (props.mode === "EDIT") {
                await dispatch(editSparepartCategory({
                    id: values.id,
                    name: values.name,
                    parrent_id: values.parrent_id,
                })).unwrap()
                await alertSuccess("Berhasil mengubah data")
            }
            await dispatch(getSparepartCategory())
            props.onSubmit()
        } catch (error) { }
    }

    return (
        <Box component={"form"} onSubmit={handleSubmit}>
            <SparepartCategorySelect value={values.parrent_id} onChange={(value) => handleChangeValues("parrent_id", value)} />
            <FormText label="Nama" name="name" value={values.name} onChange={handleChange} />
            <Box marginTop={1} display={"flex"} justifyContent={"flex-end"}>
                <Button type="submit">Simpan</Button>
            </Box>
        </Box>
    )
}