import { TextField } from "@mui/material";
import { ChangeEvent, ChangeEventHandler } from "react";

export default function FormTime(props: {
    label: string,
    name?: string,
    value: string,
    required?: boolean,
    disabled?: boolean,
    onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
}) {

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        props.onChange && props.onChange({
            ...e,
            target: {
                ...e.target,
                name: props.name || "",
                value: e.target.value
            }
        })
    }

    return (
        <TextField
            fullWidth
            type="time"
            label={props.label}
            name={props.name}
            value={props.value || ""}
            onChange={handleChange}
            required={props.required}
            disabled={props.disabled}
            InputLabelProps={{ shrink: true }}
        />
    )
}