import { Container, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import alertDelete from "../../shared/alert/alertDelete";
import { deleteBooking, getBooking } from "../../features/booking/data/booking.api";
import alertSuccess from "../../shared/alert/alertSuccess";
import useAccess from "../../hooks/useAccess";
import BookingTableAll from "../../features/booking/components/BookingTableAll";

export default function TicketPage() {

    const dispatch = useDispatch<any>()
    const access = useAccess()

    const handleDelete = (id: number) => {
        alertDelete()
            .then(async isConfirmed => {
                if (isConfirmed) {
                    try {
                        await dispatch(deleteBooking(id)).unwrap()
                        await alertSuccess("Berhasil menghapus data")
                        await dispatch(getBooking({ skip: 0, take: 5 }))
                    } catch (error) { }
                }
            })
    }

    const handlePrint = (id: number) => {
        window.open(`/#/booking/print/${id}`, "_blank")
    }

    return (
        <Container>
            <Typography variant="h3">Tiket</Typography>
            <br />
            <BookingTableAll onDelete={access("DELETE") ? handleDelete : undefined} onPrint={handlePrint} />
        </Container>
    )
}