import { Box, Button } from "@mui/material";
import { FormEvent } from "react";
import { InitialEmployeeForm, InterfaceEmployeeForm } from "../data/employee.interface";
import FormText from "../../../shared/form/FormText";
import { useDispatch } from "react-redux";
import alertSuccess from "../../../shared/alert/alertSuccess";
import { useFormValues } from "../../../hooks/useFormValues";
import { createEmployee, editEmployee, getEmployee } from "../data/employee.api";
import EmployeeTypeSelect from "./EmployeeTypeSelect";

export default function EmployeeForm(props: { mode: "ADD" | "EDIT", data?: InterfaceEmployeeForm, onSubmit: () => void }) {

    const dispatch = useDispatch<any>()

    const { values, handleChange, handleChangeValues } = useFormValues<InterfaceEmployeeForm>(props.data || InitialEmployeeForm)

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault()
        try {
            if (props.mode === "ADD") {
                await dispatch(createEmployee(values)).unwrap()
                await alertSuccess("Berhasil menyimpan data")
            }
            else if (props.mode === "EDIT") {
                await dispatch(editEmployee(values)).unwrap()
                await alertSuccess("Berhasil mengubah data")
            }
            await dispatch(getEmployee())
            props.onSubmit()
        } catch (error) { }
    }

    return (
        <Box component={"form"} onSubmit={handleSubmit}>
            <FormText label="Nama" name="name" value={values.name} onChange={handleChange} />
            <FormText label="Alamat" name="address" value={values.address} onChange={handleChange} />
            <FormText label="Telepon" name="phone" value={values.phone} onChange={handleChange} />
            <EmployeeTypeSelect value={values.type} onChange={(value) => handleChangeValues("type", value)} />
            <Box marginTop={1} display={"flex"} justifyContent={"flex-end"}>
                <Button type="submit">Simpan</Button>
            </Box>
        </Box>
    )
}