import { Box, Button } from "@mui/material";
import { FormEvent } from "react";
import { InitialEmployeeCashForm, InterfaceEmployeeCashForm } from "../data/employeeCash.interface";
import { useDispatch } from "react-redux";
import alertSuccess from "../../../shared/alert/alertSuccess";
import { useFormValues } from "../../../hooks/useFormValues";
import { createEmployeeCash, editEmployeeCash, getEmployeeCash } from "../data/employeeCash.api";
import AccountSelect from "../../account/components/AccountSelect";
import FormMoney from "../../../shared/form/FormMoney";
import { useSetting } from "../../../hooks/useSetting";
import EmployeeSelect from "../../employee/components/EmployeeSelect";
import FormCheck from "../../../shared/form/FormCheck";

export default function EmployeeCashForm(props: { mode: "ADD" | "EDIT", data?: InterfaceEmployeeCashForm, onSubmit: () => void }) {

    const dispatch = useDispatch<any>()
    const setting = useSetting()

    const { values, handleChange, handleChangeValues } = useFormValues<InterfaceEmployeeCashForm>(props.data || InitialEmployeeCashForm)

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault()
        try {
            if (props.mode === "ADD") {
                await dispatch(createEmployeeCash({
                    ...values,
                    account_id: setting.getValue("ACCOUNT_CASH_EMPLOYEE")
                })).unwrap()
                await alertSuccess("Berhasil menyimpan data")
            }
            else if (props.mode === "EDIT") {
                await dispatch(editEmployeeCash(values)).unwrap()
                await alertSuccess("Berhasil mengubah data")
            }
            await dispatch(getEmployeeCash())
            props.onSubmit()
        } catch (error) { }
    }

    return (
        <Box component={"form"} onSubmit={handleSubmit}>
            <AccountSelect disabledHead headId={setting.getValue("ACCOUNT_MAIN_FLOW")} label="Akun Sumber" value={values.account_source_id} onChange={(value) => handleChangeValues("account_source_id", value)} />
            <EmployeeSelect label="Pegawai" value={values.employee_id} onChange={(value) => handleChangeValues("employee_id", value)} />
            <FormMoney label="Nominal" name="value" value={values.value} onChange={handleChange} />
            <FormCheck label="Disetujui" name="is_approved" value={values.is_approved} onChange={handleChange} />
            <Box marginTop={1} display={"flex"} justifyContent={"flex-end"}>
                <Button type="submit">Simpan</Button>
            </Box>
        </Box>
    )
}