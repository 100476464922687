import { Card, CardContent, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAccount } from "../../account/data/account.api";
import { RootState } from "../../../store";
import moneyParser from "../../../shared/moneyParser";
import moment from "moment";
import { getReport } from "../data/report.api";

export default function Journal(props: {
    date_start: string,
    date_end: string

}) {

    const dispatch = useDispatch<any>();

    const { reportList } = useSelector((state: RootState) => state.report)

    useEffect(() => {
        dispatch(getAccount())
        dispatch(getReport())
    }, [dispatch])

    const momentDateStart = moment(props.date_start)
    const momentDateEnd = moment(props.date_end)

    const total = reportList.filter(report => moment(report.ts_created).isBetween(momentDateStart, momentDateEnd)).reduce((total, r) => total + r.value, 0)

    return (
        <Card>
            <CardContent>
                <Table>
                    <TableBody>
                        {reportList
                            .filter(report => moment(report.ts_created).isBetween(momentDateStart, momentDateEnd))
                            .map((report, reportIndex) => {
                                return (
                                    <Fragment key={reportIndex}>
                                        <TableRow>
                                            <TableCell>{moment(report.ts_created).format("YYYY-MM-DD HH:mm:ss")}</TableCell>
                                            <TableCell>{report.account_source.name}</TableCell>
                                            <TableCell>{report.name}</TableCell>
                                            <TableCell align="right">{moneyParser(report.value)}</TableCell>
                                            <TableCell align="right">{0}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>{moment(report.ts_created).format("YYYY-MM-DD HH:mm:ss")}</TableCell>
                                            <TableCell>{report.account.name}</TableCell>
                                            <TableCell>{report.name}</TableCell>
                                            <TableCell align="right">{0}</TableCell>
                                            <TableCell align="right">{moneyParser(report.value)}</TableCell>
                                        </TableRow>
                                    </Fragment>
                                )
                            })
                        }
                        <TableRow>
                            <TableCell sx={{ fontWeight: "bold" }} colSpan={3}>Total</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }} align="right">{moneyParser(total)}</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }} align="right">{moneyParser(total)}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    )
}