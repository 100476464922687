export interface InterfaceAccountData {
    id: number,
    ts_created: string,
    code: string,
    name: string,
    parrent_id: number | null,
    initial_balance: number,
    is_head: boolean
}

export interface InterfaceAccountDataTree extends InterfaceAccountData {
    indent: number
}

export interface InterfaceAccountForm {
    id: number,
    code: string,
    name: string,
    parrent_id: number | null,
    initial_balance: number,
    is_head: boolean
}

export interface InterfaceAccountCreate {
    code: string,
    name: string,
    parrent_id: number | null,
    initial_balance: number
}

export interface InterfaceAccountEdit {
    id: number,
    code?: string,
    name?: string,
    parrent_id?: number | null,
    initial_balance?: number
}

export const InitialAccountForm: InterfaceAccountForm = {
    id: 0,
    code: "",
    name: "",
    parrent_id: null,
    initial_balance: 0,
    is_head: false
}