import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { Box, Button, Tab, Tabs, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Fragment } from "react/jsx-runtime";
import { InterfaceItineraryInput } from "../../data/itinerary.interface";
import { RootState } from "../../../../store";
import moment from "moment";
import { setItineraryInput } from "../../data/itinerary.reducer";
import ItineraryCreateA from "./ItineraryCreateA";
import ItineraryCreateB from "./ItineraryCreateB";
import ItineraryCreateC from "./ItineraryCreateC";
import ItineraryCreateD from "./ItineraryCreateD";
import { AxiosResponse } from "axios";
import { getSchedule } from "../../../schedule/data/schedule.api";
import { InterfaceScheduleData } from "../../../schedule/data/schedule.interface";

export default function ItineraryCreate(props: { onSubmit?: () => void, start_date?: string, schedule_id?: number }) {

    const dispatch = useDispatch<any>()

    const { itineraryInput } = useSelector((state: RootState) => state.itinerary)

    const theme = useTheme()

    const [step, setStep] = useState(0)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const initialData = async () => {
            const initialData: InterfaceItineraryInput = {}
            if (itineraryInput.ts_created === undefined) {
                initialData.ts_created = moment().format('YYYY-MM-DD HH:mm:ss')
            }
            if (props.start_date !== undefined && itineraryInput.start_date === undefined) {
                initialData.start_date = props.start_date || moment().format('YYYY-MM-DD')
            }
            if (props.schedule_id !== undefined && itineraryInput.schedule === undefined) {
                const response: AxiosResponse = await dispatch(getSchedule({ id: props.schedule_id })).unwrap()
                const schedule: InterfaceScheduleData = response.data.data[0]
                initialData.schedule = schedule
            }
            if (Object.keys(initialData).length > 0) {
                dispatch(setItineraryInput(initialData))
            }
            setLoading(false)
        }
        initialData()
    }, [
        dispatch,
        itineraryInput,
        props.schedule_id,
        props.start_date
    ])

    useEffect(() => {
        if (
            step === 0 &&
            itineraryInput.ts_created !== undefined &&
            itineraryInput.start_date !== undefined
        ) {
            if (
                itineraryInput.schedule !== undefined
            ) {
                setStep(2)
            }
            else {
                setStep(1)
            }
        }
        if (
            step === 1 &&
            itineraryInput.schedule !== undefined
        ) {
            setStep(2)
        }
        // eslint-disable-next-line
    }, [itineraryInput])

    const enableBack = () => {
        if (step === 0) {
            return false
        }
        return true
    }

    const enableNext = () => {
        if (step === steps.length - 1) {
            return false
        }
        return true
    }

    const handleSubmit = () => {
        setStep(0)
        dispatch(setItineraryInput({}))
        props.onSubmit && props.onSubmit()
    }

    const steps = [
        {
            name: "Tanggal",
            component: <ItineraryCreateA />
        },
        {
            name: "Jadwal",
            component: <ItineraryCreateB />
        },
        {
            name: "Penumpang & Paket",
            component: <ItineraryCreateC />
        },
        {
            name: "Pegawai",
            component: <ItineraryCreateD onSubmit={handleSubmit} />
        }
    ]

    return (
        <Fragment>
            <Box position={"sticky"} top={0} bgcolor={theme.palette.background.paper}>
                <Box display={"flex"} justifyContent={"flex-end"} gap={1}>
                    <Button variant="text" disabled={!enableBack()} startIcon={<ArrowBackIos />} onClick={() => setStep(step - 1)}>Back</Button>
                    <Button variant="text" disabled={!enableNext()} endIcon={<ArrowForwardIos />} onClick={() => setStep(step + 1)}>Next</Button>
                </Box>
                <Tabs value={step}>
                    {steps.map((step, index) => (
                        <Tab key={index} value={index} label={step.name} />
                    ))}
                </Tabs>
            </Box>
            <Box marginTop={1}>
                {loading ? "Loading..." : steps[step].component}
            </Box>
        </Fragment>
    )
}