import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: "#dcb439",
            contrastText: "white"
        },
        background: {
            default: "#f4f6f8",
            paper: "white"
        }
    },
    components: {
        MuiTextField: {
            defaultProps: {
                size: "small",
                margin: "dense"
            }
        },
        MuiSelect: {
            defaultProps: {
                size: "small"
            }
        },
        MuiButton: {
            defaultProps: {
                variant: "contained"
            }
        },
        MuiContainer: {
            defaultProps: {
                sx: {
                    marginY: 2
                }
            }
        },
        MuiCard: {
            defaultProps: {
                variant: "outlined"
            }
        },
    },
});

export default theme;
