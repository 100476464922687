import { Box, Button, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from "@mui/material"
import { InterfaceItineraryCostAdd, InterfaceItineraryCostData, InterfaceItineraryCostEdit } from "../data/itinerary.interface"
import { useState } from "react"
import FormText from "../../../shared/form/FormText"
import moneyParser from "../../../shared/moneyParser"
import { Add, Close, Delete, Edit } from "@mui/icons-material"
import FormMoney from "../../../shared/form/FormMoney"
import { useDispatch } from "react-redux"
import { createItineraryCost, deleteItineraryCost, editItineraryCost } from "../data/itinerary.api"
import alertSuccess from "../../../shared/alert/alertSuccess"
import AccountSelect from "../../account/components/AccountSelect"
import { useSetting } from "../../../hooks/useSetting"
import alertDelete from "../../../shared/alert/alertDelete"

const initialCostForm: InterfaceItineraryCostEdit = {
    id: undefined,
    name: "",
    account_id: 0,
    account_source_id: 0,
    value: 0
}

export const ItineraryCloseFormCost = (props: { itinerary_id: number, cost_list: InterfaceItineraryCostData[], onSubmit: () => void }) => {

    const dispatch = useDispatch<any>()
    const setting = useSetting()

    const [costToEdit, setCostToEdit] = useState<InterfaceItineraryCostEdit>(initialCostForm)
    const [costToAdd, setCostToAdd] = useState<InterfaceItineraryCostAdd | undefined>(undefined)

    const handleEdit = (cost: InterfaceItineraryCostData) => {
        setCostToEdit({
            id: cost.id,
            name: cost.name,
            account_id: cost.account_id,
            account_source_id: cost.account_source_id,
            value: cost.value
        })
    }

    const handleAdd = () => {
        setCostToAdd({
            itinerary_id: props.itinerary_id,
            name: "",
            value: 0,
            account_id: setting.getValue("ACCOUNT_ITINERARY_COST_DESTINATION"),
            account_source_id: setting.getValue("ACCOUNT_ITINERARY_COST_SOURCE_DEFAULT")
        })
    }

    const handleCancel = () => {
        setCostToEdit(initialCostForm)
        setCostToAdd(undefined)
    }

    const handleSave = async () => {
        try {
            if (costToEdit.id !== undefined) {
                await dispatch(editItineraryCost(costToEdit)).unwrap()
                await alertSuccess("Berhasil mengubah data")
                setCostToEdit(initialCostForm)
            }
            else if (costToAdd !== undefined) {
                await dispatch(createItineraryCost(costToAdd)).unwrap()
                await alertSuccess("Berhasil menambah data")
                setCostToAdd(undefined)
            }
            props.onSubmit()
        } catch (error) { }
    }

    const handleDelete = async (id: number) => {
        alertDelete()
            .then(async isConfirmed => {
                if (isConfirmed) {
                    try {
                        await dispatch(deleteItineraryCost(id)).unwrap()
                        await alertSuccess("Berhasil menghapus data")
                        props.onSubmit && props.onSubmit()
                    } catch (error) { }
                }
            })
    }

    return (
        <Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Nama</TableCell>
                        <TableCell>Akun</TableCell>
                        <TableCell align="center">Nominal</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.cost_list.map((cost, index) => (
                        <TableRow key={index}>
                            <TableCell>
                                {costToEdit.id === cost.id
                                    ? <FormText label="Nama" name="name" value={costToEdit.name} onChange={e => setCostToEdit(last => ({ ...last, name: e.target.value }))} />
                                    : cost.name
                                }
                            </TableCell>
                            <TableCell>{cost.account.name}</TableCell>
                            <TableCell align="right">
                                {costToEdit.id === cost.id
                                    ? <FormMoney label="Nomonal" name="value" value={costToEdit.value} onChange={e => setCostToEdit(last => ({ ...last, value: parseInt(e.target.value) }))} />
                                    : moneyParser(cost.value)
                                }
                            </TableCell>
                            <TableCell>
                                {costToEdit.id === cost.id
                                    ? (
                                        <Box display={"flex"} gap={1}>
                                            <Tooltip title="Batal">
                                                <IconButton onClick={() => handleCancel()}><Close /></IconButton>
                                            </Tooltip>
                                            <Button onClick={handleSave}>
                                                Simpan
                                            </Button>
                                        </Box>
                                    )
                                    : (
                                        <Box display={"flex"} gap={1}>
                                            <Tooltip title="Edit biaya">
                                                <IconButton onClick={() => handleEdit(cost)}><Edit /></IconButton>
                                            </Tooltip>
                                            {[setting.getValue("ACCOUNT_ITINERARY_COST_DESTINATION_GAS"), setting.getValue("ACCOUNT_ITINERARY_COST_DESTINATION_MAINTENANCE")].includes(cost.account_id) === false && (
                                                <Tooltip title="Hapus biaya">
                                                    <IconButton onClick={() => handleDelete(cost.id)}><Delete /></IconButton>
                                                </Tooltip>
                                            )}
                                        </Box>
                                    )
                                }
                            </TableCell>
                        </TableRow>
                    ))}
                    {costToAdd !== undefined && (
                        <TableRow>
                            <TableCell>
                                <FormText label="Nama" name="name" value={costToAdd.name} onChange={e => costToAdd !== undefined && setCostToAdd({ ...costToAdd, name: e.target.value })} />
                            </TableCell>
                            <TableCell>
                                <AccountSelect required disabledHead headId={setting.getValue("ACCOUNT_MAIN_FLOW")} label={"Akun"} value={costToAdd.account_source_id} onChange={value => costToAdd && setCostToAdd({ ...costToAdd, account_source_id: value || 0 })} />
                            </TableCell>
                            <TableCell align="right">
                                <FormMoney label="Nomonal" name="value" value={costToAdd.value} onChange={e => costToAdd && setCostToAdd({ ...costToAdd, value: parseInt(e.target.value) })} />
                            </TableCell>
                            <TableCell>
                                <Box display={"flex"} gap={1}>
                                    <Tooltip title="Batal">
                                        <IconButton onClick={() => handleCancel()}><Close /></IconButton>
                                    </Tooltip>
                                    <Button startIcon={<Add />} onClick={handleSave}>
                                        Tambah
                                    </Button>
                                </Box>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            {costToAdd === undefined && (
                <Button variant="outlined" fullWidth sx={{ marginTop: 1 }} onClick={handleAdd}>Tambah</Button>
            )}
        </Box>
    )
}