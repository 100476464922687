import { Box, Button } from "@mui/material";
import { FormEvent, useEffect, useState } from "react";
import { InitialFlowForm, InterfaceFlowForm, TypeFlow } from "../data/flow.interface";
import FormText from "../../../shared/form/FormText";
import { useDispatch } from "react-redux";
import alertSuccess from "../../../shared/alert/alertSuccess";
import { useFormValues } from "../../../hooks/useFormValues";
import { createFlow, editFlow, getFlowIn, getFlowOut, getFlowTransfer } from "../data/flow.api";
import AccountSelect from "../../account/components/AccountSelect";
import FormMoney from "../../../shared/form/FormMoney";
import { useSetting } from "../../../hooks/useSetting";

export default function FlowForm(props: { type: TypeFlow, mode: "ADD" | "EDIT", data?: InterfaceFlowForm, onSubmit: () => void }) {

    const dispatch = useDispatch<any>()
    const setting = useSetting()

    const [accountHeadIdSource, setAccountHeadIdSource] = useState<number>(0)
    const [accountHeadIdDesctination, setAccountHeadIdDesctination] = useState<number>(0)

    const { values, handleChange, handleChangeValues } = useFormValues<InterfaceFlowForm>(props.data || InitialFlowForm)

    useEffect(() => {
        switch (props.type) {
            case "CASH_TRANSFER":
                setAccountHeadIdSource(setting.getValue("ACCOUNT_MAIN_FLOW"))
                setAccountHeadIdDesctination(setting.getValue("ACCOUNT_MAIN_FLOW"))
                break
            case "CASH_IN":
                setAccountHeadIdSource(setting.getValue("ACCOUNT_FLOW_IN_SOURCE"))
                setAccountHeadIdDesctination(setting.getValue("ACCOUNT_MAIN_FLOW"))
                break
            case "CASH_OUT":
                setAccountHeadIdSource(setting.getValue("ACCOUNT_MAIN_FLOW"))
                setAccountHeadIdDesctination(setting.getValue("ACCOUNT_FLOW_OUT_DESTINATION"))
                break
        }
    }, [setting, props.type])

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault()
        try {
            if (props.mode === "ADD") {
                await dispatch(createFlow(values)).unwrap()
                await alertSuccess("Berhasil menyimpan data")
            }
            else if (props.mode === "EDIT") {
                await dispatch(editFlow(values)).unwrap()
                await alertSuccess("Berhasil mengubah data")
            }
            switch (props.type) {
                case "CASH_TRANSFER":
                    await dispatch(getFlowTransfer())
                    break
                case "CASH_IN":
                    await dispatch(getFlowIn())
                    break
                case "CASH_OUT":
                    await dispatch(getFlowOut())
                    break
            }
            props.onSubmit()
        } catch (error) { }
    }

    return (
        <Box component={"form"} onSubmit={handleSubmit}>
            <AccountSelect disabledHead headId={accountHeadIdSource} label="Akun Sumber" value={values.account_source_id} onChange={(value) => handleChangeValues("account_source_id", value)} />
            <AccountSelect disabledHead headId={accountHeadIdDesctination} label="Akun Tujuan" value={values.account_id} onChange={(value) => handleChangeValues("account_id", value)} />
            <FormMoney label="Nominal" name="value" value={values.value} onChange={handleChange} />
            <FormText label="Keterangan" name="notes" value={values.notes} onChange={handleChange} />
            <Box marginTop={1} display={"flex"} justifyContent={"flex-end"}>
                <Button type="submit">Simpan</Button>
            </Box>
        </Box>
    )
}