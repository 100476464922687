import { Box, Button } from "@mui/material";
import { FormEvent } from "react";
import { InitialTerminalForm, InterfaceTerminalForm } from "../data/terminal.interface";
import FormText from "../../../shared/form/FormText";
import { useDispatch } from "react-redux";
import alertSuccess from "../../../shared/alert/alertSuccess";
import { useFormValues } from "../../../hooks/useFormValues";
import { createTerminal, editTerminal, getTerminal } from "../data/terminal.api";
import FormCheck from "../../../shared/form/FormCheck";
import CitySelect from "../../city/components/CitySelect";

export default function TerminalForm(props: { mode: "ADD" | "EDIT", data?: InterfaceTerminalForm, onSubmit: () => void }) {

    const dispatch = useDispatch<any>()

    const { values, handleChange, handleChangeValues } = useFormValues<InterfaceTerminalForm>(props.data || InitialTerminalForm)

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault()
        try {
            if (props.mode === "ADD") {
                await dispatch(createTerminal({
                    code: values.code,
                    name: values.name,
                    city_id: values.city_id,
                    is_active: values.is_active
                })).unwrap()
                await alertSuccess("Berhasil menyimpan data")
            }
            else if (props.mode === "EDIT") {
                await dispatch(editTerminal({
                    id: values.id,
                    code: values.code,
                    name: values.name,
                    city_id: values.city_id,
                    is_active: values.is_active
                })).unwrap()
                await alertSuccess("Berhasil mengubah data")
            }
            await dispatch(getTerminal({}))
            props.onSubmit()
        } catch (error) { }
    }

    return (
        <Box component={"form"} onSubmit={handleSubmit}>
            <FormText label="Kode" name="code" value={values.code} onChange={handleChange} />
            <FormText label="Nama" name="name" value={values.name} onChange={handleChange} />
            <CitySelect value={values.city_id} onChange={(value) => handleChangeValues("city_id", value)} required />
            <FormCheck label="Aktif" name="is_active" value={values.is_active} onChange={handleChange} />
            <Box marginTop={1} display={"flex"} justifyContent={"flex-end"}>
                <Button type="submit">Simpan</Button>
            </Box>
        </Box>
    )
}