import { createSlice } from '@reduxjs/toolkit'
import { createCustomer, deleteCustomer, editCustomer, getCustomer } from './customer.api'
import { InterfaceCustomerData } from './customer.interface'
import alertError from '../../../shared/alert/alertError'

export interface CustomerState {
    loading: boolean,
    customerList: InterfaceCustomerData[],
    customerListTotal: number,
    skip: number,
    take: number,
    search: string
}

const initialState: CustomerState = {
    loading: false,
    customerList: [],
    customerListTotal: 0,
    skip: 0,
    take: 5,
    search: ""
}

export const customerSlice = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        setSkip: (state, action: { type: string, payload: number }) => {
            state.skip = action.payload
        },
        setTake: (state, action: { type: string, payload: number }) => {
            state.take = action.payload
        },
        setSearch: (state, action: { type: string, payload: string }) => {
            state.search = action.payload
        }
    },
    extraReducers(builder) {

        builder.addCase(getCustomer.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getCustomer.fulfilled, (state, action) => {
            state.loading = false
            state.customerList = action.payload.data.data
            state.customerListTotal = action.payload.data.total_data
        })
        builder.addCase(getCustomer.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(createCustomer.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createCustomer.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createCustomer.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(editCustomer.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(editCustomer.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(editCustomer.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })

        builder.addCase(deleteCustomer.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteCustomer.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteCustomer.rejected, (state, action) => {
            state.loading = false
            alertError(action.payload)
        })
    },
})

export const { setTake, setSearch, setSkip } = customerSlice.actions

export default customerSlice.reducer